import React, { useEffect, useState } from 'react'
import { useTable } from 'react-table';
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import AllModals from '../../ProHelpers/AllModals';
import { Button, Modal, Form } from 'react-bootstrap';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import { validateEmail } from '../../ProHelpers/CommonUtils'
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook
import { generateCustomerID, getUTCDateTime } from '../../ProHelpers/AuthUtils';

function CompanyUsers() {
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const EmailID = sessionStorage.getItem('EmailID');
    const [modalType, setModalType] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToUpdate, setItemToUpdate] = useState(null);
    const [isSendingInvite, setIsSendingInvite] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isCreateVisible, setIsCreateVisible] = useState('cancel');
    const [isDeleteloading, setIsDeleteLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isSentLoading, setIsSentLoading] = useState(false);
    const handleClose = () => setModalType(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inviteUserModal, setInviteUserModal] = useState(false);
    const [emailID, setEmailID] = useState('');
    const [role, setRole] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [currentUser, setcurrentUser] = useState([]); // Spinner state
    // const [emailError, setEmailError] = useState("");
    const [customerUsers, setCustomerUsers] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [isRoleMappingLoaded, setIsRoleMappingLoaded] = useState(false);

    const rolesMapping = {};

    const handleOpenModal = () => setIsModalOpen(true); // Open modal
    const handleCloseModal = () => {
        setIsModalOpen(false); // Close modal
        setEmailID(''); // Reset form data
        setRole('');
    };

    const handleUpdateCloseModal = () => {
        setIsUpdateModalOpen(false); // Close modal

    };
    const handleCancelDelete = () => {
        setIsDeleteModalOpen(false);
        setConfirmationText("");
    };

    const handleInvitationEmail = () => {
        console.log("EmailID:", emailID, "Role:", role);
        // Add API call or processing logic here
        setIsSendingInvite(true);
        //handleCloseModal(); // Close the modal after sending
        sendInvitationMail();
    };


    const handleUpdateUser = () => {
        setIsUpdating(true);
        updateUserData();

    }

    const handleDeleteClick = (rowData) => {
        setItemToDelete(rowData);
        setIsDeleteModalOpen(true);
    };

    const handleToUpdateClick = (rowData) => {
        console.log("On Edit data: ", rowData);
        setItemToUpdate(rowData);
        setIsUpdateModalOpen(true);
        setRole(rowData.Role);
    };

    useEffect(() => {
        setIsTableLoading(true);
        const records = JSON.parse(sessionStorage.getItem('Records'));
        setcurrentUser(records[0]);

        fetchUsersRole();
        fetchCurrentClientUsers();

    }, []);


    const updateUserData = async () => {
        const requestBody = {
            'ActionType': 'update_user_role',
            'CustomerID': itemToUpdate.CustomerID,
            'EmailID': itemToUpdate.EmailID,
            'UpdatedOn': getUTCDateTime(),
            'RoleID': role,
        };
        try {
            console.log("Update Request Body: ", requestBody);
            const response = await AxiosInstance.post('/manage-resource', requestBody); // Adjust the path to your API


            console.log('Update Response Data:', response);
            //if (response?.data?.message) {
            setTimeout(() => showAlert(response.data.message, response.data.status), 0);

            // }
        } catch (err) {
            //console.error('Error fetching server zones:', err); // Log the full error for more details
            setMessage("Error loading data. Please try again.");
        } finally {
            setIsUpdating(false);
            setIsUpdateModalOpen(); // Close the modal after sending
            fetchCurrentClientUsers();
            setRole('');
        }
    }

    const sendInvitationMail = async () => {
        const requestBody = {
            'ActionType': 'send_invitation',
            'CustomerID': currentUser.CustomerID,
            'ToEmail': emailID,
            'UserID': generateCustomerID(),
            'Role': role,
            'CreatedOn': getUTCDateTime(),
        };
        try {
            console.log("Ivitation Request Body: ", requestBody);
            const response = await AxiosInstance.post('/manage-resource', requestBody); // Adjust the path to your API
            //(username, email, token, invited_by):

            console.log('Response Data:', response);
            if (response?.data?.message) {
                setTimeout(() => showAlert(response.data.message, response.data.status), 0);
                fetchCurrentClientUsers();
            }
        } catch (err) {
            //console.error('Error fetching server zones:', err); // Log the full error for more details
            setMessage("Error loading data. Please try again.");
        } finally {
            setIsSendingInvite(false);
            handleCloseModal(); // Close the modal after sending

        }
    }


    const fetchUsersRole = async () => {
        const requestBody = { 'ActionType': 'user_role', 'CustomerID': sessionStorage.getItem('CustomerID') };
        try {
            console.log('RequestBody Data:', requestBody);
            const response = await AxiosInstance.post('/users-role', requestBody); // Adjust the path to your API
            //console.log("ENV value: ", process.env.REACT_APP_BASE_URL);
            console.log('Response Data:', response);
            if (response?.data?.message && Array.isArray(response.data.message)) {
                setUserRoles(response.data.message); // Adjust based on your response structure
                setIsRoleMappingLoaded(true);
            } else {
                // console.log('Unexpected data format ', response);
            }
        } catch (err) {
            //console.error('Error fetching server zones:', err); // Log the full error for more details
            setMessage("Error loading data. Please try again.");
        } finally {
            setIsTableLoading(false);
        }
    };

    const fetchCurrentClientUsers = async () => {
        const requestBody = {
            'ActionType': 'current_users',
            'CustomerID': sessionStorage.getItem('CustomerID'),
        };
        try {
            console.log('RequestBody Data:', requestBody);
            const response = await AxiosInstance.post('/manage-resource', requestBody); // Adjust the path to your API
            //console.log("ENV value: ", process.env.REACT_APP_BASE_URL);
            console.log('Response Data:', response);
            if (response?.data?.message && Array.isArray(response.data.message)) {
                //setUserRoles(response.data.message); // Adjust based on your response structure
                //const filteredCustomerUsers = response.data.message.filter(user => user.CustomerID !== currentUser.CustomerID);
                setCustomerUsers(response.data.message);
            } else {
                // console.log('Unexpected data format ', response);
            }
        } catch (err) {
            //console.error('Error fetching server zones:', err); // Log the full error for more details
            setMessage("Error loading data. Please try again.");
        } finally {
            setIsTableLoading(false);
        }
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        setIsDeleteLoading(true);
        if (confirmationText === "Delete") {
            try {
                //const response = await deleteItem(itemToDelete.id);sessionStorage.getItem('CustomerID')
                const requestBody = { ActionType: "delete_user", CustomerID: currentUser.CustomerID, DeleteID: itemToDelete.CustomerID };
                console.log('Requested Data :', requestBody);
                const response = await AxiosInstance.post('/manage-resource', requestBody);
                console.log('Response Data :', response.data.message);
                //if (response.data.status === "success") {
                setTimeout(() => showAlert(response.data.message, response.data.status), 0);
                //alert("Item deleted successfully!");
                // Optionally, update state or remove item from table view
                fetchCurrentClientUsers();
                // } else {
                //     setTimeout(() => showAlert('Failed to delete item. Please try again.', response.data.status), 0);
                //     //alert("Failed to delete item. Please try again.");
                // }
            } catch (error) {
                setLoading(false);
                console.error("Error deleting item:", error);
                //alert("An error occurred while deleting. Please try again.");
                setTimeout(() => showAlert('An error occurred while deleting. Please try again.', 'failure'), 0);
            } finally {
                setIsDeleteLoading(false);
                setIsDeleteModalOpen(false);
                setConfirmationText("");
            }
        } else {
            setLoading(false);
            setTimeout(() => showAlert("The text doesn't match. Please type 'Delete' to confirm.", 'failure'), 0);
            // alert("The text doesn't match. Please type 'DELETE' to confirm.");
        }
    };

    const getRoleName = (roleId) => {
        if (!isRoleMappingLoaded) {
            return 'Loading...';
        }
        const role = userRoles.find(role => role.RoleID === roleId);
        return role ? role.RoleName : 'Unknown';
    };

    const hiddenColumns = React.useMemo(() => ["CustomerID"], []); // List of columns to hide
    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "Username",
                width: 150,
            },
            {
                Header: "Email ID",
                accessor: "EmailID",
                width: 150,
            },
            {
                Header: "Role",
                accessor: "Role",
                width: 50,
                Cell: ({ row }) => getRoleName(row.original.Role),
            },
            {
                Header: "Last Updated",
                accessor: "UpdatedOn",
                width: 130,
            },
            {
                Header: "Status",
                accessor: "UserStatus",
                width: 50,
            },
            {
                Header: "Actions",
                accessor: "actions",
                width: 30,
                Cell: ({ row }) => (
                    <div className="d-flex">
                        {/* Edit Icon */}
                        <i
                            className="bi bi-pencil-fill custom-edit-icon"
                            title="Edit"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleToUpdateClick(row.original)}
                        ></i>
                        {/* Delete Icon */}
                        <i
                            className="bi bi-trash-fill custom-delete-icon"
                            title="Delete"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick(row.original)}
                        ></i>
                    </div>
                ),
            },
            {
                accessor: "CustomerID", // Unique identifier
                Header: "", // No header
                width: 0,
                Cell: () => null, // No cell content
                style: { display: "none" },
            },
        ],
        [isRoleMappingLoaded]
    );


    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow } =
        useTable({
            columns,
            data: customerUsers.filter(user => user.CustomerID !== currentUser.CustomerID),
            initialState: { hiddenColumns },
        });
    return (
        <div>
            <Header setModalType={setModalType} />
            <SideBar />
            <div className="app-container">
                <main id="main" className="main">
                    <PageTitle title={"Security and Audit"} subtitle={"Users"} />
                    <AllModals modalType={modalType} handleClose={handleClose} setModalType={setModalType} />
                    <div className="d-flex justify-content-end mb-1">
                        <button className="btn btn-primary" onClick={handleOpenModal} style={{
                            //height: "30px", // Adjust the height as needed
                            fontSize: "14px", // Optional: Adjust font size for better alignment
                            padding: "5px",   // Optional: Adjust padding for a smaller look
                            width: "70px",
                            height: "auto",

                        }}>INVITE</button>
                    </div>
                    {isTableLoading ? (<div className="tab-panel">
                        <div className="w-100 p-4 shadow rounded bg-light destination-tb-custom-border"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <span
                                className="spinner-border spinner-border-lg text-primary"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '10px' }}
                            ></span></div></div>) : (<>
                                <div className="tab-panel">

                                    <Modal show={isModalOpen} onHide={handleCloseModal} centered backdrop="static" keyboard={false}>
                                        <div style={{ position: 'relative' }}>
                                            <button
                                                className="custom-modal-close-btn"
                                                onClick={handleCloseModal}
                                                aria-label="Close"
                                            >
                                                &times; {/* Close icon */}
                                            </button>
                                        </div>
                                        <Modal.Header>
                                            <Modal.Title style={{ fontSize: '18px' }}>Invite User</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{
                                            padding: '10px', // Reduce padding for a compact body
                                        }}>
                                            <Form>
                                                <div
                                                    style={{
                                                        backgroundColor: '#e7f9e7',
                                                        padding: '14px',
                                                        borderRadius: '5px',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center mb-3">
                                                        <Form.Label style={{
                                                            width: '150px',
                                                            fontSize: '16px',
                                                            marginRight: '5px',
                                                        }}>
                                                            Email ID
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter email ID"
                                                            value={emailID}
                                                            onChange={(e) => setEmailID(e.target.value)}
                                                            isInvalid={emailID && !validateEmail(emailID)}

                                                        />

                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <Form.Label style={{
                                                            width: '105px', marginRight: '5px', fontSize: '16px',
                                                            marginRight: '5px',
                                                        }}>
                                                            Role
                                                        </Form.Label>
                                                        <Form.Select
                                                            value={role}
                                                            isInvalid={!role}
                                                            onChange={(e) => setRole(e.target.value)}
                                                            style={{
                                                                width: '35%',
                                                                height: '36px',
                                                                fontSize: '15px',
                                                                padding: '4px 8px',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <option value="" disabled>
                                                                Select Role
                                                            </option>
                                                            {userRoles.slice(0, -1).map((role) => (
                                                                <option key={role.RoleID} value={role.RoleID}>
                                                                    {role.RoleName}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                </div>
                                                {/* {emailError && <div style={{ color: "red", marginLeft: "4px" }}>{emailError}</div>} */}
                                            </Form>
                                            {/* <Form>
                                    <Form.Group controlId="emailInput" style={{ marginBottom: '20px', width: '100%' }}>
                                        <Form.Label>EmailID</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter EmailID"
                                            value={emailID}
                                            onChange={(e) => setEmailID(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="roleSelect" style={{ marginBottom: '20px', width: '100%' }}>
                                        <Form.Label>Role</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                        >
                                            <option value="" disabled>Select Role</option>
                                            <option value="Admin">Admin</option>
                                            <option value="Editor">Editor</option>
                                            <option value="Viewer">Viewer</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form> */}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseModal}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={handleInvitationEmail}
                                                disabled={!emailID || !role} // Disable if email or role is empty
                                            >
                                                {isSendingInvite ? (
                                                    <>
                                                        <span
                                                            className="spinner-border spinner-border-sm me-2"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                        Please wait...
                                                    </>
                                                ) : (
                                                    "Send"
                                                )}

                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={isDeleteModalOpen} onHide={handleCancelDelete} backdrop="static" keyboard={false} centered>
                                        <div style={{ position: 'relative' }}>
                                            <button
                                                className="custom-modal-close-btn m-1"
                                                onClick={handleCancelDelete}
                                                aria-label="Close"
                                            >
                                                &times; {/* Close icon */}
                                            </button>
                                        </div>
                                        <Modal.Header>
                                            <Modal.Title>Confirm Deletion</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='delete-modal-body' style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            padding: '20px', // Optional, for spacing
                                        }}>
                                            <Form>
                                                <Form.Group controlId="confirmationText">
                                                    {itemToDelete?.CustomerID && (  // Check if SystemName is not null or empty
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            backgroundColor: '#f8d7da',
                                                            padding: '20px',
                                                            borderRadius: '5px',
                                                            textAlign: 'center',
                                                            alignItems: 'center', // Center items horizontally
                                                            justifyContent: 'center', // Center items vertically
                                                            gap: '10px', // Add spacing between elements
                                                        }}>
                                                            <div className="row">
                                                                <h5>Do you want to delete {itemToDelete.EmailID}?</h5>
                                                                <h6>Please type 'Delete' to confirm</h6>
                                                                <Form.Control
                                                                    style={{
                                                                        width: '300px',
                                                                        marginLeft: '70px',
                                                                    }}
                                                                    type="text"
                                                                    placeholder="Enter Delete to confirm"
                                                                    value={confirmationText}
                                                                    onChange={(e) => setConfirmationText(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCancelDelete}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="danger"
                                                onClick={handleConfirmDelete}
                                                disabled={confirmationText !== "Delete"}
                                            >
                                                {isDeleteloading ? (
                                                    <>
                                                        <span
                                                            className="spinner-border spinner-border-sm me-2"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                        Please wait...
                                                    </>
                                                ) : (
                                                    "Submit"
                                                )}
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={isUpdateModalOpen} onHide={handleUpdateCloseModal} centered backdrop="static" keyboard={false}>
                                        <div style={{ position: 'relative' }}>
                                            <button
                                                className="custom-modal-close-btn"
                                                onClick={handleUpdateCloseModal}
                                                aria-label="Close"
                                            >
                                                &times; {/* Close icon */}
                                            </button>
                                        </div>
                                        <Modal.Header>
                                            <Modal.Title style={{ fontSize: '18px' }}>Update User</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{
                                            padding: '10px', // Reduce padding for a compact body
                                        }}>
                                            {itemToUpdate ? (
                                                <div style={{
                                                    backgroundColor: '#e7f9e7',  // Light green background
                                                    padding: '5px',
                                                    borderRadius: '5px',
                                                    marginLeft: '10px',
                                                    marginRight: '10px'
                                                }}>
                                                    <div className="row">
                                                        <div className="col-4 text-end">
                                                            Name :
                                                        </div>
                                                        <div className="col-8 text-start">
                                                            {itemToUpdate.Username}
                                                        </div>
                                                        <div className="col-4 text-end mt-1">
                                                            Email ID :
                                                        </div>
                                                        <div className="col-8 text-start mt-1">
                                                            {itemToUpdate.EmailID}
                                                        </div>
                                                        <div className="col-4 text-end mt-1">
                                                            Role :
                                                        </div>
                                                        <div className="col-8 text-start mt-1">
                                                            <Form.Select
                                                                value={role}
                                                                isInvalid={!role}
                                                                onChange={(e) => setRole(e.target.value)}
                                                                style={{
                                                                    width: '50%',
                                                                    height: '30px',
                                                                    fontSize: '14px',
                                                                    padding: '4px 8px',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <option value="" disabled>
                                                                    Select Role
                                                                </option>
                                                                {userRoles.slice(0, -1).map((role) => (
                                                                    <option key={role.RoleID} value={role.RoleID}>
                                                                        {role.RoleName}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </div>
                                                        {/* Add other fields as needed */}
                                                    </div>
                                                </div>
                                            ) : (
                                                <p>No data available.</p>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleUpdateCloseModal}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={handleUpdateUser}
                                                disabled={!role} // Disable if role is empty
                                            >
                                                {isUpdating ? (
                                                    <>
                                                        <span
                                                            className="spinner-border spinner-border-sm me-2"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                        Updateing...
                                                    </>
                                                ) : (
                                                    "Update"
                                                )}

                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <div className="w-100 p-4 mt-2 shadow rounded bg-light destination-tb-custom-border">
                                        <table {...getTableProps()} className="table table-striped table-bordered">
                                            <thead>
                                                {headerGroups.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()} >
                                                        {headerGroup.headers.map((column) => (
                                                            <th {...column.getHeaderProps()} style={{ width: column.width }}>{column.render("Header")}</th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {rows.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                                            No data found.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    rows.map((row, i) => {
                                                        prepareRow(row);
                                                        return (
                                                            <tr {...row.getRowProps()}
                                                                key={row.id}
                                                                className={`custom-row-height ${i % 2 === 0 ? 'row-grey' : 'row-white'}`}>
                                                                {row.cells.map((cell) => (
                                                                    <td {...cell.getCellProps()} key={cell.column.id}>{cell.render("Cell")}</td>
                                                                ))}
                                                            </tr>
                                                        );
                                                    })
                                                )

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>)}
                </main>
            </div>
            <Footer />
            <BackToTop />
        </div >
    )
}

export default CompanyUsers