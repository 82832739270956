import React from 'react';
import './Nav.css';

import NavSearch from './NavSearch';
import NavNotice from './NavNotice';
import NavMessage from './NavMessage';
import NavAvatar from './NavAvatar';

function Nav({ setModalType }) {
    return (
        <nav className="dashboard-nav ms-auto">
            <ul className="d-flex align-items-center">
                <NavSearch />
                {/* <NavNotice />
                <NavMessage /> */}
                <NavAvatar setModalType={setModalType} />
            </ul>
        </nav>
    );
}

export default Nav;
