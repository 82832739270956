import React, { useState } from 'react';
import openEye from '../../Resource/openeye-bg.png';
import closeEye from '../../Resource/closeeye-bg.png';


function RegistrationPage3({ formData, setFormData }) {
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    //console.log("Form Data :", formData);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const [Country, City, ServerInstanceURL] = formData.RegionName.split('|');
    return (
        <div>
            <div className="form-data-summary scrollable-section">
                <div className="summary-item row">
                    <div className="summary-item">Account Type: <b>{formData.AccountType}</b>  {formData.AccountType === 'Enterprise' && (
                        <b>{formData.CustomerName}</b>
                    )}</div>
                </div>
                <div className="summary-item row">
                    <div className="summary-item">Name: {formData.FirstName} {formData.LastName}</div>

                </div>
                <div className="summary-item row">
                    <div className="summary-item">Address: {formData.CustomerAddress}</div>
                </div>
                <div className="summary-item row">
                    <div className="summary-item">Country: {formData.Country}</div>
                    <div className="summary-item">DC Region: {Country} {City}</div>
                </div>
                {/* <div className="summary-item row">
                    <div className="summary-item">System Time Zone: {formData.timezone}</div>
                </div> */}
            </div>

            <div className="form-group mt-2">
                <input type="email" className="form-control" placeholder="Enter Email" value={formData.EmailID} onChange={(event) => {
                    setFormData({ ...formData, EmailID: event.target.value })

                }} />

            </div>
            <div className="form-group mt-2">
                <div className="row">
                    <div className="col-md-6">
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            id="password"
                            name="password"
                            placeholder="Enter Password"
                            className="form-control"
                            value={formData.CustomerPassword}
                            onChange={(event) => {
                                setFormData({ ...formData, CustomerPassword: event.target.value })

                            }} />
                    </div>
                    <div className="col-md-6">
                        <div className="password-wrapper">
                            <input
                                type={passwordVisible ? 'text' : 'password'}
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Enter Confirm Password"
                                className="form-control"
                                value={formData.confirmpassword}
                                onChange={(event) => {
                                    setFormData({ ...formData, confirmpassword: event.target.value })

                                }} />
                            <button type="button" className="show-hide-btn" onClick={togglePasswordVisibility}>
                                {passwordVisible ? (
                                    <img src={openEye} alt="Hide Password" />
                                ) : (
                                    <img src={closeEye} alt="Show Password" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </div >
    )
}

export default RegistrationPage3