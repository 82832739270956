import React, { useEffect, useState, useMemo } from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import { useAlert } from '../../ProHelpers/AlertContext';
import Select from 'react-select';
import DatePicker from 'react-datepicker'; // Import DatePicker
import {
    getprofileAndUTCTime,
    formatUTCTimestamp,
    getUTCDateTime,
    getAccessTokenFromStorage,
    fetchAccessToken, fetchClientDetails, retryMessage
} from '../../ProHelpers/CommonUtils'
import AllModals from '../../ProHelpers/AllModals';
import { Tab, Tabs, Modal, Button } from 'react-bootstrap';
import { Checkbox } from '../../Components/Dashboard/Checkbox';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


function FailureRetry() {
    const EmailID = sessionStorage.getItem('EmailID');
    const systemTimeZone = sessionStorage.getItem('SystemTimeZone');
    const CustomerID = sessionStorage.getItem('CustomerID');
    const [modalType, setModalType] = useState(null);
    const handleClose = () => setModalType(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [activeTab, setActiveTab] = useState("get_1");
    //const [data, setData] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [timeRange, setTimeRange] = useState("default"); // Default time range
    const [customStart, setCustomStart] = useState("");
    const [customEnd, setCustomEnd] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [localPageSize, setLocalPageSize] = useState(25);
    const { showAlert } = useAlert();
    // Variables Start Here
    const navigate = useNavigate();
    const [currentUser, setcurrentUser] = useState([]); // Current User details
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [gotoInputValue, setGotoInputValue] = useState("");
    const [isTabLoading, setIsTabLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isRetrying, setIsRetrying] = useState(false);
    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
    const [calculatedTimeRange, setCalculatedTimeRange] = useState({ start: null, end: null });

    const [showViewModal, setShowViewModal] = useState(false);
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [showRecoverModal, setshowRecoverModal] = useState(false);
    const [showRetryModal, setshowRetryModal] = useState(false);

    const [selectedRowData, setSelectedRowData] = useState(null);

    const [iFlowNameFilter, setIFlowNameFilter] = useState(null);
    const [applicationIDFilter, setApplicationIDFilter] = useState(null);
    const [senderFilter, setSenderFilter] = useState(null);
    const [receiverFilter, setReceiverFilter] = useState(null);
    // Variables End Here
    const getTimeZone = (timeZone) => {
        if (!timeZone) {
            // Fallback to browser timezone if systemTimeZone is null or empty
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return timeZone;
    };

    const refreshTableData = () => {
        setIsTabLoading(true);
        const conditions = {
            DetailsPage: activeTab,
            CustomerID: CustomerID,
            End: timeRange === "custom" ? customEnd : calculatedTimeRange.endUTC,
            Start: timeRange === "custom" ? customStart : calculatedTimeRange.startUTC,
        };

        fetchDataAPI(conditions, currentPage, localPageSize);
    }

    const fetchDataAPI = async (conditions, Page = 1, PageSize = 25) => {
        setLoading(true);
        try {
            const requestBody = { ...conditions, Page, PageSize };
            console.log("Request Body:", requestBody);
            const response = await AxiosInstance.post('/failure-message', requestBody);
            console.log('Response Data:', response.data || []);
            setResponseData(response.data.message.data || []);
            setFilteredData(response.data.message.data || []);
            //setData(response.data.message.data || []); // Assuming `items` is the data list
            setTotalPages(Math.ceil(response.data.message.total_records / localPageSize) || 1); // Assuming `totalPages` is in response
            setCurrentPage(response.data.message.current_page);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };
    const deleteMessageAPI = async () => {
        const requestBody = {
            'DetailsPage': 'delete_' + activeTab,
            'CustomerID': CustomerID,
            'MessageID': selectedRows.map(row => row.MessageID),
        }
        try {
            console.log("Request Body:", requestBody);
            const response = await AxiosInstance.post('/failure-message', requestBody);
            console.log('Response Data:', response.data);
            if (response.status === 200) {
                //setResponseData(response.data.message);
                //setFilteredData(response.data.message);
                setshowDeleteModal(false);
                refreshTableData();
                console.log('After If Response Data:', response.data);
            } else {
                console.log('Else Message:', response.data.message);
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setIsDeleting(false);
        } finally {
            setIsDeleting(false);
        }
    };


    const recoverMessageAPI = async () => {
        const requestBody = {
            'DetailsPage': 'recover',
            'CustomerID': CustomerID,
            'MessageID': selectedRows.map(row => row.MessageID),
        }
        try {
            console.log("Request Body:", requestBody);
            const response = await AxiosInstance.post('/failure-message', requestBody);
            console.log('Response Data:', response.data);
            //console.log('After If Response Data:', response.data.message);
            //console.log('StatusCode:', response.status);
            if (response.status === 200) {
                //setResponseData(response.data.message);
                //setFilteredData(response.data.message);
                setshowRecoverModal(false);
                refreshTableData();
                console.log('After If Response Data:', response.data);
            } else {
                console.log('Else Message:', response.data.message);
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setIsDeleting(false);
        } finally {
            setIsDeleting(false);
        }
    };


    const retryMessagesForSelectedRows = async () => {
        // Fetch client details first
        const clientDetails = await fetchClientDetails();
        if (!clientDetails) {
            console.error('Client details not found');
            return;
        }

        // Then fetch the access token using the clientID
        let accessToken = getAccessTokenFromStorage();
        console.log('Access token from storage : ', accessToken);
        console.log('clientDetails : ', clientDetails.message.HostUrl);

        // If no valid token, fetch a new one
        if (!accessToken) {
            console.log('Access token is expired or not found, fetching a new one...');
            accessToken = await fetchAccessToken(clientDetails);
            if (!accessToken) {
                console.error('Unable to fetch a new access token');
                return;
            }
        }
        console.log('Access token from API : ', accessToken);

        // Create an array of promises for the selected rows
        const retryPromises = selectedRows.map(async (row) => {
            try {
                const requestBody = {
                    'DetailsPage': 'retry_message',
                    'CustomerID': CustomerID,
                    'data': row,
                    'hostUrl': clientDetails.message.HostUrl,
                    'token': accessToken
                };
                console.log('Request Data:', requestBody);
                const response = await AxiosInstance.post('/failure-message', requestBody);
                console.log('Response Data:', response);

                if (response.data.message.status_code === 200) {
                    console.log('Success Response Data:', response.data);
                    setTimeout(() => showAlert(response.data.message.body, 'success'), 0);
                } else {
                    console.log('else Message:', response.data.message);
                    setTimeout(() => showAlert(response.data.message.body, 'failure'), 0);
                }
            } catch (err) {
                console.error('Error fetching data for row:', err);
            }
        });

        // Wait for all retry API calls to complete
        await Promise.all(retryPromises);

        // Perform actions after all rows are processed
        setIsRetrying(false);
        setshowRetryModal(false);

        // Deselect all rows
        selectedFlatRows.forEach(row => {
            toggleRowSelected(row.id, false); // Deselect each row by its ID
        });

        // Refresh the table data
        refreshTableData();
    };



    // const retryMessagesForSelectedRows = async () => {
    //     // Fetch client details first
    //     const clientDetails = await fetchClientDetails();
    //     if (!clientDetails) {
    //         console.error('Client details not found');
    //         return;
    //     }

    //     // Then fetch the access token using the clientID
    //     let accessToken = getAccessTokenFromStorage();
    //     console.log('Access token from storage : ', accessToken);
    //     console.log('clientDetails : ', clientDetails.message.HostUrl);
    //     // If no valid token, fetch a new one
    //     if (!accessToken) {
    //         console.log('Access token is expired or not found, fetching a new one...');
    //         accessToken = await fetchAccessToken(clientDetails);
    //         if (!accessToken) {
    //             console.error('Unable to fetch a new access token');
    //             return;
    //         }
    //     }
    //     console.log('Access token from API : ', accessToken);
    //     // Iterate through the selectedRows array and call retryMessageAPI for each row
    //     for (let row of selectedRows) {
    //         try {
    //             const requestBody = {
    //                 'DetailsPage': 'retry_message',
    //                 'CustomerID': CustomerID,
    //                 'data': row,
    //                 'hostUrl': clientDetails.message.HostUrl,
    //                 'token': accessToken
    //             }
    //             console.log('Request Data:', requestBody);
    //             const response = await AxiosInstance.post('/failure-message', requestBody);
    //             console.log('Response Data:', response);
    //             if (response.data.message.status_code === 200) {
    //                 console.log('Success Response Data:', response.data);
    //                 console.log('Success Response Data:', response.data);
    //                 setTimeout(() => showAlert(response.data.message.body, 'success'), 0);
    //             } else {
    //                 console.log('else Message:', response.data.message);
    //                 setTimeout(() => showAlert(response.data.message.body, 'failure'), 0);
    //             }
    //         } catch (err) {
    //             setIsRetrying(false);
    //             console.log('Error fetching data for row:', err);

    //         } finally {
    //             // Optional: you can handle something after each API call here if needed
    //             setIsRetrying(false);
    //             setshowRetryModal(false);
    //             selectedFlatRows.forEach(row => {
    //                 toggleRowSelected(row.id, false); // Deselect each row by its ID
    //             });
    //             refreshTableData();
    //         }
    //     }
    // };


    const handleViewClick = (rowData) => {
        setSelectedRowData(rowData); // Store row data
        setShowViewModal(true); // Show the View modal
    };


    const handleCloseDeleteModal = () => {
        setshowDeleteModal(false);
    };
    const handleCloseViewModal = () => {
        setShowViewModal(false);
    };
    const handleCloseRecoverModal = () => {
        setshowRecoverModal(false);
    };

    const handleCloseRetryModal = () => {
        setshowRetryModal(false);
        selectedFlatRows.forEach(row => {
            toggleRowSelected(row.id, false); // Deselect each row by its ID
        });
    };
    // const getConditions = () => ({
    //     DetailsPage: activeTab,
    //     CustomerID: CustomerID,
    //     End: timeRange === "custom" ? customEnd : calTimeRange.endUTC,
    //     Start: timeRange === "custom" ? customStart : calTimeRange.startUTC,
    // });

    const handleDeleteAPI = () => {
        setIsDeleting(true);
        deleteMessageAPI();
    }
    const handleRecoverAPI = () => {
        setIsDeleting(true);
        recoverMessageAPI();
    }

    const handleRetryAPI = () => {
        setIsRetrying(true);
        //retryMessageAPI();
        retryMessagesForSelectedRows();

    }
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
        setCurrentPage(0); // Reset to first page when switching tabs
        setPageSize(25); // Reset to default page size
        //fetchDataAPI(getConditions(), 1, 25); // Default page and size
    };

    // useEffect(() => {
    //     handleTabSelect(activeTab);
    // }, [activeTab]);

    function saveTimeRange(tabId, timeRange) {
        // Retrieve the existing time ranges from localStorage
        const timeRanges = JSON.parse(localStorage.getItem("timeRanges")) || {};

        // Update the time range for the specific tab
        timeRanges[tabId] = timeRange;

        // Save the updated object back to localStorage
        localStorage.setItem("timeRanges", JSON.stringify(timeRanges));
    }

    function getTimeRange(tabId) {
        // Retrieve the time ranges object from localStorage
        const timeRanges = JSON.parse(localStorage.getItem("timeRanges")) || {};

        // Return the time range for the specific tab, or a default value
        return timeRanges[tabId] || "defaultTimeRange";
    }
    const resetAllTimeRanges = () => {
        const defaultTimeRange = "last24Hours"; // Define your default time range

        const timeRanges = {
            get_1: defaultTimeRange,
            get_2: defaultTimeRange,
            get_3: defaultTimeRange,
            get_4: defaultTimeRange
        };
        localStorage.setItem("timeRanges", JSON.stringify(timeRanges));

    };

    useEffect(() => {
        // Reset time range when leaving this page
        return () => {
            resetAllTimeRanges();
        };
    }, []);


    useEffect(() => {
        setIsTabLoading(true);
        const records = JSON.parse(sessionStorage.getItem('Records'));
        setcurrentUser(records[0]);
        // const currentTime = convertToTimeZone(systemTimeZone);
        //console.log('Current loggedin:', records[0]);
        //console.log(`Current user timezone ${records[0].TimeZone}:`, getTimeZone(records[0].TimeZone));
        //setFilteredData(responseData);
        //const storedTimeRange = localStorage.getItem("updatedTimeRange") || "last24Hours";
        const storedTimeRange = getTimeRange(activeTab) || "last24Hours";
        const systemTimeZone = records[0].TimeZone;
        let currentUserDateTime;
        //if (systemTimeZone && systemTimeZone != "") {
        console.log('First UseEffect pageload:', storedTimeRange);
        setShowCustomDatePicker(false);
        if (storedTimeRange) {
            currentUserDateTime = getprofileAndUTCTime(getTimeZone(systemTimeZone), storedTimeRange);
            setTimeRange(storedTimeRange);
        }
        if (currentUserDateTime) {
            setCalculatedTimeRange(currentUserDateTime); // Update state with the calculated range
            setCustomStart(currentUserDateTime.startUTC);
            setCustomEnd(currentUserDateTime.endUTC);
        }

        fetchDataAPI({
            'DetailsPage': activeTab,
            'CustomerID': CustomerID,
            'End': currentUserDateTime.endUTC,
            'Start': currentUserDateTime.startUTC,
        }, 1, localPageSize);
        // }

    }, [localPageSize, activeTab]);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchDataAPI({
            'DetailsPage': false,
            'EmailID': EmailID,
            'Start': calculatedTimeRange.startUTC,
            'End': calculatedTimeRange.endUTC,
        }, newPage, localPageSize);

    };


    const handlePageSizeChange = (newPageSize) => {
        setLocalPageSize(newPageSize);
        setPageSize(newPageSize);
    };


    // Define columns for React Table
    const columns = useMemo(
        () => [
            {
                id: "selection",

                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div className="custom-checkbox">
                        <input type="checkbox" {...getToggleAllRowsSelectedProps()} id="select-all" />
                        <label htmlFor="select-all"></label>
                    </div>
                    //<Form.Check {...getToggleAllRowsSelectedProps()} />
                ),
                // Cell: ({ row }) => <Form.Check {...row.getToggleRowSelectedProps()} />,
                Cell: ({ row }) => (
                    <div className="custom-checkbox">
                        <input
                            type="checkbox"
                            id={`checkbox-${row.id}`}  // Unique ID for each checkbox
                            {...row.getToggleRowSelectedProps()}  // Get the necessary props for row selection
                        />
                        <label htmlFor={`checkbox-${row.id}`}></label>  {/* Link the label to the input */}
                    </div>
                ),
            },
            { Header: "IFlow Name", accessor: "IFlowName", width: 200 },
            { Header: "Application ID", accessor: "ApplicationID", width: 200 },
            { Header: "Sender", accessor: "SenderSystem", width: 200 },
            { Header: "Receiver", accessor: "ReceiverSystem", width: 200 },
            {
                Header: "Details", accessor: "details", width: 40, Cell: ({ row }) => {
                    return (
                        <div className="d-flex">
                            {/* View Icon */}
                            <i
                                className="bi bi-info-square-fill table-custom-moreinfo-icon m-0 p-1"
                                title="More Details"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleViewClick(row.original)}
                            ></i>

                        </div >
                    );
                },
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        selectedFlatRows,
        toggleRowSelected,
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: { pageIndex: 0, pageSize: 25 },
        },
        usePagination,
        useRowSelect
    );

    // Handlers
    const handleOnChangeTimeRange = (event) => {
        const selectedValue = event.target.value;
        const userTimeZone = sessionStorage.getItem('SystemTimeZone');
        const userTimeRange = getprofileAndUTCTime(getTimeZone(userTimeZone), selectedValue);
        if (selectedValue !== "custom") {
            if (userTimeRange) {
                setCalculatedTimeRange(userTimeRange); // Update state with the calculated range
            }
            setShowCustomDatePicker(false)
            setTimeRange(selectedValue);
        } else {
            setCustomStart("");
            setCustomEnd("");
            setSelectedStartDate("");
            setSelectedEndDate("");
            setTimeRange(selectedValue);
            setShowCustomDatePicker(true);
        }
    };

    const handleCustomStartOnChange = (date) => {
        if (date) {
            const options = {
                systemTimeZone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            };
            // Use `toLocaleString` to get the datetime in the specified timezone
            const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
            console.log('Selected Start Date in Specified Timezone:', formattedDate);
            // Convert the formatted datetime to a UTC datetime string

            const utcStartDate = new Date(formattedDate.toLocaleString('en-US', { timeZone: 'UTC' }));

            console.log('Local Start Date:', formattedDate.toString());
            console.log('Converted UTC Start Date:', utcStartDate.toISOString());
            // Convert to a JavaScript Date object
            // Split the input into date and time parts
            const [datePart, timePart] = formattedDate.split(", ");

            // Rearrange the date part from MM/DD/YYYY to YYYY-MM-DD
            const [month, day, year] = datePart.split("/");
            const formattedUserDate = `${year}-${month}-${day} ${timePart}`;
            setSelectedStartDate(formattedUserDate);
            setCustomStart(utcStartDate.toISOString());
        }
    };

    const handleCustomEndOnChange = (date) => {
        if (date) {
            const options = {
                systemTimeZone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            };
            // Use `toLocaleString` to get the datetime in the specified timezone
            const formattedEndDate = new Intl.DateTimeFormat('en-US', options).format(date);
            console.log('Selected Date in Specified Timezone:', formattedEndDate);
            // Convert the formatted datetime to a UTC datetime string

            const utcEndDate = new Date(
                formattedEndDate.toLocaleString('en-US', { timeZone: 'UTC' })
            );

            console.log('Local End Date:', formattedEndDate.toString());
            console.log('Converted UTC End Date:', utcEndDate.toISOString());
            // Split the input into date and time parts
            const [datePart, timePart] = formattedEndDate.split(", ");

            // Rearrange the date part from MM/DD/YYYY to YYYY-MM-DD
            const [month, day, year] = datePart.split("/");
            const formattedUserDate = `${year}-${month}-${day} ${timePart}`;
            setSelectedEndDate(formattedUserDate);
            setCustomEnd(utcEndDate.toISOString());
        }
    };

    const handleConfirmClick = () => {
        let calTimeRange;
        let conditions;

        if (timeRange !== "custom") {
            saveTimeRange(activeTab, timeRange);
            localStorage.setItem("updatedTimeRange", timeRange)
            calTimeRange = getprofileAndUTCTime(getTimeZone(systemTimeZone), timeRange);
            if (calTimeRange) {
                setCalculatedTimeRange(calTimeRange); // Update state with the calculated range
            }
            console.log("Stored Time Range: ", timeRange);
        } else {
            // If "custom" is selected, use custom date-time values
            if (!customStart || !customEnd) {

                console.error("Custom date-time values are missing.");
                setTimeout(() => showAlert("Custom date time values are missing.", 'failure'), 0);
                return; // Exit if custom dates are not provided
            }

            if (customStart && customEnd) {
                setCalculatedTimeRange({ start: customStart, end: customEnd }); // Update state with the calculated range
            }
        }
        setIsTabLoading(true);
        conditions = {
            DetailsPage: activeTab,
            CustomerID: CustomerID,
            End: timeRange === "custom" ? customEnd : calTimeRange.endUTC,
            Start: timeRange === "custom" ? customStart : calTimeRange.startUTC,
        };

        fetchDataAPI(conditions, currentPage, localPageSize);
    };

    const handleLinkClick = () => {
        // Navigate with the MessageID or without it
        if (selectedRowData && selectedRowData.MessageID) {
            navigate(`/edit-payload/${selectedRowData.MessageID}`);
        } else {
            navigate(`/edit-payload`); // Navigate without MessageID
        }
    };

    // Filters Settings
    const getUniqueOptions = (key, responseData) => {
        return [...new Set(responseData.map(item => item[key]))]
            .filter(item => item) // remove empty values
            .map(item => ({ value: item, label: item }));
    };

    const handleFilterChange = (filter, setFilter, responseData) => (selectedOption) => {
        setFilter(selectedOption);
        applyFilters({
            iFlowName: iFlowNameFilter,
            applicationID: applicationIDFilter,
            senderName: senderFilter,
            receiverName: receiverFilter,
            [filter]: selectedOption
        }, responseData);
    };

    const applyFilters = (filters, responseData) => {
        setFilteredData(
            responseData.filter(item =>
                (!filters.iFlowName || item.IFlowName === filters.iFlowName?.value) &&
                (!filters.applicationID || item.ApplicationID === filters.applicationID?.value) &&
                (!filters.senderName || item.SenderSystem === filters.senderName?.value) &&
                (!filters.receiverName || item.ReceiverSystem === filters.receiverName?.value)
            )
        );
    };

    const handleResetFilters = () => {
        setIFlowNameFilter(null);
        setApplicationIDFilter(null);
        setSenderFilter(null);
        setReceiverFilter(null);
        setFilteredData(responseData); // Reset to all data
    };

    const handleSort = (key) => {
        // Toggle sorting order
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);

        // Sort the data
        const sortedData = [...responseData].sort((a, b) => {
            if (newSortOrder === 'asc') {
                return a[key].localeCompare(b[key]); // Ascending
            } else {
                return b[key].localeCompare(a[key]); // Descending
            }
        });

        // Update the state or table data
        setFilteredData(sortedData);
    };
    const handleRetryButtonClick = () => {
        const selectedAppID = selectedFlatRows.map((row) => row.original);
        console.log("Selected SNo Values:", selectedAppID);
        setSelectedRows(selectedAppID);
        setshowRetryModal(true);
        console.log("After selection: ", selectedAppID);
    }
    const handleRecoverButtonClick = () => {
        const selectedAppID = selectedFlatRows.map((row) => row.original);
        console.log("Selected SNo Values:", selectedAppID);
        setSelectedRows(selectedAppID);
        setshowRecoverModal(true);
        console.log("After selection: ", selectedAppID);

    }

    const handleDeleteButtonClick = () => {
        const selectedAppID = selectedFlatRows.map((row) => row.original);
        console.log("Selected SNo Values:", selectedAppID);
        setSelectedRows(selectedAppID);
        setshowDeleteModal(true);
        console.log("After selection: ", selectedAppID);
    };

    const customComponents = {
        DropdownIndicator: () => null, // Removes the dropdown icon
        IndicatorSeparator: () => null, // Removes the separator
    };

    return (
        <>
            <Header setModalType={setModalType} />
            <SideBar />
            <div className="app-container">

                <main id="main" className="main">
                    <PageTitle title={"Job Overall"} subtitle={"Job Overall"} />
                    <AllModals modalType={modalType} handleClose={handleClose} setModalType={setModalType} />
                    <Modal className="custom-modal-end" show={showViewModal} onHide={handleCloseViewModal} centered backdrop="static" keyboard={false}>
                        <div style={{ position: 'relative' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '15px', // Adjust top spacing
                                    right: '10px', // Adjust right spacing
                                    fontSize: '13px', // Font size
                                    color: '#333', // Optional text color
                                    backgroundColor: '#e7f9e7', // Light green background
                                    padding: '4px 7px', // Padding around the text
                                    borderRadius: '5px', // Rounded corners
                                    border: '1px solid rgb(181, 227, 181)',
                                }}
                            >
                                {selectedRowData ? (`Retried Count :${selectedRowData.RetriedCount}`) : ('')}
                            </div>
                        </div>
                        <Modal.Header>
                            <Modal.Title>More Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="custom-modal-body">
                            {/* Two-column layout */}
                            {selectedRowData ? (
                                <div style={{
                                    backgroundColor: '#e7f9e7',  // Light green background
                                    padding: '5px',           // Internal padding
                                    borderRadius: '5px',       // Rounded corners
                                    margin: '20px',            // Space around the element (top, bottom, left, right)
                                    width: 'calc(100% - 10px)', // Adjust width to account for margin (optional)
                                    height: 'calc(100% - 10px)', // Adjust height to account for margin (optional)
                                    boxSizing: 'border-box',   // Ensures padding doesn't affect width/height
                                }}>
                                    <div className="row">
                                        <div className="col-4 text-end" style={{ fontSize: '14px' }}>
                                            IFlow Name :
                                        </div>
                                        <div className="col-8 text-start" style={{ fontSize: '14px' }}>
                                            {selectedRowData.IFlowName.substring(0, 35) + '...'}
                                        </div>

                                        <div className="col-4 text-end" style={{ fontSize: '14px' }}>
                                            Message ID :
                                        </div>
                                        <div className="col-8 text-start" style={{ fontSize: '14px' }}>
                                            {['get_1', 'get_2', 'get_3'].includes(activeTab) ? (
                                                <span
                                                    onClick={handleLinkClick}
                                                    style={{ color: '#007bff', cursor: 'pointer' }}
                                                >
                                                    {selectedRowData.MessageID}
                                                </span>

                                            ) : (
                                                <span> {selectedRowData.MessageID} </span>
                                            )}
                                            {/* <Link to={`/edit-payload/${selectedRowData.MessageID}`} style={{ textDecoration: 'none', color: '#007bff' }}>
                                                {selectedRowData.MessageID}
                                            </Link> */}
                                        </div>

                                        <div className="col-4 text-end" style={{ fontSize: '15px' }}>
                                            Last Update :
                                        </div>
                                        <div className="col-8 text-start" style={{ fontSize: '14px' }}>
                                            {getUTCDateTime(selectedRowData.LastUpdate)}
                                        </div>
                                        <div className="col-4 text-end" style={{ fontSize: '15px' }}>
                                            Error Details :
                                        </div>
                                        <div className="col-8 text-start"
                                            style={{
                                                fontSize: '14px', whiteSpace: 'normal', // Allows text wrapping
                                                wordWrap: 'break-word', // Ensures text breaks at boundaries
                                                wordBreak: 'break-word', // Breaks long words
                                                overflowWrap: 'break-word', // Supports modern browsers for wrapping
                                            }}>
                                            {selectedRowData.ErrorDetails.substring(0, 85) + '...'}
                                        </div>
                                        {/* Add other fields as needed */}
                                    </div>
                                </div>
                            ) : (
                                <p>No data available.</p>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseViewModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="custom-modal-end" show={showDeleteModal} onHide={handleCloseDeleteModal} centered backdrop="static" keyboard={false}>
                        <div style={{ position: 'relative' }}>
                            <button
                                className="custom-modal-close-btn m-1"
                                onClick={handleCloseDeleteModal}
                                aria-label="Close"
                            >
                                &times; {/* Close icon */}
                            </button>
                        </div>
                        <Modal.Header>
                            <Modal.Title>Confirm Delete Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="custom-modal-body">
                            {/* Two-column layout */}
                            {selectedRows && selectedRows.length > 0 ? (
                                <div
                                    style={{
                                        backgroundColor: '#fce4e4', // Soft red background
                                        padding: '10px', // Internal padding
                                        borderRadius: '5px', // Rounded corners
                                        margin: '20px', // Space around the element
                                        width: 'calc(100% - 40px)', // Adjust width to account for margins
                                        boxSizing: 'border-box', // Ensures padding doesn't affect width/height
                                    }}
                                >
                                    Do you want to delete ?
                                    <div className="row">
                                        <div
                                            style={{
                                                whiteSpace: 'pre-line',
                                                marginBottom: '10px',
                                                height: '80px', // Fixed height for the content area
                                                overflowY: selectedRows.length > 5 ? 'auto' : 'hidden', // Enable scrolling for more than 5 records
                                                padding: '10px', // Optional: Add padding inside the content area

                                            }}
                                        >
                                            {selectedRows.map((row) => row.ApplicationID).join('\n')}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{
                                    backgroundColor: '#fce4e4', // Soft red background
                                    padding: '10px',           // Internal padding
                                    borderRadius: '5px',       // Rounded corners
                                    margin: '20px',            // Space around the element (top, bottom, left, right)
                                    width: 'calc(100% - 20px)', // Adjust width to account for margin (optional)
                                    height: 'calc(100% - 20px)', // Adjust height to account for margin (optional)
                                    boxSizing: 'border-box',   // Ensures padding doesn't affect width/height
                                    justifyContent: 'center',
                                    justifyItems: 'center',
                                    fontSize: '15px',
                                }}>
                                    <p>Oops!!! no data selected.</p>
                                </div>
                            )}
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseDeleteModal}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleDeleteAPI}>
                                {isDeleting ? (
                                    <>
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        Please wait...
                                    </>
                                ) : (
                                    "Confirm"
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="custom-modal-end" show={showRecoverModal} onHide={handleCloseRecoverModal} centered backdrop="static" keyboard={false}>
                        <div style={{ position: 'relative' }}>
                            <button
                                className="custom-modal-close-btn m-1"
                                onClick={handleCloseRecoverModal}
                                aria-label="Close"
                            >
                                &times; {/* Close icon */}
                            </button>
                        </div>
                        <Modal.Header>
                            <Modal.Title>Restore Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="custom-modal-body">
                            {/* Two-column layout */}
                            {selectedRows && selectedRows.length > 0 ? (
                                <div style={{
                                    backgroundColor: '#fce4e4', // Soft red background
                                    padding: '10px',           // Internal padding
                                    borderRadius: '5px',       // Rounded corners
                                    margin: '20px',            // Space around the element (top, bottom, left, right)
                                    width: 'calc(100% - 20px)', // Adjust width to account for margin (optional)
                                    height: 'calc(100% - 20px)', // Adjust height to account for margin (optional)
                                    boxSizing: 'border-box',   // Ensures padding doesn't affect width/height
                                }}>
                                    Do you want to restore ?
                                    <div className="row">
                                        <div
                                            style={{
                                                whiteSpace: 'pre-line',
                                                marginBottom: '10px',
                                                height: '80px', // Fixed height for the content area
                                                overflowY: selectedRows.length > 3 ? 'auto' : 'hidden', // Enable scrolling for more than 5 records
                                                padding: '10px', // Optional: Add padding inside the content area

                                            }}
                                        >
                                            {selectedRows.map((row) => row.ApplicationID).join('\n')}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{
                                    backgroundColor: '#fce4e4', // Soft red background
                                    padding: '10px',           // Internal padding
                                    borderRadius: '5px',       // Rounded corners
                                    margin: '20px',            // Space around the element (top, bottom, left, right)
                                    width: 'calc(100% - 20px)', // Adjust width to account for margin (optional)
                                    height: 'calc(100% - 20px)', // Adjust height to account for margin (optional)
                                    boxSizing: 'border-box',   // Ensures padding doesn't affect width/height
                                    justifyContent: 'center',
                                    justifyItems: 'center',
                                    fontSize: '15px',
                                }}>
                                    <p>Oops!!! no data selected.</p>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseRecoverModal}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleRecoverAPI}>
                                {isDeleting ? (
                                    <>
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        Please wait...
                                    </>
                                ) : (
                                    "Confirm"
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="custom-modal-end" show={showRetryModal} onHide={handleCloseRetryModal} centered backdrop="static" keyboard={false}>
                        <div style={{ position: 'relative' }}>
                            <button
                                className="custom-modal-close-btn m-1"
                                onClick={handleCloseRetryModal}
                                aria-label="Close"
                            >
                                &times; {/* Close icon */}
                            </button>
                        </div>
                        <Modal.Header>
                            <Modal.Title>Retry Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="custom-modal-body">
                            {/* Two-column layout */}
                            {selectedRows && selectedRows.length > 0 ? (
                                <div style={{
                                    backgroundColor: '#fce4e4', // Soft red background
                                    padding: '10px',           // Internal padding
                                    borderRadius: '5px',       // Rounded corners
                                    margin: '20px',            // Space around the element (top, bottom, left, right)
                                    width: 'calc(100% - 20px)', // Adjust width to account for margin (optional)
                                    height: 'calc(100% - 20px)', // Adjust height to account for margin (optional)
                                    boxSizing: 'border-box',   // Ensures padding doesn't affect width/height
                                }}>
                                    Do you want to retry ?
                                    <div className="row">
                                        <div
                                            style={{
                                                whiteSpace: 'pre-line',
                                                marginBottom: '10px',
                                                height: '80px', // Fixed height for the content area
                                                overflowY: selectedRows.length > 3 ? 'auto' : 'hidden', // Enable scrolling for more than 5 records
                                                padding: '10px', // Optional: Add padding inside the content area

                                            }}
                                        >
                                            {selectedRows.map((row) => row.ApplicationID).join('\n')}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{
                                    backgroundColor: '#fce4e4', // Soft red background
                                    padding: '10px',           // Internal padding
                                    borderRadius: '5px',       // Rounded corners
                                    margin: '20px',            // Space around the element (top, bottom, left, right)
                                    width: 'calc(100% - 20px)', // Adjust width to account for margin (optional)
                                    height: 'calc(100% - 20px)', // Adjust height to account for margin (optional)
                                    boxSizing: 'border-box',   // Ensures padding doesn't affect width/height
                                    justifyContent: 'center',
                                    justifyItems: 'center',
                                    fontSize: '15px',
                                }}>
                                    <p>Oops!!! no data selected.</p>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseRetryModal}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleRetryAPI}>
                                {isRetrying ? (
                                    <>
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        Please wait...
                                    </>
                                ) : (
                                    "Confirm"
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="tabs-header">
                        <div className="d-flex border-bottom flex-grow-1">
                            <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
                                <Tab eventKey="get_1" title="System Issue" />
                                <Tab eventKey="get_2" title="Data Issue" />
                                <Tab eventKey="get_3" title="Manually Archived" />
                                <Tab eventKey="get_4" title="Retry Successful" />
                            </Tabs>
                        </div>
                    </div>
                    <div className="left-align">
                        <div className="time-range-selector">
                            <label>
                                Time:{' '}
                                <select value={timeRange} onChange={handleOnChangeTimeRange}>
                                    <option value="last60Minutes">Last 60 Minutes</option>
                                    <option value="last24Hours">Last 24 Hours</option>
                                    <option value="lastWeek">Last Week</option>
                                    <option value="last30Days">Last 30 Days</option>
                                    <option value="custom">Custom</option>
                                </select>
                            </label>
                        </div>
                        {showCustomDatePicker ? (
                            <>
                                Start: &nbsp;
                                <div className="custom-calendar-divtag rounded bg-white">
                                    <DatePicker
                                        selected={selectedStartDate ? new Date(selectedStartDate) : null}
                                        onChange={handleCustomStartOnChange}
                                        customInput={
                                            <div className="custom-calendar-datepicker d-flex align-items-center">
                                                <input
                                                    type="text"
                                                    className="text-center w-100 custom-calendar-input-field"
                                                    placeholder="Select start date time"
                                                    value={selectedStartDate || ''}
                                                    readOnly
                                                />
                                                <span className="custom-calendar-icon ms-2"><i className="bi bi-calendar2-range-fill"></i></span>
                                            </div>
                                        }
                                        showTimeSelect
                                        dateFormat="Pp"
                                    />
                                </div>
                                &nbsp;&nbsp;End: &nbsp;
                                <div className="custom-calendar-divtag rounded bg-white">
                                    <DatePicker
                                        selected={selectedEndDate ? new Date(selectedEndDate) : null}
                                        onChange={handleCustomEndOnChange}
                                        customInput={
                                            <div className="custom-calendar-datepicker d-flex align-items-center">
                                                <input
                                                    type="text"
                                                    className="text-center w-100 custom-calendar-input-field"
                                                    placeholder="Select end date time"
                                                    value={selectedEndDate || ''}
                                                    readOnly
                                                />
                                                <span className="custom-calendar-icon ms-2"><i className="bi bi-calendar2-range-fill"></i></span>
                                            </div>
                                        }
                                        showTimeSelect
                                        dateFormat="Pp"
                                    />
                                </div>

                            </>) : (
                            <div className="selected-range">
                                {calculatedTimeRange.startLocal && calculatedTimeRange.endLocal && (
                                    <p><b>Start</b>: {formatUTCTimestamp(calculatedTimeRange.startLocal)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>End</b>: {formatUTCTimestamp(calculatedTimeRange.endLocal)}</p>
                                )}

                            </div>)}
                        <i class="bi bi-search custom-confirm-icon"
                            onClick={handleConfirmClick}></i>
                    </div>

                    {loading ? (
                        <div className="tab-panel">
                            <div
                                className="w-100 p-4 shadow rounded bg-light destination-tb-custom-border"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <span
                                    className="spinner-border spinner-border-lg text-primary"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: '10px' }}
                                ></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <table {...getTableProps()} className="monitoring-table">
                                <thead>
                                    <tr className="filter-row">
                                        <th className="column-header">
                                            <div class="customsearch-wrapper">

                                            </div>
                                        </th>
                                        <th className="column-header">
                                            <div class="customsearch-wrapper">
                                                <div class="customsearch-input-container">
                                                    <i class="bi bi-search customsearch-icon"></i>
                                                    <Select
                                                        options={getUniqueOptions('IFlowName', responseData)}
                                                        value={iFlowNameFilter}
                                                        onChange={handleFilterChange('iFlowName', setIFlowNameFilter, responseData)}
                                                        placeholder="IFlow Name"
                                                        className="customsearch-input"
                                                        components={customComponents}
                                                        styles={{
                                                            control: (base) => ({
                                                                ...base,
                                                                border: 'none', // Removes border
                                                                boxShadow: 'none', // Removes shadow
                                                                height: '20px',        // Reduces the height
                                                                minHeight: '20px',
                                                                alignContent: 'center'
                                                            }),

                                                        }}
                                                    />
                                                </div>
                                                <i
                                                    className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                    onClick={() => handleSort('IFlowName')} // Pass the key to sort
                                                    style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="column-header">
                                            <div class="customsearch-wrapper">
                                                <div class="customsearch-input-container">
                                                    <i class="bi bi-search customsearch-icon"></i>
                                                    <Select
                                                        options={getUniqueOptions('ApplicationID', responseData)}
                                                        value={applicationIDFilter}
                                                        onChange={handleFilterChange('applicationID', setApplicationIDFilter, responseData)}
                                                        placeholder="Application ID"
                                                        className="customsearch-input"
                                                        components={customComponents}
                                                        styles={{
                                                            control: (base) => ({
                                                                ...base,
                                                                border: 'none', // Removes border
                                                                boxShadow: 'none', // Removes shadow
                                                                height: '20px',        // Reduces the height
                                                                minHeight: '20px',
                                                                alignContent: 'center'
                                                            }),

                                                        }}
                                                    />
                                                </div>
                                                <i
                                                    className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                    onClick={() => handleSort('ApplicationID')} // Pass the key to sort
                                                    style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="column-header">
                                            <div class="customsearch-wrapper">
                                                <div class="customsearch-input-container">
                                                    <i class="bi bi-search customsearch-icon"></i>
                                                    <Select
                                                        options={getUniqueOptions('SenderSystem', responseData)}
                                                        value={senderFilter}
                                                        onChange={handleFilterChange('senderName', setSenderFilter, responseData)}
                                                        placeholder="Sender"
                                                        className="customsearch-input"
                                                        components={customComponents}
                                                        styles={{
                                                            control: (base) => ({
                                                                ...base,
                                                                border: 'none', // Removes border
                                                                boxShadow: 'none', // Removes shadow
                                                                height: '20px',        // Reduces the height
                                                                minHeight: '20px',
                                                                alignContent: 'center'
                                                            }),

                                                        }}
                                                    />
                                                </div>
                                                <i
                                                    className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                    onClick={() => handleSort('Sender')} // Pass the key to sort
                                                    style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="column-header">
                                            <div class="customsearch-wrapper">
                                                <div class="customsearch-input-container">
                                                    <i class="bi bi-search customsearch-icon"></i>
                                                    <Select
                                                        options={getUniqueOptions('ReceiverSystem', responseData)}
                                                        value={receiverFilter}
                                                        onChange={handleFilterChange('receiverName', setReceiverFilter, responseData)}
                                                        placeholder="Receiver"
                                                        className="customsearch-input"
                                                        components={customComponents}
                                                        styles={{
                                                            control: (base) => ({
                                                                ...base,
                                                                border: 'none', // Removes border
                                                                boxShadow: 'none', // Removes shadow
                                                                height: '20px',        // Reduces the height
                                                                minHeight: '20px',
                                                                alignContent: 'center'
                                                            }),

                                                        }}
                                                    />
                                                </div>
                                                <i
                                                    className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                    onClick={() => handleSort('Receiver')} // Pass the key to sort
                                                    style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                ></i>
                                            </div>

                                        </th>
                                        {['get_1', 'get_2', 'get_3'].includes(activeTab) && (
                                            <th className="column-header">
                                                <div className="d-flex">
                                                    {/* Reset Icon */}
                                                    <i
                                                        className="bi bi-repeat table-custom-repeat-icon"
                                                        title="Reset Filter"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={handleResetFilters}
                                                    ></i>
                                                    {/* Send Icon */}
                                                    {activeTab === 'get_3' ? ('') : (
                                                        <i
                                                            className="bi bi-send-fill table-custom-send-icon"
                                                            title="Retry"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={handleRetryButtonClick}
                                                        ></i>
                                                    )}
                                                    {/* Delete Icon */}
                                                    {activeTab === 'get_3' ? (
                                                        <i
                                                            className="bi bi-archive-fill table-custom-delete-icon"
                                                            title="Restore"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={handleRecoverButtonClick}

                                                        ></i>

                                                    ) : (
                                                        <i
                                                            className="bi bi-trash-fill table-custom-delete-icon"
                                                            title="Delete"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={handleDeleteButtonClick}

                                                        ></i>)
                                                    }
                                                </div >

                                            </th>
                                        )}
                                    </tr>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.length === 0 ? (
                                        <tr>
                                            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                                No data found.
                                            </td>
                                        </tr>
                                    ) : (
                                        page.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    key={row.id}
                                                    className={`custom-row-height ${i % 2 === 0 ? 'row-grey' : 'row-white'}`}
                                                >
                                                    {row.cells.map((cell) => (
                                                        <td {...cell.getCellProps()} key={cell.column.id}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>

                                            );
                                        })
                                    )}
                                </tbody>
                            </table>

                            <div className="details-pagination">
                                <div className="pagination-controls">
                                    <button onClick={() => handlePageChange(0)} disabled={pageIndex === 0}>
                                        {"<<"}
                                    </button>
                                    <button onClick={() => handlePageChange(pageIndex - 1)} disabled={pageIndex === 0}>
                                        {"<"}
                                    </button>
                                    <span>
                                        Page{" "}{pageIndex + 1} of {pageCount}
                                    </span>
                                    <button onClick={() => handlePageChange(pageIndex + 1)} disabled={pageIndex >= pageCount - 1}>
                                        {">"}
                                    </button>
                                    <button onClick={() => handlePageChange(pageCount - 1)} disabled={pageIndex >= pageCount - 1}>
                                        {">>"}
                                    </button>


                                    {/* Input Box and Go Button */}
                                    <div className="pagination-goto-page">
                                        <input
                                            type="number"
                                            min="1"
                                            max={totalPages}
                                            value={gotoInputValue}
                                            onChange={(e) => setGotoInputValue(e.target.value)}
                                            placeholder="Go to"
                                            className="pagination-goto-input"
                                        />
                                        <button
                                            onClick={() => gotoPage(Number(gotoInputValue))}
                                            disabled={!gotoInputValue || gotoInputValue < 1 || gotoInputValue > totalPages}
                                            className="pagination-goto-button"
                                        >
                                            Go
                                        </button>
                                    </div>
                                </div>
                                <div className="details-page-size-selector">
                                    <select
                                        value={localPageSize}
                                        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                    >
                                        {[25, 50, 75, 100].map((size) => (
                                            <option key={size} value={size}>
                                                {size}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                        </>)}

                </main>
            </div>
            <Footer />
            <BackToTop />
        </>

    )
}

export default FailureRetry;
