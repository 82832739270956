// import React, { createContext, useContext, useState } from 'react';
// import Alert from './Alert'; // Import the Alert component

// // Create the context
// const AlertContext = createContext();

// // Custom hook to use the alert context
// export const useAlert = () => useContext(AlertContext);

// // Create the provider component
// export const AlertProvider = ({ children }) => {
//     const [alert, setAlert] = useState({
//         message: '',
//         type: '',
//         isVisible: false,
//         duration: 3000,
//     });

//     // Function to show an alert
//     const showAlert = (message, type = 'success', duration = 3000) => {
//         setAlert({ message, type, isVisible: true, duration });
//     };

//     // Function to close the alert
//     const closeAlert = () => {
//         setAlert((prev) => ({ ...prev, isVisible: false }));
//     };

//     return (
//         <AlertContext.Provider value={{ showAlert, closeAlert }}>
//             {children}
//             {/* Conditionally render the Alert component */}
//             {alert.isVisible && (
//                 <Alert
//                     message={alert.message}
//                     type={alert.type}
//                     duration={alert.duration}
//                     onClose={closeAlert}
//                 />
//             )}
//         </AlertContext.Provider>
//     );
// };


// import React, { createContext, useContext, useState } from 'react';
// import Alert from './Alert'; // Import the Alert component

// // Create the context
// const AlertContext = createContext();

// // Custom hook to use the alert context
// export const useAlert = () => useContext(AlertContext);

// // Create the provider component
// export const AlertProvider = ({ children }) => {
//     const [alerts, setAlerts] = useState([]); // Store multiple alerts

//     // Function to show an alert
//     const showAlert = (message, type = 'success', duration = 3000) => {
//         const id = new Date().getTime(); // Unique id for each alert
//         setAlerts((prevAlerts) => [
//             ...prevAlerts,
//             { id, message, type, duration },
//         ]);

//         // Auto-close the alert after the specified duration
//         setTimeout(() => closeAlert(id), duration);
//     };

//     // Function to close a specific alert
//     const closeAlert = (id) => {
//         setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
//     };

//     return (
//         <AlertContext.Provider value={{ showAlert }}>
//             {children}
//             {/* Render all alerts */}
//             <div className="alert-container">
//                 {alerts.map((alert) => (
//                     <Alert
//                         key={alert.id}
//                         message={alert.message}
//                         type={alert.type}
//                         duration={alert.duration}
//                         onClose={() => closeAlert(alert.id)}
//                     />
//                 ))}
//             </div>
//         </AlertContext.Provider>
//     );
// };


import React, { createContext, useContext, useState } from 'react';
import Alert from './Alert'; // Import the Alert component

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([]); // Store multiple alerts

    // Function to show an alert
    const showAlert = (message, type = 'success', duration = 3000) => {
        const id = Date.now(); // Use a timestamp as a unique ID for each alert
        const newAlert = { id, message, type, duration };

        setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
    };

    // Function to close a specific alert
    const closeAlert = (id) => {
        setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
    };

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}
            <div className="alert-container">
                {alerts.map((alert) => (
                    <Alert
                        key={alert.id}
                        id={alert.id}
                        message={alert.message}
                        type={alert.type}
                        duration={alert.duration}
                        onClose={closeAlert} // Close individual alert
                    />
                ))}
            </div>
        </AlertContext.Provider>
    );
};

