import React, { useEffect, useState } from 'react'
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import './Endpoint.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import { useTable, usePagination, useSortBy } from 'react-table';
import { useAlert } from '../../ProHelpers/AlertContext';
import { Modal, Button, Form } from 'react-bootstrap';
import AllModals from '../../ProHelpers/AllModals';

function EndPointsConnectivity() {
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const [activeTab, setActiveTab] = useState("endpoint");
    const [endpointData, setEndpointData] = useState([])
    const [endpointDownloadData, setEndpointDownloadData] = useState([])
    const [oauthData, setOauthData] = useState([])
    const [isCreateVisible, setIsCreateVisible] = useState('cancel');
    const [oauthName, setOauthName] = useState("");
    const [validity, setValidity] = useState("");
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [itemToDelete, setItemToDelete] = useState(null);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [isSectionLoading, setIsSectionLoading] = useState(false);
    const [isDeleteloading, setIsDeleteLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const [modalType, setModalType] = useState(null);

    const handleClose = () => setModalType(null);

    useEffect(() => {
        fetchEndpointDataAPI();
        fetchOauthDataAPI();

    }, []);

    const handleCreateAction = () => {
        setIsCreateVisible("create");
        setShowViewModal(true);
    };
    const handleCloseModal = () => {
        setShowViewModal(false);
    };

    const handleCloseWarningModal = () => {
        setShowWarningModal(false);
    };

    function handleTabClick(tabName) {
        setActiveTab(tabName);
        setIsCreateVisible(tabName !== "endpoint"); // Set to false if "endpoint" tab is selected
    }

    const handleCancel = () => {
        setIsCreateVisible("cancel");
        setConfirmationText("");
    };

    const handleSaveOAuthData = () => {
        const oauthData = {
            ActionType: "save_oauth_data", // Add the key-value pair to formData
            CustomerID: sessionStorage.getItem('CustomerID'),
            Name: oauthName,
            ValidTill: validity
        };

        savedataAPI(oauthData);
    }

    const handleDeleteClick = (rowData) => {
        setItemToDelete(rowData);
        setIsDeleteModalOpen(true);
    };

    const handleCancelDelete = () => {
        setIsDeleteModalOpen(false);
        setConfirmationText("");
    };

    const handleValidityChange = (e) => {
        const value = e.target.value;
        setValidity(value);
        if (value === "" || (value >= 1 && value <= 365)) {
        } else {
            setTimeout(() => showAlert("Value should be between 1 and 365", 'warning'), 0);
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "Name",
                width: 150,
            },
            {
                Header: "Client ID",
                accessor: "ClientID",
                width: 300,
            },
            {
                Header: "Validity",
                accessor: "ValidTill",
                width: 150,

            },
            {
                Header: "Actions",
                accessor: "actions",
                width: 50,
                Cell: ({ row }) => (
                    <div className="d-flex justify-content-around">
                        {/* Delete Icon */}
                        <i
                            // className="fas fa-trash-alt text-primary"
                            className="bi bi-trash-fill custom-delete-icon"
                            title="Delete"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick(row.original)}
                        ></i>
                    </div>
                ),
            },
        ],
        []
    );
    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow } =
        useTable({
            columns,
            data: oauthData,

        }

        );

    const fetchEndpointDataAPI = async () => {
        setIsSectionLoading(true);
        const requestBody = { 'ActionType': 'ep_data', 'CustomerID': sessionStorage.getItem('CustomerID') };
        setLoading(true);
        setMessage("Fetching data...");
        try {
            const response = await AxiosInstance.post('/manage-resource', requestBody); // Adjust the path to your API
            console.log("Response Data: ", response);
            setLoading(false);
            if (response && response.data && response.data.message) {

                console.log("Response Get Data: ", response.data.message);
                // if (response.data.status == "success") {
                //     console.log("Response Get Data: ", response.data.message);
                //     setConnectionData(response.data.message);
                // } else {
                //     console.log("Response Get Data: ", response.data.message);
                //     console.log("Response Get Data: ", response.data.status);
                // }
                if (response.data.status == "success") {
                    setEndpointData(response.data.message);
                    setEndpointDownloadData(response.data.message);

                }
            } else {
                console.log("Empty or invalid response.");
                setEndpointData([]);
            }
        } catch (err) {
            console.error('Error fetching server zones:', err); // Log the full error for more details
            setEndpointData([]);
        } finally {
            setLoading(false);
            setIsSectionLoading(false);
        }
    };

    const savedataAPI = async (data) => {
        //alert(`Data saved successfully! Here is the form data:\n${JSON.stringify(data, null, 2)}`);
        try {
            setLoading(true);
            setIsSaveLoading(true);
            const requestBody = data;
            console.log('Requested Data :', requestBody);
            const response = await AxiosInstance.post('/manage-resource', requestBody); // Adjust the path to your API
            console.log('Response Data:', response);
            console.log('Single Data:', response.data.status);
            console.log('Single Data:', response.data.message);

            if (response.data.status == 'success') {
                setTimeout(() => showAlert("Successfully created", 'success'), 0);
                setIsCreateVisible(false);
                setLoading(false);
                setShowViewModal(false);
                setShowWarningModal(true);
                handleDownload(response.data.message);
                setOauthName('');
                setValidity('');

                fetchOauthDataAPI();
            } else {
                setTimeout(() => showAlert("Oops failed to create", 'danger'), 0);
                setIsCreateVisible(false);
            }
        } catch (err) {
            setLoading(false);
            console.log('Error fetching server zones:', err); // Log the full error for more details
            setIsCreateVisible(false);
        } finally {
            setLoading(false);
            setIsSectionLoading(false);
            setIsSaveLoading(false);
        }
    };

    const fetchOauthDataAPI = async () => {
        setIsSectionLoading(true);
        const requestBody = { 'ActionType': 'get_oauth', 'CustomerID': sessionStorage.getItem('CustomerID') };
        setLoading(true);
        setMessage("Fetching data...");
        try {
            const response = await AxiosInstance.post('/manage-resource', requestBody); // Adjust the path to your API
            console.log("Response Data: ", response);
            if (response && response.data && response.data.message) {
                if (response.data.message.length > 0) {
                    if (response.data.status == "success") {
                        console.log("Response Get Data: ", response.data.message);
                        setOauthData(response.data.message)
                    } else {
                        console.log("Response Get Data: ", response.data.message);
                        console.log("Response Get Data: ", response.data.status);
                    }
                } else {
                    console.log("No data found.");
                    setOauthData([]);
                }
            } else {
                console.log("Empty or invalid response.");
                setOauthData([]);
            }
        } catch (err) {
            //console.error('Error fetching server zones:', err); // Log the full error for more details
            setMessage("Error loading data. Please try again.");
        } finally {
            setLoading(false);
            setIsSectionLoading(false);
        }
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        setIsDeleteLoading(true);
        if (confirmationText === "Delete") {
            try {
                //const response = await deleteItem(itemToDelete.id);
                const requestBody = { ActionType: "delete", Type: "OAuth", CustomerID: sessionStorage.getItem('CustomerID'), SNo: itemToDelete.SNo };
                console.log('Requested Data :', requestBody);
                const response = await AxiosInstance.post('/manage-resource', requestBody);
                console.log('Response Data :', response.data.message);
                setLoading(false);
                if (response.data.message === "success") {
                    setTimeout(() => showAlert(`OAuth with name ${itemToDelete.Name} deleted successfully!`, 'success'), 0);
                    //alert("Item deleted successfully!");
                    // Optionally, update state or remove item from table view
                    fetchOauthDataAPI();
                } else {
                    setTimeout(() => showAlert('Failed to delete item. Please try again.', 'failure'), 0);
                    //alert("Failed to delete item. Please try again.");
                }
            } catch (error) {
                setLoading(false);
                console.error("Error deleting item:", error);
                //alert("An error occurred while deleting. Please try again.");
                setTimeout(() => showAlert('An error occurred while deleting. Please try again.', 'failure'), 0);
            } finally {
                setIsDeleteLoading(false);
                setIsDeleteModalOpen(false);
                setConfirmationText("");
            }
        } else {
            setLoading(false);
            setTimeout(() => showAlert("The text doesn't match. Please type 'Delete' to confirm.", 'failure'), 0);
            // alert("The text doesn't match. Please type 'DELETE' to confirm.");
        }
    };


    const handleDownloadEnpoints = () => {
        // List of keys to remove
        const keysToRemove = ["HostName", "TokenURL", "MasterMail", "SMTPName", "PleaseNote", "SupportEmail"];

        // Mapping of old keys to new keys (for renaming)

        const keyRenameMap = {
            "MessagesMonitoring": 'Monitoring',
            "MessageRetry": "Retry", // Example of renaming SupportEmail to NewSupportEmail
            "SAPJobMonitoring": 'Job'
        };

        // Remove keys dynamically
        keysToRemove.forEach((key) => {
            delete endpointDownloadData[key];
        });

        // Rename keys dynamically
        Object.keys(keyRenameMap).forEach((oldKey) => {
            if (endpointDownloadData.hasOwnProperty(oldKey)) {
                endpointDownloadData[keyRenameMap[oldKey]] = endpointDownloadData[oldKey];
                delete endpointDownloadData[oldKey]; // Remove the old key
            }
        });
        // Convert to plain text format
        const plainTextData = Object.entries(endpointDownloadData)
            .map(([key, value]) => `${key}: "${value}"`) // Format each key-value pair
            .join('\n'); // Join with new lines

        // Create a blob with the plain text data
        const blob = new Blob([plainTextData], { type: "text/plain" });

        // Create a link element
        const link = document.createElement("a");

        // Set the download URL to the blob
        link.href = URL.createObjectURL(blob);

        // Set the file name
        link.download = "Endpoints.txt";

        // Trigger the download
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(link.href);
    }


    const handleDownload = (response_data) => {
        const updatedData = {
            ...response_data, // Spread the existing data
            oauth: {
                ...response_data.oauth, // Spread the existing oauth object
                tokenurl: endpointData.TokenURL, // Add extra key-value pairs
                url: "https://" + endpointData.HostName,
            },
        };
        // Convert the object to a JSON string
        const jsonString = JSON.stringify(updatedData, null, 2);

        // Create a blob with the JSON data
        const blob = new Blob([jsonString], { type: "application/json" });

        // Create a link element
        const link = document.createElement("a");

        // Set the download URL to the blob
        link.href = URL.createObjectURL(blob);

        // Set the file name
        link.download = "IASF_" + oauthName + ".json";

        // Trigger the download
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(link.href);
    };



    return (
        <div>
            <Header setModalType={setModalType} />
            <SideBar />
            <div className="app-container">
                <main id="main" className="main">
                    <PageTitle title={"Connectivity"} subtitle={"Endpoint"} />
                    <AllModals modalType={modalType} handleClose={handleClose} setModalType={setModalType} />
                    <div className="oauth-download-container">

                        <Modal show={showWarningModal} onHide={handleCloseWarningModal} backdrop="static" keyboard={false} centered>
                            <Modal.Body className='oauth-body'>
                                {/* Close Icon */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '-10px',
                                        right: '-10px',
                                        cursor: 'pointer',
                                        backgroundColor: '#f8d7da', // Light red background matching the modal
                                        borderRadius: '50%',
                                        width: '30px',
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                    }}
                                    onClick={handleCloseWarningModal}
                                >
                                    <i className="bi bi-x-lg" style={{ fontSize: '0.8rem', color: '#721c24' }}></i>
                                </div>
                                {/* Body Content */}
                                <div
                                    style={{
                                        backgroundColor: '#f8d7da', // Light red background
                                        padding: '20px',
                                        borderRadius: '5px',
                                        marginTop: '0px',
                                        height: '120px'
                                    }}
                                >
                                    <p>
                                        Your new OAuth token has been downloaded. Please save it securely as it won't be visible again.
                                    </p>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal className="custom-modal-end" show={showViewModal} onHide={handleCloseModal} backdrop="static" keyboard={false} centered>
                            <div style={{ position: 'relative' }}>
                                <button
                                    className="custom-modal-close-btn m-1"
                                    onClick={handleCloseModal}
                                    aria-label="Close"
                                >
                                    &times; {/* Close icon */}
                                </button>
                            </div>
                            <Modal.Header>
                                <Modal.Title>Create OAuth Connection</Modal.Title>

                            </Modal.Header>
                            <Modal.Body className="custom-modal-body">
                                <Form>
                                    {/* <Form.Control.Feedback type="invalid">
                                                Name must be between 5 and 15 characters.
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Validity must be between 1 and 365 days.
                                            </Form.Control.Feedback> */}
                                    <div style={{
                                        backgroundColor: '#e7f9e7',  // Light green background
                                        padding: '20px',
                                        borderRadius: '5px',
                                        marginTop: '4px',

                                    }}>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <Form.Label className="me-3">Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    value={oauthName}
                                                    onChange={(e) => setOauthName(e.target.value)}
                                                    isInvalid={oauthName.length < 5 || oauthName.length > 15} // Highlight if invalid
                                                />

                                            </div>
                                        </div>
                                        <div className="mt-3 d-flex align-items-center">
                                            <Form.Label className="me-2">Validity</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Validity"
                                                value={validity}
                                                onChange={(e) => setValidity(e.target.value)}
                                                className="me-3"
                                                style={{ width: '150px' }} // Adjust width here
                                                isInvalid={!validity || isNaN(validity) || validity < 1 || validity > 365} // Validate range
                                            />

                                            <div
                                                className="text-muted"
                                                style={{
                                                    color: "grey",
                                                    backgroundColor: "#b3bbc0",
                                                    borderRadius: "5px",
                                                    padding: "5px 10px",
                                                }}
                                            >
                                                Range 1 - 365 Days
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={handleCloseModal}>Cancel</button>
                                <button className='btn btn-primary'
                                    disabled={
                                        oauthName.length < 5 ||
                                        oauthName.length > 15 ||
                                        isNaN(validity) ||
                                        validity < 1 ||
                                        validity > 365
                                    }

                                    onClick={handleSaveOAuthData}>
                                    {isSaveLoading ? (
                                        <>
                                            <span
                                                className="spinner-border spinner-border-sm me-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Please wait...
                                        </>
                                    ) : (
                                        "Save"
                                    )}

                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={isDeleteModalOpen} onHide={handleCancelDelete} backdrop="static" keyboard={false} centered>
                            <div style={{ position: 'relative' }}>
                                <button
                                    className="custom-modal-close-btn m-1"
                                    onClick={handleCancelDelete}
                                    aria-label="Close"
                                >
                                    &times; {/* Close icon */}
                                </button>
                            </div>
                            <Modal.Header>
                                <Modal.Title>Confirm Deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='delete-modal-body' style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '20px', // Optional, for spacing
                            }}>
                                <Form>
                                    <Form.Group controlId="confirmationText">
                                        {itemToDelete?.Name && (  // Check if SystemName is not null or empty
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                backgroundColor: '#f8d7da',
                                                padding: '20px',
                                                borderRadius: '5px',
                                                textAlign: 'center',
                                                alignItems: 'center', // Center items horizontally
                                                justifyContent: 'center', // Center items vertically
                                                gap: '10px', // Add spacing between elements
                                            }}>
                                                <div className="row">
                                                    <h5>Do you want to delete {itemToDelete.Name}?</h5>
                                                    <h6>Please type 'Delete' to confirm</h6>
                                                    <Form.Control
                                                        style={{
                                                            width: '300px',
                                                            marginLeft: '70px',
                                                        }}
                                                        type="text"
                                                        placeholder="Enter Delete to confirm"
                                                        value={confirmationText}
                                                        onChange={(e) => setConfirmationText(e.target.value)}

                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancelDelete}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={handleConfirmDelete}
                                    disabled={confirmationText !== "Delete"}
                                >
                                    {isDeleteloading ? (
                                        <>
                                            <span
                                                className="spinner-border spinner-border-sm me-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Please wait...
                                        </>
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {isSectionLoading ? (
                            <div className="w-100 p-4 shadow rounded bg-light destination-tb-custom-border"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <span
                                    className="spinner-border spinner-border-lg text-primary"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </div>
                        ) : (
                            <>
                                <div className="tabs-header">
                                    <button
                                        className={`tab-button ${activeTab === "endpoint" ? "active" : ""}`}
                                        onClick={() => handleTabClick("endpoint")}
                                    >
                                        Endpoint
                                    </button>
                                    <button
                                        className={`tab-button ${activeTab === "oauth" ? "active" : ""}`}
                                        onClick={() => handleTabClick("oauth")}
                                    >
                                        OAuth
                                    </button>
                                </div>
                                <div className="tabs-content">
                                    {activeTab === "endpoint" && (
                                        <div className="tab-section">

                                            <div className="two-column-layout">
                                                <div className="left-column">
                                                    <div className="inner-table">
                                                        <div className="inner-row">
                                                            <div className="inner-label">Retry</div>
                                                            <div className="inner-value">
                                                                {endpointData.MessageRetry}
                                                            </div>
                                                        </div>
                                                        <div className="inner-row">
                                                            <div className="inner-label">Monitoring</div>
                                                            <div className="inner-value">
                                                                {endpointData.MessagesMonitoring}
                                                            </div>
                                                        </div>
                                                        <div className="inner-row">
                                                            <div className="inner-label">Job</div>
                                                            <div className="inner-value">
                                                                {endpointData.SAPJobMonitoring}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-column">
                                                    <div className="d-flex justify-content-end mb-1">
                                                        <button className="btn btn-primary text-xs" onClick={handleDownloadEnpoints} title="Download Endpoint"><i className='bi bi-download'></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === "oauth" && (
                                        <div className="tab-section">
                                            <div className="d-flex justify-content-end mb-1">
                                                <button className="btn btn-primary text-xs" onClick={handleCreateAction} style={{
                                                    //height: "30px", // Adjust the height as needed
                                                    fontSize: "14px", // Optional: Adjust font size for better alignment
                                                    padding: "5px",   // Optional: Adjust padding for a smaller look
                                                    width: "70px",
                                                    height: "auto",

                                                }}>CREATE</button>
                                            </div>
                                            {/* {isCreateVisible === 'create' && (

                                                <div className="two-column-layout">
                                                   
                                                    <div className="left-column">
                                                        <div className="inner-table">
                                                            <div className="inner-row">
                                                                <div className="inner-label">Name</div>
                                                                <div className="inner-value">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        name="OAuthName"
                                                                        placeholder="Enter Name"
                                                                        value={oauthName}
                                                                        onChange={(e) => setOauthName(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>

                                                   
                                                    <div className="right-column">
                                                        <div className="inner-table">
                                                            <div className="inner-row">
                                                                <div className="inner-label">Validity</div>
                                                                <div className="inner-value">
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Validity"
                                                                                name="Validity"
                                                                                value={validity}
                                                                                onChange={handleValidityChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-5 custom-light-bg">
                                                                            <label>Range 1 - 365</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-primary mt-2 me-2" onClick={handleCancel}>Cancel</button>
                                                            <button className='btn btn-primary mt-2' onClick={handleSaveOAuthData}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>


                                            )} */}
                                            <div className="w-100 p-4 shadow rounded bg-light destination-tb-custom-border">
                                                <table {...getTableProps()} className="table table-striped table-bordered">
                                                    <thead>
                                                        {headerGroups.map((headerGroup) => (
                                                            <tr {...headerGroup.getHeaderGroupProps()} >
                                                                {headerGroup.headers.map((column) => (
                                                                    <th {...column.getHeaderProps()} style={{ width: column.width }}>{column.render("Header")}</th>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </thead>
                                                    <tbody {...getTableBodyProps()}>
                                                        {rows.length === 0 ? (
                                                            <tr>
                                                                <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                                                    No data found.
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            rows.map((row, i) => {
                                                                prepareRow(row);
                                                                return (
                                                                    <tr {...row.getRowProps()}
                                                                        key={row.id}
                                                                        className={`custom-row-height ${i % 2 === 0 ? 'row-grey' : 'row-white'}`}>
                                                                        {row.cells.map((cell) => (
                                                                            <td {...cell.getCellProps()} key={cell.column.id}>{cell.render("Cell")}</td>
                                                                        ))}
                                                                    </tr>
                                                                );
                                                            })
                                                        )

                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </main>
            </div>
            <Footer />
            <BackToTop />
        </div >
    )
}

export default EndPointsConnectivity