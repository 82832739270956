import React, { useState } from 'react';
import './AllModals.css'; // Create a separate CSS file for modal styling
import { Modal, Button, Col, Form, Spinner } from 'react-bootstrap';
import suggetionImg from './../Resource/suggestion.png';
import reportBugImg from './../Resource/bug-fix.png';
import suggetionImprovement from './../Resource/sug_imp.png';
import thankyouImage from './../Resource/thankyou.png';
import AxiosInstance from '../Components/RestApi/AxiosInstance';
import { useAlert } from '../ProHelpers/AlertContext';
import { AlignCenter, AlignJustify } from 'lucide-react';


const AllModals = ({ modalType, handleClose, setModalType }) => {
    // Only render the modal if "show" is true
    const EmailID = sessionStorage.getItem('EmailID');
    const [formData, setFormData] = useState({ email: EmailID, message: "", subject: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [screenMessage, setScreenMessage] = useState("");
    const { showAlert } = useAlert();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (type) => {
        setIsLoading(true);
        setApiResponse("");

        try {
            console.log("Email: ", EmailID, "Type: ", type, "Message: ", formData.message, "Subject:", formData.subject);
            await sendEmail(formData.message, type, formData.subject);
            setFormData({ type: "", message: "", subject: "" });

        } catch (error) {
            setApiResponse("Failed to submit. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const sendEmail = async (formmessage, type, subject) => {

        const data = {
            ActionType: 'improve_suggestion',
            name: sessionStorage.getItem('UserName'),
            email: sessionStorage.getItem('EmailID'),
            messagetype: type,
            mailmessage: formmessage,
            mailsubject: subject
        };
        console.log("Send Mail data for Suggestion: ", data);
        try {

            const response = await AxiosInstance.post('/SendMail', data);
            if (response.status === 200) {
                setModalType("sent_mail_modal");
                //setScreenMessage(`<p style="color: green; margin-bottom: 0;">${response.data.message}</p>`);
                //handleClose();
                // setScreenMessage(response.data.message);
                //setTimeout(() => showAlert(response.data.message, 'success'), 0);
            } else {
                setScreenMessage(`<p style="color: red; margin-bottom: 0;">${response.data.message}</p>`);
                //setScreenMessage(response.data.message);
                //setTimeout(() => showAlert(response.data.message, 'warning'), 0);
            }
        } catch (error) {
            // Handle error - Display error response in an alert
            setScreenMessage('<p style="color: red; margin-bottom: 0;">Oops!!! Something Went Wrong.</p>');
            //setTimeout(() => showAlert('oops!!! Something Went Wrong.', 'warning'), 0);
        } finally {
            setIsLoading(false);  // Stop loading
        }
    };

    const backhandler = () => {
        setScreenMessage("");
        setModalType("suggestion_improvement");
        console.log("Reset the message: ", screenMessage);
    }
    return (
        <>
            {/* Suggestion Modal */}
            <Modal show={modalType === "suggestion"} onHide={handleClose} centered backdrop="static" keyboard={false} size='xl'>
                <Modal.Body className="suggestion-modal-body">
                    <div className="row">
                        <div className="col-12 col-md-3 left-column">
                            <div className='row justify-content-center pt-2'>
                                <Col xs="auto">
                                    <div onClick={backhandler} >
                                        <i className="bi bi-chevron-left text-back-arrow"></i>
                                    </div>
                                </Col>
                                <Col>
                                    <h2 className="heading-text">Help us to improve</h2>
                                </Col>
                            </div>

                            <div className="row-1">
                                <Col>

                                    <div className="row-content">

                                        <div className="text-box-content" onClick={() => setModalType("suggestion")} >
                                            <div className="content-left">

                                                <p className="left-row-subheading mb-0">We’d love to know what you want to see in the future or what could be improved. We may contact you about the feedback you’ve shared and research opportunities related to your feedback.
                                                    <p dangerouslySetInnerHTML={{ __html: screenMessage || "<span></span>" }} className="left-row-subheading mb-0"></p>
                                                </p>

                                            </div>
                                        </div>

                                    </div>
                                </Col>

                            </div>

                            {/* Second Row */}
                            <div className="row-2">
                                <Col>

                                    <div className="row-content">
                                        <div className="text-box-content">
                                            <div className="feedback-form">
                                                <input type="text"
                                                    className="feedback-inputarea"
                                                    name="subject"
                                                    placeholder="Subject line"
                                                    value={formData.subject}
                                                    onChange={handleInputChange}
                                                    onFocus={() => setScreenMessage("")}
                                                />
                                                <textarea
                                                    className="feedback-textarea"
                                                    placeholder="Write here..."
                                                    name='message'
                                                    value={formData.message}
                                                    onChange={handleInputChange}
                                                    onFocus={() => setScreenMessage("")}
                                                ></textarea>
                                                <Button
                                                    variant="primary"
                                                    className='submit-button'
                                                    onClick={() => handleSubmit("suggestion")}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? (
                                                        <>
                                                            <span
                                                                className="spinner-border spinner-border-sm me-2"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                            Please wait...
                                                        </>
                                                    ) : (
                                                        "Submit"
                                                    )}

                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                        <div className="col-12 col-md-9 right-column">
                            <img src={suggetionImg} alt="Right Visual" className="right-image" />
                        </div>
                    </div>
                </Modal.Body>
                <Button
                    variant="outline-secondary"
                    onClick={handleClose}
                    className="suggestion-close-button"
                >
                    &times;
                </Button>
            </Modal>

            {/* Report Bug Modal */}
            <Modal show={modalType === "report_bug"} onHide={handleClose} centered backdrop="static" keyboard={false} size='xl'>
                <Modal.Body className="suggestion-modal-body">
                    <div className="row">
                        <div className="col-12 col-md-3 left-column">
                            <div className='row justify-content-center pt-2'>
                                <Col xs="auto">
                                    <div onClick={backhandler}>
                                        <i className="bi bi-chevron-left text-back-arrow" ></i>
                                    </div>
                                </Col>
                                <Col>
                                    <h2 className="heading-text">Report a bug</h2>
                                </Col>
                            </div>
                            <div className="row-1">
                                <Col>
                                    <div className="row-content">
                                        <div className="text-box-content">
                                            <div className="content-left">
                                                <h4 className="left-row-header"></h4>
                                                <p className="left-row-subheading mb-0">Tell us what what you were doing in IASF when you saw the bug, and what you expected to happen instead. Include as much detail as possible.</p>
                                                {/* <p className='text-success mb-0'>{screenMessage}</p> */}
                                                <p dangerouslySetInnerHTML={{ __html: screenMessage || "<span></span>" }} className="left-row-subheading mb-0"></p>
                                            </div>
                                            <div className="content-right">

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>

                            {/* Second Row */}
                            <div className="row-2">
                                <Col>
                                    <div className="row-content">
                                        <div className="text-box-content">
                                            <div className="feedback-form">
                                                {/* Binding the textarea value to the state */}
                                                <input type="text"
                                                    className="feedback-inputarea"
                                                    name="subject"
                                                    placeholder="Subject line"
                                                    value={formData.subject}
                                                    onChange={handleInputChange}
                                                    onFocus={() => setScreenMessage("")}
                                                />
                                                <textarea
                                                    name='message'
                                                    className="feedback-textarea"
                                                    placeholder="Write here..."
                                                    value={formData.message}
                                                    onChange={handleInputChange}
                                                    onFocus={() => setScreenMessage("")}
                                                ></textarea>

                                                <Button
                                                    variant="primary"
                                                    className="submit-button"
                                                    onClick={() => handleSubmit("report_bug")}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? (
                                                        <>
                                                            <span
                                                                className="spinner-border spinner-border-sm me-2"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                            Please wait...
                                                        </>
                                                    ) : (
                                                        "Submit"
                                                    )}


                                                </Button>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                        <div className="col-12 col-md-9 right-column">
                            <img src={reportBugImg} alt="Right Visual" className="right-image" />
                        </div>
                    </div>

                </Modal.Body>
                <Button
                    variant="outline-secondary"
                    onClick={handleClose}
                    className="suggestion-close-button"
                >
                    &times;
                </Button>
            </Modal>

            {/* Suggestion and Improvement Modal */}
            <Modal show={modalType === "suggestion_improvement"} onHide={handleClose} centered backdrop="static" keyboard={false} size='xl'>
                <Modal.Body className="suggestion-modal-body">
                    <div className="row">
                        <div className="col-12 col-md-3 left-column">
                            <h2 className="left-column-heading">Give feedback or report a bug</h2>
                            {/* First Row */}
                            <div className="row-1">
                                <Col>
                                    <div className="row-content">
                                        <div className="option-row-content" onClick={() => setModalType("suggestion")}>
                                            <div className="content-left">
                                                <h4 className="left-row-header">Help us to improve</h4>
                                                <p className="left-row-subheading">Tell us how we can make even better</p>
                                            </div>
                                            <div className="content-right">
                                                <i className="bi bi-chevron-right left-arrow-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>

                            {/* Second Row */}
                            <div className="row-2">
                                <Col>
                                    <div className="row-content">
                                        <div className="option-row-content" onClick={() => setModalType("report_bug")}>
                                            <div className="content-left">
                                                <h4 className="left-row-header">Report a bug</h4>
                                                <p className="left-row-subheading">Let us know if IASF not working as expected</p>
                                            </div>
                                            <div className="content-right">
                                                <i className="bi bi-chevron-right left-arrow-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                        <div className="col-12 col-md-9 right-column">
                            <img src={suggetionImprovement} alt="Right Visual" className="right-image" />
                        </div>
                    </div>
                </Modal.Body>
                <Button
                    variant="outline-secondary"
                    onClick={handleClose}
                    className="suggestion-close-button"
                >
                    &times;
                </Button>
            </Modal>
            {/* Successfuly Completion */}
            <Modal show={modalType === "sent_mail_modal"} onHide={handleClose} centered backdrop="static" keyboard={false} size='xl'>
                <div
                    style={{
                        display: "flex",
                        borderRadius: "15px",
                        overflow: "hidden",
                        height: "450px",
                    }}
                >
                    {/* Left Side */}
                    <div
                        style={{
                            flex: "1",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#f8f9fa",
                            padding: "20px",
                        }}
                    >
                        <h3 style={{ marginBottom: "20px", textAlign: "center", fontSize: "20px" }}>
                            Thank you for letting us know.
                            If you have more details, please reply to the email you received along with the attachment.
                        </h3>
                        <Button
                            variant="primary"
                            style={{
                                padding: "10px 20px",
                                fontSize: "14px",

                                height: "40px",
                                width: "180px"
                            }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </div>
                    {/* Right Side */}
                    <div
                        style={{
                            flex: "1",
                            backgroundImage: `url(${thankyouImage})`, // Use the `image` constant here
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>
                </div>
            </Modal>
        </>
    );
};

export default AllModals;
