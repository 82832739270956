import React, { useEffect, useState } from 'react';
import './header.css';

import Nav from './../Dashboard/Nav/Nav'
import brandImg from '../../Resource/logo.png';
import Logo from './../Dashboard/Logo';


function Header({ setModalType }) {
    const CompanyLogo = sessionStorage.getItem('CompanyLogo');
    const [currentUser, setcurrentUser] = useState([])
    //console.log("Company Logo: ", CompanyLogo);

    return (
        <header id="header" className="app-header fixed-top d-flex align-items-center" style={{
            borderTop: '2px solid #ccc', // Adjust thickness and color
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.4)',
        }}>
            <div className="logo-container">
                <Logo />
                <img
                    src={CompanyLogo ? `data:image/jpeg;base64,${CompanyLogo}` : brandImg}
                    alt="Company Logo"
                    className="brand-img"
                    style={{
                        transition: "transform 0.3s",
                        objectFit: 'contain', // Ensures the image fits inside the circle
                        borderRadius: '50%', // Ensures the container is circular
                        display: 'block', // Prevents unwanted spacing below the image
                    }} />
                {/* <img src={brandImg} alt="Brand Logo" className="brand-img" /> */}
            </div>
            <Nav setModalType={setModalType} />
        </header>
    );
}

export default Header;
