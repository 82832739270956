import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { useNavigate, useLocation } from 'react-router-dom';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import { truncateText, convertToCustomFormat, convertTableDataToUserTimeZone, extractErrorSnippet } from '../../ProHelpers/CommonUtils'
import './DetailsPage.css'
import './main.css';
import Select from 'react-select';
import Header from './Header';
import SideBar from './SideBar';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const DetailsPage = () => {
    const location = useLocation();
    const EmailID = sessionStorage.getItem('EmailID');

    const [messageOverallData, setMessageOverallData] = useState(null);
    const [header, setHeader] = useState(null);
    const [startEndTime, setStartEndTime] = useState(null);
    const [loading, setLoading] = useState(true);
    const [responseData, setresponseData] = useState([]);
    const [message, setMessage] = useState("Please wait, loading...");
    const [timeRange, setTimeRange] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [filteredData, setFilteredData] = useState([]);
    const [messageTypeFilter, setMessageTypeFilter] = useState(null);
    const [messageIDFilter, setMessageIDFilter] = useState(null);
    const [applicationIDFilter, setApplicationIDFilter] = useState(null);
    const [startFilter, setStartFilter] = useState(null);
    const [endFilter, setEndFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);
    const [isCustomTimeZone, setIsCustomTimeZone] = useState(false); // Default to false
    const [timeZone, setTimeZone] = useState("");
    const maxLength = 20; // Maximum length for text before adding "..."
    const [copySuccess, setCopySuccess] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
    const [gotoInputValue, setGotoInputValue] = useState("");
    const [localPageSize, setLocalPageSize] = useState(25);
    const [currentUser, setcurrentUser] = useState([]); // Spinner state
    const systemTimeZone = sessionStorage.getItem('SystemTimeZone');

    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
            })
            .catch((err) => console.error('Failed to copy text: ', err));
    };

    const getTimeZone = (timeZone) => {
        if (!timeZone) {
            // Fallback to browser timezone if systemTimeZone is null or empty
            const resolvedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // Correct the "Calcutta" to "Kolkata"
            if (resolvedTimeZone === "Asia/Calcutta") {
                return "Asia/Kolkata"; // Map Calcutta to Kolkata
            }

            return resolvedTimeZone;
        }
        return timeZone;
    };

    // const getInitialPageSize = () => {
    //     const savedPageSize = localStorage.getItem("tablePageSize");
    //     return savedPageSize ? parseInt(savedPageSize, 10) : 25; // Default: 25
    // };

    // const [pageSize, setPageSize] = useState(getInitialPageSize);

    const navigate = useNavigate(); // Hook for navigation

    const handleGoBackClick = () => {
        navigate('/monitoring/overall'); // Replace '/your-target-path' with the desired route
        // navigate('/monitoring/jobs-overall'); // Replace '/your-target-path' with the desired route
        // navigate('/monitoring/jobs-failure'); // Replace '/your-target-path' with the desired route
    };
    const handleSidebarItemClick = () => {
        // Optional: Clear localStorage if you want to remove the saved range
        //localStorage.removeItem("timeRange");

        // Reset the timeRange and date-time range
        setTimeRange("last24Hours");
        //calculateTimeRange("last24Hours");
    };
    const calculateTimeRange = (selectedRange) => {
        const end = new Date(); // current date and time
        let start;

        switch (selectedRange) {
            case "lastHour":
                start = new Date(end.getTime() - 60 * 60 * 1000); // Subtract 1 hour in milliseconds
                break;
            case "lastDay":
                start = new Date(end.getTime() - 24 * 60 * 60 * 1000); // Subtract 1 day in milliseconds
                break;
            case "lastWeek":
                start = new Date(end.getTime() - 24 * 7 * 60 * 60 * 1000); // Subtract 1 day in milliseconds
                break;
            case "lastMonth":
                // Handle month subtraction properly and account for day overflow
                const previousMonth = new Date(end);
                previousMonth.setMonth(end.getMonth() - 1); // Move to the same day of the previous month
                if (previousMonth.getMonth() === end.getMonth()) {
                    // This means the day in the previous month doesn't exist; adjust to the last day
                    previousMonth.setDate(0);
                }
                start = new Date(previousMonth);
                break;
            case "thisMonth":
                start = new Date(end.getFullYear(), end.getMonth(), 1, 0, 0, 0, 0); // Start of the current month
                break;
            default:
                start = null;
                break;
        }
        setStartEndTime((prev) => ({
            ...prev,
            start: start ? start.toISOString() : null,
            end: end.toISOString(),
        }));
        if (selectedRange) {
            localStorage.setItem("timeRange", selectedRange);
        }
    };

    // useEffect(() => {
    //     const records = JSON.parse(sessionStorage.getItem('Records'));
    //     setcurrentUser(records[0]);
    //     // const currentTime = convertToTimeZone(systemTimeZone);
    //     console.log('Current loggedin:', records[0]);
    //     console.log(`Current user timezone ${records[0].TimeZone}:`, getTimeZone(records[0].TimeZone));

    //     const sessionTimeZone = records[0].TimeZone;
    //     //console.log("TimeZone from Database: ", sessionTimeZone);

    //     if (sessionTimeZone && sessionTimeZone.trim() !== "") {
    //         setTimeZone(getTimeZone(sessionTimeZone));
    //     }
    //     // const utcDateString1 = "2024-10-24T13:50:24.442"; // UTC datetime string
    //     // const timeZone1 = "America/New_York"; // Example time zone


    //     // const localDate1 = convertTableDataToUserTZ(utcDateString1, systemTimeZone);
    //     // console.log('Converted Local UTC Time Here:', localDate1); // Outputs: 2024-10-24T09:50:24.442-04:00

    //     // if (sessionTimeZone && sessionTimeZone.trim() !== "") {
    //     //     // Use session time zone if it exists and is not empty
    //     //     //const utcTime = getUTCDateTimeWithOffset(sessionTimeZone);
    //     //     //setTimeZone(utcTime); // Example: "UTC+5:30"
    //     //     setIsCustomTimeZone(true);
    //     // } else {
    //     //     // Default to the browser's time zone if session time zone is null or empty
    //     //     //setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone); // Example: "America/New_York"
    //     // }

    // }, []); // Runs only on component mount

    useEffect(() => {
        //handleSort('ApplicationID');
        const records = JSON.parse(sessionStorage.getItem('Records'));
        setcurrentUser(records[0]);
        // const currentTime = convertToTimeZone(systemTimeZone);
        console.log('Current loggedin:', records[0]);
        console.log(`Current user timezone ${records[0].TimeZone}:`, getTimeZone(records[0].TimeZone));

        const sessionTimeZone = records[0].TimeZone;
        //console.log("TimeZone from Database: ", sessionTimeZone);

        // if (sessionTimeZone && sessionTimeZone.trim() !== "") {
        //     setTimeZone(getTimeZone(sessionTimeZone));
        // }
        // const sessionTimeZone = sessionStorage.getItem("SystemTimeZone");
        // const userBrowserTimeZone = "UTC+0:00";
        if (location.state) {
            // if (sessionTimeZone && sessionTimeZone.trim() !== "") {
            //     setTimeZone(sessionTimeZone);
            // } else {
            //     setTimeZone(userBrowserTimeZone);
            // }
            if (sessionTimeZone && sessionTimeZone.trim() !== "") {
                setTimeZone(getTimeZone(sessionTimeZone));
            }
            const { messageOverallData, header, dateRange } = location.state;
            const startEndTime = dateRange?.startEndTime;
            setMessageOverallData(messageOverallData);
            setHeader(header);
            setStartEndTime(startEndTime);
            const conditions = {
                'DetailsPage': true,
                "PackageName": messageOverallData.PackageName,
                "IFlowName": messageOverallData.IFlowName,
                "Status": header,
                "EmailID": EmailID,
                "End": startEndTime.end,
                "Start": startEndTime.start
            };

            fetchData(conditions, 1, localPageSize);
            console.log("Start Time: ", startEndTime.startLocal, "Endt Time: ", startEndTime.endLocal);
        } else {
            // Handle the case where no data is passed
            setLoading(false); // Avoid infinite loading
        }
    }, [location.state, localPageSize]);

    const fetchData = async (condition = {}, Page, PageSize) => {
        const requestBody = { ...condition, Page, PageSize };
        setLoading(true);
        setMessage("Please Wait, Loading...");
        console.log("Request Body:", requestBody);

        try {
            const response = await AxiosInstance.post('/message-processinglog', requestBody);

            if (response.data.status === "success" && response.data.message && response.data.message.data.length > 0) {
                setresponseData(response.data.message.data);
                setFilteredData(response.data.message.data);
                console.log("Response Data Body:", response.data.message.data);
                // Assuming the server returns totalItems and pageSize
                setTotalRecords(response.data.message.total_records);
                setTotalPages(Math.ceil(response.data.message.total_records / localPageSize));
                setCurrentPage(response.data.message.current_page);
                //handleSort('ApplicationID');
            } else {
                setresponseData([]);
                setFilteredData([]);
                setTotalRecords(1);
                setTotalPages(1); // Set to 1 if no data is available
            }

            // console.log('Response Data:', response.data);
            // console.log('Response first Item:', response.data.message.data);
        } catch (err) {
            //console.error('Error fetching data:', err);
            setresponseData([]);
            setFilteredData([]);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    };


    // Unique dropdown options
    const getUniqueOptions = (key) => {
        return [...new Set(responseData.map(item => item[key]))]
            .filter(item => item) // remove empty values
            .map(item => ({ value: item, label: item }));
    };

    // Handle dropdown changes
    const handleFilterChange = (filter, setFilter) => (selectedOption) => {
        setFilter(selectedOption);
        console.log("Selected Option: ", selectedOption);
        if (selectedOption.value == 'FAILED') {
            setErrorColumn(selectedOption);
        } else {
            setErrorColumn(null);
        }
        applyFilters({
            startValue: startFilter,
            endValue: endFilter,
            applicationID: applicationIDFilter,
            messageType: messageTypeFilter,
            messageID: messageIDFilter,
            status: statusFilter,
            [filter]: selectedOption // Use the selected filter here
        });
    };


    const applyFilters = (filters) => {
        setFilteredData(
            responseData.filter(item => {
                // Check if the header is not "COMPLETED"
                const isHeaderNotCompleted = header !== "COMPLETED";
                //alert(isHeaderNotCompleted);
                return (
                    (!filters.startValue || item.Start === filters.startValue?.value) &&
                    (!filters.endValue || item.End === filters.endValue?.value) &&
                    (!filters.applicationID || item.ApplicationID === filters.applicationID?.value) &&
                    (!filters.messageType || item.MessageType === filters.messageType?.value) &&
                    (!filters.messageID || item.MessageGuid === filters.messageID?.value) &&
                    (!isHeaderNotCompleted || (filters.status && item.Status === filters.status?.value)) // Apply status filter if header is not "COMPLETED"
                );
            })
        );
    };

    // const columns = React.useMemo(
    //     () => [
    //         {
    //             Header: 'Start',
    //             accessor: 'Start',
    //             width: 130,
    //             Cell: ({ value }) => {

    //                 return convertTableDataToUserTimeZone(value, systemTimeZone);
    //             },
    //         },
    //         {
    //             Header: 'End',
    //             accessor: 'End',
    //             width: 130,
    //             Cell: ({ value }) => {
    //                 return convertTableDataToUserTimeZone(value, systemTimeZone);
    //             },
    //         },
    //         { Header: 'Application ID', accessor: 'ApplicationID', disableSortBy: true, width: 130, },
    //         { Header: 'Message Type', accessor: 'MessageType', disableSortBy: true, width: 150, },
    //         {
    //             Header: 'MessageGuid', accessor: 'MessageGuid', width: 170,
    //             Cell: ({ row }) => (
    //                 <a
    //                     href={row.original.Link} // Use the value from the row data
    //                     target="_blank" // Open in a new tab
    //                     style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
    //                 >
    //                     {row.original.MessageGuid}
    //                 </a>
    //             ),
    //             disableSortBy: true,
    //         },

    //         // { Header: 'Link', accessor: 'Link' },
    //         {
    //             Header: 'Status',
    //             accessor: 'Status',
    //             disableSortBy: true,
    //             width: 70,
    //         },
    //     ],
    //     [isCustomTimeZone]
    // );
    const [errorColumn, setErrorColumn] = React.useState(null);

    const columns = React.useMemo(() => {
        let baseColumns = [
            {
                Header: 'Start',
                accessor: 'Start',
                width: 130,
                Cell: ({ value }) => convertTableDataToUserTimeZone(value, getTimeZone(timeZone)),
            },
            {
                Header: 'End',
                accessor: 'End',
                width: 130,
                Cell: ({ value }) => convertTableDataToUserTimeZone(value, getTimeZone(timeZone)),
            },
            { Header: 'Application ID', accessor: 'ApplicationID', disableSortBy: true, width: 130 },
            { Header: 'Message Type', accessor: 'MessageType', disableSortBy: true, width: 120 },
            {
                Header: 'MessageGuid',
                accessor: 'MessageGuid',
                width: 170,
                Cell: ({ row }) => (
                    <a
                        href={row.original.Link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        {row.original.MessageGuid}
                    </a>
                ),
                disableSortBy: true,
            },
            { Header: 'Status', accessor: 'Status', disableSortBy: true, width: 70 },
        ];

        // Add a new column if statusValue is set
        if (errorColumn) {
            console.log("Error Column: ", errorColumn);
            baseColumns.push({
                Header: 'Error',
                accessor: 'MsgError',
                width: 100,
                Cell: ({ value }) => (
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-${value || 'default'}`}>
                                    {value || 'No error message'}
                                </Tooltip>
                            }
                        >
                            <span>{extractErrorSnippet(value, 50) || 'Default Value'}</span>
                        </OverlayTrigger>
                    </span>
                ), // Customize cell rendering
            });
        }

        // Return filtered columns, excluding the MsgError column if errorColumn is false
        return baseColumns.filter(column => column.accessor !== 'MsgError' || errorColumn);
    }, [isCustomTimeZone, errorColumn]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize, // Keep this for useTable's state
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: { pageIndex: 0, pageSize: localPageSize }, // Use localPageSize here
            manualPagination: false,
        },
        useSortBy,
        usePagination
    );
    // Utility Functions


    const gotoPage = (page) => {
        const conditions = {
            'DetailsPage': true,
            "PackageName": messageOverallData.PackageName,
            "IFlowName": messageOverallData.IFlowName,
            "Status": header,
            "EmailID": EmailID,
            "End": startEndTime.end,
            "Start": startEndTime.start
        };

        setCurrentPage(page);
        fetchData(conditions, page, localPageSize);
    };

    // const handleConfirmClick = () => {
    //     const conditions = {
    //         'DetailsPage': true,
    //         "PackageName": messageOverallData.PackageName,
    //         "IFlowName": messageOverallData.IFlowName,
    //         "Status": header,
    //         "EmailID": EmailID,
    //         "End": startEndTime.end,
    //         "Start": startEndTime.start
    //     };

    //     fetchData(conditions, currentPage, localPageSize);
    // };
    const handleSort = (key) => {
        // Toggle sorting order
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);

        // Sort the data
        const sortedData = [...responseData].sort((a, b) => {
            if (newSortOrder === 'asc') {
                return a[key].localeCompare(b[key]); // Ascending
            } else {
                return b[key].localeCompare(a[key]); // Descending
            }
        });

        // Update the state or table data
        setFilteredData(sortedData);
    };
    const handlePageSizeChange = (event) => {
        const newPageSize = Number(event.target.value); // Get the new page size from the select input
        setLocalPageSize(newPageSize);// Set the new page size to the state
        setPageSize(newPageSize);
    };
    // Date formatting function to handle condition

    const handleResetFilters = () => {
        setStartFilter(null);
        setEndFilter(null);
        setStatusFilter(null);
        setApplicationIDFilter(null);
        setMessageTypeFilter(null);
        setMessageIDFilter(null);
        setFilteredData(responseData); // Reset to all data
    };

    const renderTooltip = (text) => (props) => (
        <Tooltip id="tooltip" {...props}>
            {copySuccess ? "Copied to clipboard" : text}
        </Tooltip>
    );

    const customComponents = {
        DropdownIndicator: () => null, // Removes the dropdown icon
        IndicatorSeparator: () => null, // Removes the separator
    };
    return (
        <div>
            {loading ? (
                <LoadingPopup message={message} />
            ) : (
                <>
                    <Header />
                    <SideBar onSidebarItemClick={handleSidebarItemClick} />
                    <div className="app-container">
                        <main id="main" className="main">
                            <PageTitle title={"Details Page"} subtitle={"Details"} />
                            <p className='details-datetime'>
                                {/* <i class="bi bi-backspace-fill"></i>
                                <button className="global-button" onClick={handleGoBackClick}>Go Back</button>{' '} */}
                                <i
                                    className="bi bi-backspace-fill custom-back-icon"
                                    title="Go Back"
                                    // style={{ cursor: "pointer", fontSize: "1.2rem", width: "1rem", height: "1rem", padding: "0.2rem", }}
                                    onClick={handleGoBackClick}
                                ></i>
                                From: {startEndTime.startLocal}
                                {'   '}To: {startEndTime.endLocal}
                            </p>
                            <div className="details-wrapper">

                                <div className='left-align-details'>
                                    <div className="details-left-side">
                                        <div className="labels-column">
                                            <div className="left-label">IFlow Name</div>
                                            <div className="left-label">Package Name</div>
                                        </div>
                                        <div className="values-column">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={renderTooltip(responseData[0].IFlowName)}
                                            >
                                                <div
                                                    className="left-value details-package-name d-flex align-items-center"
                                                    onClick={() => handleCopyClick(responseData[0].IFlowName)}
                                                >
                                                    {truncateText(responseData[0].IFlowName, maxLength)}
                                                    <i className="bi bi-copy custom-copy-icon"></i>
                                                </div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={renderTooltip(responseData[0].PackageName)}
                                            >
                                                <div className="left-value details-package-name d-flex align-items-center"
                                                    onClick={() => handleCopyClick(responseData[0].PackageName)}>
                                                    {truncateText(responseData[0].PackageName, maxLength)}
                                                    <i className="bi bi-copy custom-copy-icon"></i>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                                <div className='right-align-details'>
                                    <div className="details-right-side">
                                        <div className="labels-column">
                                            <div className="right-label">Sender</div>
                                            <div className="right-label">Receiver</div>
                                        </div>
                                        <div className="values-column">
                                            <div className="right-value">{responseData[0].Sender}</div>
                                            <div className="right-value">{responseData[0].Receiver}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='right-align-details'>
                                    <div className="details-exright-side">
                                        <div className="labels-column">
                                            <div className="right-label">Status</div>
                                            <div className="right-label">Total Count</div>
                                        </div>
                                        <div className={header === "COMPLETED" ? "values-column" : "values-column values-extreme-column"}>
                                            {header === "COMPLETED" ? (<div className="right-value">{header}</div>) :
                                                (
                                                    <div className="right-value">
                                                        <div className="details-status-dropdown-filter-container">
                                                            <Select
                                                                options={getUniqueOptions('Status')}
                                                                value={statusFilter}
                                                                onChange={handleFilterChange('status', setStatusFilter)}
                                                                placeholder="Status"
                                                                className="details-status-dropdown-filter"
                                                                classNamePrefix="react-select"
                                                            />
                                                        </div>
                                                    </div>
                                                )}


                                            <div className="right-value">{totalRecords}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {/* Table */}
                            <table {...getTableProps()} className="monitoring-table">
                                <thead>
                                    {/* Filter Row */}

                                    <tr className="filter-row-container">
                                        <th className="column-header">
                                            {/* <div class="customsearch-wrapper">
                                                <div class="input-container">
                                                    <i class="bi bi-search customsearch-icon"></i>
                                                    <input
                                                        type='text'
                                                        value={startFilter}
                                                        onChange={(e) => handleFilterChange('startValue', setStartFilter)(e.target.value)}
                                                        placeholder="Start"
                                                        className="details-input-filter"
                                                    />
                                                </div>
                                                <i
                                                    className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                    onClick={() => handleSort('Start')} 
                                                    style={{ cursor: 'pointer' }} 
                                                ></i>
                                            </div> */}

                                        </th>
                                        <th className="column-header">
                                            {/* <div class="customsearch-wrapper">
                                                <div class="input-container">
                                                    <i class="bi bi-search customsearch-icon"></i>
                                                    <input
                                                        type="text"
                                                        value={endFilter}
                                                        onChange={(e) => handleFilterChange('endValue', setEndFilter)(e.target.value)}
                                                        placeholder="End"
                                                        className="details-input-filter"
                                                    />
                                                </div>
                                                <i
                                                    className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                    onClick={() => handleSort('End')} // Pass the key to sort
                                                    style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                ></i>
                                            </div> */}
                                        </th>
                                        {/* <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('Start')}
                                                value={startFilter}
                                                onChange={handleFilterChange('startValue', setStartFilter)}
                                                placeholder="Start"
                                                className="details-dropdown-filter"
                                            />
                                        </th>
                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('End')}
                                                value={endFilter}
                                                onChange={handleFilterChange('endValue', setEndFilter)}
                                                placeholder="End"
                                                className="details-dropdown-filter"
                                            />
                                        </th> */}
                                        <th className="column-header">
                                            {/* <Select
                                                options={getUniqueOptions('ApplicationID')}
                                                value={applicationIDFilter}
                                                onChange={handleFilterChange('applicationID', setApplicationIDFilter)}
                                                placeholder="ApplicationID"
                                                className="details-dropdown-filter"
                                            /> */}
                                            <div class="customsearch-wrapper">
                                                <div class="customsearch-input-container">
                                                    <i class="bi bi-search customsearch-icon"></i>
                                                    <Select
                                                        options={getUniqueOptions('ApplicationID')}
                                                        value={applicationIDFilter}
                                                        onChange={handleFilterChange('applicationID', setApplicationIDFilter)}
                                                        placeholder="Application ID"
                                                        className="customsearch-input"
                                                        components={customComponents}
                                                        styles={{
                                                            control: (base) => ({
                                                                ...base,
                                                                border: 'none', // Removes border
                                                                boxShadow: 'none', // Removes shadow
                                                                height: '20px',        // Reduces the height
                                                                minHeight: '20px',
                                                                alignContent: 'center'
                                                            }),

                                                        }}
                                                    />
                                                </div>
                                                <i
                                                    className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                    onClick={() => handleSort('ApplicationID')} // Pass the key to sort
                                                    style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="column-header">
                                            {/* <Select
                                                options={getUniqueOptions('MessageType')}
                                                value={messageTypeFilter}
                                                onChange={handleFilterChange('messageType', setMessageTypeFilter)}
                                                placeholder="Message Type"
                                                className="details-dropdown-filter"
                                            /> */}
                                            <div class="customsearch-wrapper">
                                                <div class="customsearch-input-container">
                                                    <i class="bi bi-search customsearch-icon"></i>
                                                    <Select
                                                        options={getUniqueOptions('MessageType')}
                                                        value={messageTypeFilter}
                                                        onChange={handleFilterChange('messageType', setMessageTypeFilter)}
                                                        placeholder="Message Type"
                                                        className="customsearch-input"
                                                        components={customComponents}
                                                        styles={{
                                                            control: (base) => ({
                                                                ...base,
                                                                border: 'none', // Removes border
                                                                boxShadow: 'none', // Removes shadow
                                                                height: '20px',        // Reduces the height
                                                                minHeight: '20px',
                                                                alignContent: 'center'
                                                            }),

                                                        }}
                                                    />
                                                </div>
                                                <i
                                                    className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                    onClick={() => handleSort('MessageType')} // Pass the key to sort
                                                    style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                ></i>
                                            </div>
                                        </th>

                                        <th className="column-header">
                                            {/* <Select
                                                options={getUniqueOptions('MessageGuid')}
                                                value={messageIDFilter}
                                                onChange={handleFilterChange('messageID', setMessageIDFilter)}
                                                placeholder="Message Guid"
                                                className="details-dropdown-filter"
                                            /> */}
                                            <div class="customsearch-wrapper">
                                                <div class="customsearch-input-container">
                                                    <i class="bi bi-search customsearch-icon"></i>
                                                    <Select
                                                        options={getUniqueOptions('MessageGuid')}
                                                        value={messageIDFilter}
                                                        onChange={handleFilterChange('messageID', setMessageIDFilter)}
                                                        placeholder="Message Guid"
                                                        className="customsearch-input"
                                                        components={customComponents}
                                                        styles={{
                                                            control: (base) => ({
                                                                ...base,
                                                                border: 'none', // Removes border
                                                                boxShadow: 'none', // Removes shadow
                                                                height: '20px',        // Reduces the height
                                                                minHeight: '20px',
                                                                alignContent: 'center'
                                                            }),

                                                        }}
                                                    />
                                                </div>
                                                <i
                                                    className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                    onClick={() => handleSort('MessageGuid')} // Pass the key to sort
                                                    style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="column-header">
                                            {/* <button className="global-button" onClick={handleResetFilters}>Reset</button>{' '} */}
                                            <i
                                                className="bi bi-repeat custom-repeat-icon"
                                                title="Reset Filter"
                                                // style={{ cursor: "pointer", fontSize: "1.2rem", width: "1rem", height: "1rem", padding: "0.2rem", }}
                                                onClick={handleResetFilters}
                                            ></i>
                                        </th>

                                    </tr>

                                    <tr>
                                        <td colSpan={columns.length}>
                                            {/* Horizontal line above column names */}
                                            <hr style={{ margin: '-8px -8px', borderTop: '3px solid #ccc' }} />
                                        </td>
                                    </tr>
                                    {/* Column Headers Row */}
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()} key={column.id} style={{ width: column.width }}>
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                    {/*                                   
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps(column.id === 'Start' || column.id === 'End' ? column.getSortByToggleProps() : {})}
                                                    key={column.id}
                                                    className="column-header"
                                                    style={{ cursor: column.canSort ? 'pointer' : 'default' }} // Add pointer cursor for sortable columns
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        {column.render('Header')}
                                                        
                                                        {column.id === 'Start' || column.id === 'End' ? (
                                                            column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <span> <i className="fas fa-angle-down"></i></span>
                                                                ) : (
                                                                    <span> <i className="fas fa-angle-up"></i></span>
                                                                )
                                                            ) : (
                                                                <span> <i className="fas fa-angle-down"></i></span> // Default sort icon
                                                            )
                                                        ) : null}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))} */}
                                </thead>

                                <tbody {...getTableBodyProps()}>
                                    {page.length === 0 ? (
                                        <tr>
                                            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                                No data found.
                                            </td>
                                        </tr>
                                    ) : (
                                        page.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    key={row.id}
                                                    className={`custom-row-height ${i % 2 === 0 ? 'row-grey' : 'row-white'}`}
                                                >
                                                    {row.cells.map((cell) => (
                                                        <td {...cell.getCellProps()} key={cell.column.id}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                            <div className="details-table-top-left">
                                {/* Pagination controls */}
                                <div className="details-pagination">

                                    <div className="pagination-controls">
                                        <button onClick={() => gotoPage(1)} disabled={currentPage === 1}>
                                            <i className="fas fa-angle-double-left"></i>
                                        </button>
                                        <button onClick={() => gotoPage(currentPage - 1)} disabled={currentPage === 1}>
                                            <i className="fas fa-angle-left"></i>
                                        </button>
                                        <span>
                                            Page{' '}
                                            {currentPage} of {totalPages}
                                        </span>
                                        <button onClick={() => gotoPage(currentPage + 1)} disabled={currentPage === totalPages}>
                                            <i className="fas fa-angle-right"></i>
                                        </button>
                                        <button onClick={() => gotoPage(totalPages)} disabled={currentPage === totalPages}>
                                            <i className="fas fa-angle-double-right"></i>
                                        </button>
                                        {/* Input Box and Go Button */}
                                        <div className="pagination-goto-page">
                                            <input
                                                type="number"
                                                min="1"
                                                max={totalPages}
                                                value={gotoInputValue}
                                                onChange={(e) => setGotoInputValue(e.target.value)}
                                                placeholder="Go to"
                                                className="pagination-goto-input"
                                            />
                                            <button
                                                onClick={() => gotoPage(Number(gotoInputValue))}
                                                disabled={!gotoInputValue || gotoInputValue < 1 || gotoInputValue > totalPages}
                                                className="pagination-goto-button"
                                            >
                                                Go
                                            </button>

                                        </div>
                                    </div>
                                    <div className="details-page-size-selector">
                                        <label>
                                            <select value={localPageSize} onChange={handlePageSizeChange}>
                                                {[25, 50, 75, 100].map(size => (
                                                    <option key={size} value={size}>
                                                        {size}
                                                    </option>
                                                ))}
                                            </select>{' '}
                                        </label>
                                        {/* <button className="global-button" onClick={handleConfirmClick}>Refresh</button>{' '} */}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    <Footer />
                    <BackToTop />
                </>
            )
            }
        </div >
    );
};

export default DetailsPage;

