import React, { useEffect } from "react";

const SessionTimeout = ({ onLogout, children, navigate }) => {
    const timeoutDuration = 15 * 60 * 1000; // 15 minutes in milliseconds
    let timeoutId = null;

    const resetTimeout = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            handleLogout();
        }, timeoutDuration);
    };

    const handleLogout = () => {
        onLogout();
        if (navigate) navigate("/login"); // Use navigate prop for redirection
    };

    useEffect(() => {
        const events = ["mousemove", "keydown", "mousedown", "scroll", "touchstart"];
        events.forEach((event) => window.addEventListener(event, resetTimeout));
        resetTimeout();

        return () => {
            events.forEach((event) => window.removeEventListener(event, resetTimeout));
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, []);

    return <>{children}</>; // Render children to integrate SessionTimeout as a wrapper
};

export default SessionTimeout;
