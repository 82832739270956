import React, { useEffect, useState } from 'react';
import profileImgold from '../../../Resource/logo.png';
import profileImg from '../../../Resource/user_default.png';
import { Link, useNavigate } from 'react-router-dom';

import AxiosInstance from '../../RestApi/AxiosInstance';
import { useAlert } from '../../../ProHelpers/AlertContext';
import { Modal, Col, Button } from "react-bootstrap";
import suggetionImprovement from '../../../Resource/sug_imp.png';
import suggetionImg from '../../../Resource/suggestion.png';
import reportBugImg from '../../../Resource/bug-fix.png';
import SuggestionImprovementModal from "./../../../ProHelpers/SuggestionImprovementModal";

function NavAvatar({ setModalType }) {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const UserName = sessionStorage.getItem('UserName');
  const CustomerImage = sessionStorage.getItem('CustomerImage');

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Please wait, loading...");
  const [formsuggestionmessage, setFormSuggestionMessage] = useState('');
  //const [bugmessage, setBugMessage] = React.useState('');
  const [showSuggestionModal, setShowSuggestionModal] = useState(false); // State to control modal visibility
  const [showSuggestionImpModal, setShowSuggestionImpModal] = useState(false); // State to control modal visibility
  const [showReportBugModal, setShowReportBugModal] = useState(false); // State to control modal visibility
  const { showModal, closeModal } = useAlert();
  const [activeModal, setActiveModal] = useState(null);
  const [currentUser, setcurrentUser] = useState([]);
  const [bugmessage, setBugMessage] = useState('Write a report message.');


  // const handleOpenModal = (modalName) => setActiveModal(modalName);
  // const closeModal = () => setActiveModal(null);
  const handleSignOut = () => {

    // Clear session data
    sessionStorage.clear();
    //sessionStorage.removeItem('UserName');
    // Redirect to login page
    navigate('/login');
  };

  return (
    <li className="nav-item dropdown pe-3">
      <a
        className="nav-link nav-profile d-flex align-items-center pe-0"
        href="#"
        data-bs-toggle="dropdown"
      >
        {/* <img src={profileImg} alt="Profile" className="rounded-circle" /> */}
        <img
          src={CustomerImage ? `data:image/jpeg;base64,${CustomerImage}` : profileImg}
          alt="Profile"
          className="rounded-circle"
          style={{
            transition: "transform 0.3s",
            objectFit: 'contain', // Ensures the image fits inside the circle
            borderRadius: '50%', // Ensures the container is circular
            display: 'block', // Prevents unwanted spacing below the image
          }}
        />

        <span className="d-none d-md-block dropdown-toggle ps-2"></span>
      </a>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li className="dropdown-header">
          <h6>{UserName}</h6>
          {/* <span>Web Developer</span> */}
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <div className="dropdown-item d-flex align-items-center">
            <Link to="/userprofile" className="nav-link">
              <i className="bi bi-person"></i>
              <span>My Profile</span>
            </Link>

          </div>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <div
            className="dropdown-item d-flex align-items-center"
            onClick={() => setModalType("suggestion_improvement")}
            //onClick={() => setModalType("sent_mail_modal")}
            role="button"
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-envelope me-2"></i>
            <span>Suggestion & Improvement</span>
          </div>
        </li>
        {/* <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a
            className="dropdown-item d-flex align-items-center"
            href="pages-faq.html"
          >
            <i className="bi bi-question-circle"></i>
            <span>Need Help?</span>
          </a>
        </li> */}
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="#"
            onClick={handleSignOut}>
            <i className="bi bi-box-arrow-right"></i>
            <span>Sign Out</span>
          </a>
        </li>
      </ul>
    </li>
  );
}

export default NavAvatar;
