import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Link, useNavigate } from 'react-router-dom'; // Import Link for navigation
import openEye from '../../Resource/openeye-bg.png';
import closeEye from '../../Resource/closeeye-bg.png';
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import useCheckAuth from '../../ProHelpers/CheckAuth';
import Logo from '../../ProHelpers/Logo'
import loginImage from '../../Resource/loginImage.png'; // Add your image here
import './LoginPage.css';

const LoginPage = () => {

    useCheckAuth();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [email, setEmail] = useState(''); // State for email
    const [password, setPassword] = useState(''); // State for password

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const LoginApi = async (email, password) => {
        try {
            const requestBody = {
                EmailID: email,
                CustomerPassword: password
            };
            setLoading(true);
            setMessage("Please Wait, Logging...")
            const response = await AxiosInstance.post('/Login', requestBody); // Replace with actual API endpoint
            setLoading(false);
            const { message, reason, details } = response.data || {}; // Ensure 'data' exists
            console.log("Details: Response message ", details);
            if (message === "success") {
                //console.log("Verification: Response message ", response.data)
                sessionStorage.setItem('CustomerID', details.CustomerID);
                sessionStorage.setItem('EmailID', details.EmailID);
                const UserName = details.FirstName + ' ' + details.LastName;
                sessionStorage.setItem('UserName', UserName);
                sessionStorage.setItem('AccountType', details.AccountType);
                sessionStorage.setItem('SystemTimeZone', details.TimeZone);
                sessionStorage.setItem('CustomerImage', details.CustomerImage);
                sessionStorage.setItem('CustomerAddress', details.CustomerAddress);
                sessionStorage.setItem('CompanyLogo', details.CompanyLogo);
                addToSessionStorageArray('Records', details);

                navigate('/overview', { state: { message, reason } });
            } else {
                console.log("Verification: Response message ", response.data);
                setTimeout(() => showAlert(message, 'failure'), 0);
            }
        } catch (error) {
            setLoading(false);
            //console.log("Error fetching data from API:", error);
            return { message: "error", reason: "API request failed" };
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
        LoginApi(email, password); // Call the LoginApi function
    };
    function addToSessionStorageArray(key, value) {
        // Retrieve existing array from session storage or initialize as an empty array
        let existingArray = JSON.parse(sessionStorage.getItem(key)) || [];

        // Add the new record to the array
        existingArray.push(value);

        // Save the updated array back to session storage
        sessionStorage.setItem(key, JSON.stringify(existingArray));
    }
    return (
        <div>
            {loading ? (<LoadingPopup message={message} />) :
                (
                    <div className="login-page d-flex justify-content-center align-items-center">
                        <div className="loginpage-card shadow-lg">
                            {/* Left section with image and logo */}
                            <div className="loginpage-card-left d-none d-md-flex">
                                <Link to="/">
                                    <Logo className="logo" />
                                </Link>
                                <img src={loginImage} alt="Innodhee Solutions" className="loginpage-image" />
                            </div>

                            {/* Right section with form */}
                            <div className="loginpage-card-right p-4">
                                <h2 className="text-left app-branding-title" style={{ fontSize: "1.7rem" }}>Sign In</h2>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} // Update email state
                                            required
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="password">Password</label>
                                        <div className="custom-input-group">
                                            <div className="password-wrapper">
                                                <input
                                                    type={passwordVisible ? 'text' : 'password'}
                                                    id="password"
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Enter your password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)} // Update password state
                                                    required
                                                />
                                                <button type="button" className="show-hide-btn" onClick={togglePasswordVisibility}>
                                                    {passwordVisible ? (
                                                        <img src={openEye} alt="Hide Password" />

                                                    ) : (
                                                        <img src={closeEye} alt="Show Password" />

                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="loginpage-btn-container mt-4">
                                        <button type="submit" className="btn app-branding-button" onClick={handleSubmit}>Login</button>
                                    </div>
                                </form>
                                <div className="register-link text-end">
                                    <p>Don’t have an account? <Link to="/register">Create account</Link> | <Link to="/forgotpassword">Forgot Password</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>


                )}

        </div>
    );
}

export default LoginPage;

