import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import LoginPage from './Pages/Login/LoginPage';
import RegistrationPage from './Pages/Registration/RegistrationPage';
import { AlertProvider } from './ProHelpers/AlertContext';
import RegistrationComplete from './Pages/Registration/RegistrationComplete';
import VerificationPage from './Pages/Registration/VerificationPage';
import VerificationCompleted from './Pages/Registration/VerificationCompleted'

import ForgotPassword from './Pages/ForgetPassword/ForgotPassword'
import ForgotVerificationPage from './Pages/ForgetPassword/ForgotVerificationPage';
import ForgotPasswordSet from './Pages/ForgetPassword/ForgotPasswordSet';
import UnderConstruction from './Pages/UnderConstruction/UnderConstruction'
import NotFound from './Pages/NotFound/NotFound'
//import OverView from './Components/Dashboard/OverView';
import DashboardLayout from './Components/Dashboard/DashboardLayout'
import DetailsPage from './Components/Dashboard/DetailsPage'
import DetailsAllPage from './Components/Dashboard/DetailsAllPage'
// import Dashboard from './Components/Dashboard/Dashboard';
import MonitoringOverall from './Components/Dashboard/MonitoringOverall';
import MonitoringFailure from './Components/Dashboard/MonitoringFailure';
import JobOverall from './Components/Dashboard/JobOverall';
import JobsFailure from './Components/Dashboard/JobsFailure';
import UserProfile from './Components/Dashboard/UserProfile';
import DestinationConnectivity from './Components/Dashboard/DestinationConnectivity';
import EndPointsConnectivity from './Components/Dashboard/EndPoints';
import CompanyUsers from './Components/Dashboard/CompanyUsers';
import Dashboard from './Components/Dashboard/Dashboard';
import FailureRetry from './Components/Dashboard/FailureRetry';
import SecuritySystem from './Components/Dashboard/SecuritySystem';
import SecurityFeatures from './Components/Dashboard/SecurityFeatures';
import BrandingFeatures from './Components/Dashboard/BrandingFeatures';
import UserInvitation from './Components/Dashboard/UserInvitation';
import UserRegistrationPage from './Components/Dashboard/Nav/UserRegistrationPage';

// import MonitoringFailure from './Components/Dashboard/MonitoringFailure';
// import SecurityInvite from './Components/Dashboard/SecurityInvite';
// import SecurityDelete from './Components/Dashboard/SecurityDelete';
// import SecurityRoleAssignment from './Components/Dashboard/SecurityRoleAssignment';
// import SystemAuditMessages from './Components/Dashboard/SystemAuditMessages';
// import SystemAuditSecurity from './Components/Dashboard/SystemAuditSecurity';
// import SettingsInbound from './Components/Dashboard/SettingsInbound';
// import SettingsOutbound from './Components/Dashboard/SettingsOutbound';
import SessionTimeout from "./SessionTimeout";
import EditMessagePayload from './Components/Dashboard/EditMessagePayload';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  }, {
    path: "/register",
    element: <RegistrationPage />,
  }, {
    path: "/registrationcompleted",
    element: <RegistrationComplete />,
  }, {
    path: "/verification",
    element: <VerificationPage />,
  }, {
    path: "/verificationcompleted",
    element: <VerificationCompleted />,
  },
  {
    path: "/user-invitation",
    element: <UserInvitation />,
  },
  {
    path: "/overview",
    element: <Dashboard />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  }, {
    path: "/forgotverification",
    element: <ForgotVerificationPage />,
  }, {
    path: "/forgotpasswordset",
    element: <ForgotPasswordSet />
  }, {
    path: "/underconstruction",
    element: <UnderConstruction />
  }, {
    path: "/NotFound",
    element: <NotFound />
  },
  {
    path: "/monitoring/overall",
    element: <MonitoringOverall />
  },
  {
    path: "/monitoring/failure-retried",
    element: <MonitoringFailure />
  },
  {
    path: "/monitoring/jobs-overall",
    element: <JobOverall />
  },
  {
    path: "/monitoring/jobs-failure",
    element: <JobsFailure />
  },
  {
    path: "/monitoring/overall/details",
    element: <DetailsPage />
  },
  {
    path: "/monitoring/overall/detailsall",
    element: <DetailsAllPage />
  },
  {
    path: "/branding",
    element: <BrandingFeatures />
  },
  {
    path: "/features",
    element: <SecurityFeatures />
  },
  {
    path: "/userprofile",
    element: <UserProfile />
  },
  {
    path: "/connectivity/destination",
    element: <DestinationConnectivity />
  },
  {
    path: "/connectivity/endpoint",
    element: <EndPointsConnectivity />
  },
  {
    path: "/security/users",
    element: <CompanyUsers />
  },
  {
    path: "/security/system",
    element: <SecuritySystem />
  },
  {
    path: "/monitoring/failure-retry",
    element: <FailureRetry />
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/complete-user-registration",
    element: <UserRegistrationPage />,
  },
  {
    path: "/edit-payload/:messageID",
    element: <EditMessagePayload />,
  },
  {
    path: "/edit-payload",
    element: <EditMessagePayload />,
  },
  //  {
  //   path: "IntegrationService",
  //   element: <IntegrationSolution />,
  // }, {
  //   path: "AutomationTools",
  //   element: <AutomationTools />,
  // }, {
  //   path: "ComprehensiveResourcing",
  //   element: <ComprehensiveResourcing />,
  // }
]);


const AppWrapper = () => {
  // const location = useLocation();

  // const singlePageRoutes = [
  //   "/", // Add all single-page website routes here
  // ];
  // const isSinglePageWebsite = singlePageRoutes.includes(location.pathname);
  const handleLogout = () => {
    // Clear session data
    sessionStorage.clear();
    console.log("Logged out due to inactivity.");
    window.location.href = "/login"; // Redirect to login page
  };

  return (
    <>
      <SessionTimeout onLogout={handleLogout} />
      <AlertProvider>
        <RouterProvider router={router} />
      </AlertProvider>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(

//   <AlertProvider>
//     <RouterProvider router={router} />
//   </AlertProvider>

// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
