import { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import logo from '../../Resource/logo.png';
import LoadingPopup from '../../ProHelpers/LoadingPopup';

function UserInvitation() {
    const [searchParams] = useSearchParams();
    const verificationCode = searchParams.get('code');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const apiCalled = useRef(false);
    const navigate = useNavigate();
    useEffect(() => {
        const VerificationApi = async (code) => {
            try {
                const requestBody = {
                    ActivationCode: code
                };
                setLoading(true);
                setMessage("Please Wait, Trying...")
                const response = await AxiosInstance.post('/user-verify', requestBody); // Replace with actual API endpoint
                setLoading(false);
                // Extract the message and reason from API response
                const { message, reason, status } = response.data || {};
                console.log("UserVerify: Response message ", response)
                // Set state with the message and verification status

                navigate('/complete-user-registration', { state: { status, message, reason } });


                // if (message === "success") {
                //     console.log("User Verification: Response message ", reason)
                //     // navigate('/VerificationCompleted', { state: { message, reason } });
                //     navigate('/ForgotPasswordSet', { state: { status, message, reason } });
                // } else {
                //     console.log("User Verification: Response message ", reason)
                //     //navigate('/VerificationCompleted', { state: { message, reason } });
                // }
            } catch (error) {
                console.log("Verification: Response message ", error)
                //navigate('/VerificationCompleted', { state: { message: "error", reason: "Something Went Wrong, Please Try Again." } });
            } finally {
                setLoading(false);
            }
        };

        if (verificationCode && !apiCalled.current) {
            apiCalled.current = true;
            VerificationApi(verificationCode);
        }
    }, [verificationCode, navigate]); // Ensure `navigate` is included in the dependency array

    return (
        <div>
            {loading && <LoadingPopup message={message} />}
        </div>
    )
}

export default UserInvitation