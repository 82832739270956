import React, { useState } from "react";
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';

function SecuritySystem() {
    return (
        <>
            <Header />
            <SideBar />
            <div className="app-container">

                <main id="main" className="main">
                    <PageTitle title={"Security and Audit"} subtitle={"System"} />
                    <div>Security System</div>
                </main>
            </div>
            <Footer />
            <BackToTop />
        </>

    )
}

export default SecuritySystem