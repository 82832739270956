import React, { useEffect, useState } from 'react'
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import './UserProfile.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import AllModals from '../../ProHelpers/AllModals';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import { useAlert } from '../../ProHelpers/AlertContext';
import defaultuserImage from '../../Resource/user_default.png';

function UserProfile() {
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const [profileImage, setProfileImage] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");
    const [showModal, setShowModal] = useState(false);

    const customerID = sessionStorage.getItem('CustomerID');
    const EmailID = sessionStorage.getItem('EmailID');
    const FirstName = sessionStorage.getItem('FirstName');
    const UserName = sessionStorage.getItem('UserName');
    const AccountType = sessionStorage.getItem('AccountType');
    const [systemTimeZone, setSystemTimeZone] = useState(null);
    const [customerImage, setCustomerImage] = useState(null);
    const [isEditable, setIsEditable] = useState(false); // Toggle between editable and read-only
    const [isLoading, setIsLoading] = useState(false); // Spinner state
    const [currentUser, setcurrentUser] = useState([]); // Spinner state

    const [modalType, setModalType] = useState(null);
    const handleClose = () => setModalType(null);

    const selectTimeZones = [
        { code: "AS", country: "American Samoa", timezone: "Pacific/Pago_Pago", utc: "UTC-11:00" },
        { code: "NU", country: "Niue", timezone: "Pacific/Niue", utc: "UTC-11:00" },
        { code: "UM", country: "United States Minor Outlying", timezone: "Pacific/Midway", utc: "UTC-11:00" },
        { code: "PF", country: "French Polynesia", timezone: "Pacific/Marquesas", utc: "UTC -09:30" },
        { code: "PF", country: "French Polynesia", timezone: "Pacific/Gambier", utc: "UTC-09:00" },
        { code: "US", country: "United States", timezone: "America/Anchorage", utc: "UTC-09:00" },
        { code: "US", country: "United States", timezone: "America/Juneau", utc: "UTC-09:00" },
        { code: "US", country: "United States", timezone: "America/Metlakatla", utc: "UTC-09:00" },
        { code: "US", country: "United States", timezone: "America/Nome", utc: "UTC-09:00" },
        { code: "US", country: "United States", timezone: "America/Sitka", utc: "UTC-09:00" },
        { code: "US", country: "United States", timezone: "America/Yakutat", utc: "UTC-09:00" },
        { code: "CA", country: "Canada", timezone: "America/Vancouver", utc: "UTC-08:00" },
        { code: "MX", country: "Mexico", timezone: "America/Tijuana", utc: "UTC-08:00" },
        { code: "PN", country: "Pitcairn", timezone: "Pacific/Pitcairn", utc: "UTC-08:00" },
        { code: "US", country: "United States", timezone: "America/Los_Angeles", utc: "UTC-08:00" },
        { code: "CA", country: "Canada", timezone: "America/Cambridge_Bay", utc: "UTC-07:00" },
        { code: "CA", country: "Canada", timezone: "America/Creston", utc: "UTC-07:00" },
        { code: "CA", country: "Canada", timezone: "America/Dawson", utc: "UTC-07:00" },
        { code: "CA", country: "Canada", timezone: "America/Dawson_Creek", utc: "UTC-07:00" },
        { code: "CA", country: "Canada", timezone: "America/Edmonton", utc: "UTC-07:00" },
        { code: "CA", country: "Canada", timezone: "America/Fort_Nelson", utc: "UTC-07:00" },
        { code: "CA", country: "Canada", timezone: "America/Inuvik", utc: "UTC-07:00" },
        { code: "CA", country: "Canada", timezone: "America/Whitehorse", utc: "UTC-07:00" },
        { code: "MX", country: "Mexico", timezone: "America/Ciudad_Juarez", utc: "UTC-07:00" },
        { code: "MX", country: "Mexico", timezone: "America/Hermosillo", utc: "UTC-07:00" },
        { code: "MX", country: "Mexico", timezone: "America/Mazatlan", utc: "UTC-07:00" },
        { code: "US", country: "United States", timezone: "America/Boise", utc: "UTC-07:00" },
        { code: "US", country: "United States", timezone: "America/Denver", utc: "UTC-07:00" },
        { code: "US", country: "United States", timezone: "America/Phoenix", utc: "UTC-07:00" },
        { code: "BZ", country: "Belize", timezone: "America/Belize", utc: "UTC-06:00" },
        { code: "CA", country: "Canada", timezone: "America/Rankin_Inlet", utc: "UTC-06:00" },
        { code: "CA", country: "Canada", timezone: "America/Regina", utc: "UTC-06:00" },
        { code: "CA", country: "Canada", timezone: "America/Resolute", utc: "UTC-06:00" },
        { code: "CA", country: "Canada", timezone: "America/Swift_Current", utc: "UTC-06:00" },
        { code: "CA", country: "Canada", timezone: "America/Winnipeg", utc: "UTC-06:00" },
        { code: "CR", country: "Costa Rica", timezone: "America/Costa_Rica", utc: "UTC-06:00" },
        { code: "EC", country: "Ecuador", timezone: "Pacific/Galapagos", utc: "UTC-06:00" },
        { code: "SV", country: "El Salvador", timezone: "America/El_Salvador", utc: "UTC-06:00" },
        { code: "GT", country: "Guatemala", timezone: "America/Guatemala", utc: "UTC-06:00" },
        { code: "HN", country: "Honduras", timezone: "America/Tegucigalpa", utc: "UTC-06:00" },
        { code: "MX", country: "Mexico", timezone: "America/Bahia_Banderas", utc: "UTC-06:00" },
        { code: "MX", country: "Mexico", timezone: "America/Chihuahua", utc: "UTC-06:00" },
        { code: "MX", country: "Mexico", timezone: "America/Matamoros", utc: "UTC-06:00" },
        { code: "MX", country: "Mexico", timezone: "America/Merida", utc: "UTC-06:00" },
        { code: "MX", country: "Mexico", timezone: "America/Mexico_City", utc: "UTC-06:00" },
        { code: "MX", country: "Mexico", timezone: "America/Monterrey", utc: "UTC-06:00" },
        { code: "MX", country: "Mexico", timezone: "America/Ojinaga", utc: "UTC-06:00" },
        { code: "NI", country: "Nicaragua", timezone: "America/Managua", utc: "UTC-06:00" },
        { code: "US", country: "United States", timezone: "America/Chicago", utc: "UTC-06:00" },
        { code: "US", country: "United States", timezone: "America/Indiana/Knox", utc: "UTC-06:00" },
        { code: "US", country: "United States", timezone: "America/Indiana/Tell_City", utc: "UTC-06:00" },
        { code: "US", country: "United States", timezone: "America/Menominee", utc: "UTC-06:00" },
        { code: "US", country: "United States", timezone: "America/North_Dakota/Beulah", utc: "UTC-06:00" },
        { code: "US", country: "United States", timezone: "America/North_Dakota/Center", utc: "UTC-06:00" },
        { code: "US", country: "United States", timezone: "America/North_Dakota/New_Salem", utc: "UTC-06:00" },
        { code: "BS", country: "Bahamas", timezone: "America/Nassau", utc: "UTC-05:00" },
        { code: "BR", country: "Brazil", timezone: "America/Eirunepe", utc: "UTC-05:00" },
        { code: "BR", country: "Brazil", timezone: "America/Rio_Branco", utc: "UTC-05:00" },
        { code: "CA", country: "Canada", timezone: "America/Atikokan", utc: "UTC-05:00" },
        { code: "CA", country: "Canada", timezone: "America/Iqaluit", utc: "UTC-05:00" },
        { code: "CA", country: "Canada", timezone: "America/Toronto", utc: "UTC-05:00" },
        { code: "KY", country: "Cayman Islands", timezone: "America/Cayman", utc: "UTC-05:00" },
        { code: "CL", country: "Chile", timezone: "Pacific/Easter", utc: "UTC-05:00" },
        { code: "CO", country: "Colombia", timezone: "America/Bogota", utc: "UTC-05:00" },
        { code: "CU", country: "Cuba", timezone: "America/Havana", utc: "UTC-05:00" },
        { code: "EC", country: "Ecuador", timezone: "America/Guayaquil", utc: "UTC-05:00" },
        { code: "HT", country: "Haiti", timezone: "America/Port-au-Prince", utc: "UTC-05:00" },
        { code: "JM", country: "Jamaica", timezone: "America/Jamaica", utc: "UTC-05:00" },
        { code: "MX", country: "Mexico", timezone: "America/Cancun", utc: "UTC-05:00" },
        { code: "PA", country: "Panama", timezone: "America/Panama", utc: "UTC-05:00" },
        { code: "PE", country: "Peru", timezone: "America/Lima", utc: "UTC-05:00" },
        { code: "TC", country: "Turks and Caicos Islands", timezone: "America/Grand_Turk", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/Detroit", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/Indiana/Indianapolis", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/Indiana/Marengo", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/Indiana/Petersburg", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/Indiana/Vevay", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/Indiana/Vincennes", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/Indiana/Winamac", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/Kentucky/Louisville", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/Kentucky/Monticello", utc: "UTC-05:00" },
        { code: "US", country: "United States", timezone: "America/New_York", utc: "UTC-05:00" },
        { code: "AI", country: "Anguilla", timezone: "America/Anguilla", utc: "UTC-04:00" },
        { code: "AG", country: "Antigua and Barbuda", timezone: "America/Antigua", utc: "UTC-04:00" },
        { code: "AW", country: "Aruba", timezone: "America/Aruba", utc: "UTC-04:00" },
        { code: "BB", country: "Barbados", timezone: "America/Barbados", utc: "UTC-04:00" },
        { code: "BM", country: "Bermuda", timezone: "Atlantic/Bermuda", utc: "UTC-04:00" },
        { code: "BO", country: "Bolivia, Plurinational State of", timezone: "America/La_Paz", utc: "UTC-04:00" },
        { code: "BQ", country: "Bonaire, Sint Eustatius and Saba", timezone: "America/Kralendijk", utc: "UTC-04:00" },
        { code: "BR", country: "Brazil", timezone: "America/Boa_Vista", utc: "UTC-04:00" },
        { code: "BR", country: "Brazil", timezone: "America/Campo_Grande", utc: "UTC-04:00" },
        { code: "BR", country: "Brazil", timezone: "America/Cuiaba", utc: "UTC-04:00" },
        { code: "BR", country: "Brazil", timezone: "America/Manaus", utc: "UTC-04:00" },
        { code: "BR", country: "Brazil", timezone: "America/Porto_Velho", utc: "UTC-04:00" },
        { code: "CA", country: "Canada", timezone: "America/Blanc-Sablon", utc: "UTC-04:00" },
        { code: "CA", country: "Canada", timezone: "America/Glace_Bay", utc: "UTC-04:00" },
        { code: "CA", country: "Canada", timezone: "America/Goose_Bay", utc: "UTC-04:00" },
        { code: "CA", country: "Canada", timezone: "America/Halifax", utc: "UTC-04:00" },
        { code: "CA", country: "Canada", timezone: "America/Moncton", utc: "UTC-04:00" },
        { code: "CW", country: "Curaçao", timezone: "America/Curacao", utc: "UTC-04:00" },
        { code: "DM", country: "Dominica", timezone: "America/Dominica", utc: "UTC-04:00" },
        { code: "DO", country: "Dominican Republic", timezone: "America/Santo_Domingo", utc: "UTC-04:00" },
        { code: "GL", country: "Greenland", timezone: "America/Thule", utc: "UTC-04:00" },
        { code: "GD", country: "Grenada", timezone: "America/Grenada", utc: "UTC-04:00" },
        { code: "GP", country: "Guadeloupe", timezone: "America/Guadeloupe", utc: "UTC-04:00" },
        { code: "GY", country: "Guyana", timezone: "America/Guyana", utc: "UTC-04:00" },
        { code: "MQ", country: "Martinique", timezone: "America/Martinique", utc: "UTC-04:00" },
        { code: "MS", country: "Montserrat", timezone: "America/Montserrat", utc: "UTC-04:00" },
        { code: "PR", country: "Puerto Rico", timezone: "America/Puerto_Rico", utc: "UTC-04:00" },
        { code: "BL", country: "Saint Barthélemy", timezone: "America/St_Barthelemy", utc: "UTC-04:00" },
        { code: "KN", country: "Saint Kitts and Nevis", timezone: "America/St_Kitts", utc: "UTC-04:00" },
        { code: "LC", country: "Saint Lucia", timezone: "America/St_Lucia", utc: "UTC-04:00" },
        { code: "MF", country: "Saint Martin (French part)", timezone: "America/Marigot", utc: "UTC-04:00" },
        { code: "VC", country: "Saint Vincent and the Grenadines", timezone: "America/St_Vincent", utc: "UTC-04:00" },
        { code: "SX", country: "Sint Maarten (Dutch part)", timezone: "America/Lower_Princes", utc: "UTC-04:00" },
        { code: "TT", country: "Trinidad and Tobago", timezone: "America/Port_of_Spain", utc: "UTC-04:00" },
        { code: "VE", country: "Venezuela, Bolivarian Republic of", timezone: "America/Caracas", utc: "UTC-04:00" },
        { code: "VG", country: "Virgin Islands, British", timezone: "America/Tortola", utc: "UTC-04:00" },
        { code: "VI", country: "Virgin Islands, U.S.", timezone: "America/St_Thomas", utc: "UTC-04:00" },
        { code: "AQ", country: "Antarctica", timezone: "Antarctica/Palmer", utc: "UTC-03:00" },
        { code: "AQ", country: "Antarctica", timezone: "Antarctica/Rothera", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/Buenos_Aires", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/Catamarca", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/Cordoba", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/Jujuy", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/La_Rioja", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/Mendoza", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/Rio_Gallegos", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/Salta", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/San_Juan", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/San_Luis", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/Tucuman", utc: "UTC-03:00" },
        { code: "AR", country: "Argentina", timezone: "America/Argentina/Ushuaia", utc: "UTC-03:00" },
        { code: "BR", country: "Brazil", timezone: "America/Araguaina", utc: "UTC-03:00" },
        { code: "BR", country: "Brazil", timezone: "America/Bahia", utc: "UTC-03:00" },
        { code: "BR", country: "Brazil", timezone: "America/Belem", utc: "UTC-03:00" },
        { code: "BR", country: "Brazil", timezone: "America/Fortaleza", utc: "UTC-03:00" },
        { code: "BR", country: "Brazil", timezone: "America/Maceio", utc: "UTC-03:00" },
        { code: "BR", country: "Brazil", timezone: "America/Recife", utc: "UTC-03:00" },
        { code: "BR", country: "Brazil", timezone: "America/Santarem", utc: "UTC-03:00" },
        { code: "BR", country: "Brazil", timezone: "America/Sao_Paulo", utc: "UTC-03:00" },
        { code: "CL", country: "Chile", timezone: "America/Punta_Arenas", utc: "UTC-03:00" },
        { code: "CL", country: "Chile", timezone: "America/Santiago", utc: "UTC-03:00" },
        { code: "FK", country: "Falkland Islands", timezone: "Atlantic/Stanley", utc: "UTC-03:00" },
        { code: "GF", country: "French Guiana", timezone: "America/Cayenne", utc: "UTC-03:00" },
        { code: "PY", country: "Paraguay", timezone: "America/Asuncion", utc: "UTC-03:00" },
        { code: "PM", country: "Saint Pierre and Miquelon", timezone: "America/Miquelon", utc: "UTC-03:00" },
        { code: "SR", country: "Suriname", timezone: "America/Paramaribo", utc: "UTC-03:00" },
        { code: "UY", country: "Uruguay", timezone: "America/Montevideo", utc: "UTC-03:00" },
        { code: "CA", country: "Canada", timezone: "America/St_Johns", utc: "UTC -03:30" },
        { code: "BR", country: "Brazil", timezone: "America/Noronha", utc: "UTC-02:00" },
        { code: "GL", country: "Greenland", timezone: "America/Nuuk", utc: "UTC-02:00" },
        { code: "GL", country: "Greenland", timezone: "America/Scoresbysund", utc: "UTC-02:00" },
        { code: "GS", country: "South Georgia and the South Sandwich Islands", timezone: "Atlantic/South_Georgia", utc: "UTC-02:00" },
        { code: "CV", country: "Cape Verde", timezone: "Atlantic/Cape_Verde", utc: "UTC-01:00" },
        { code: "PT", country: "Portugal", timezone: "Atlantic/Azores", utc: "UTC-01:00" },
        { code: "AQ", country: "Antarctica", timezone: "Antarctica/Troll", utc: "UTC" },
        { code: "CI", country: "Côte d'Ivoire", timezone: "Africa/Abidjan", utc: "UTC" },
        { code: "FO", country: "Faroe Islands", timezone: "Atlantic/Faroe", utc: "UTC" },
        { code: "GM", country: "Gambia", timezone: "Africa/Banjul", utc: "UTC" },
        { code: "GH", country: "Ghana", timezone: "Africa/Accra", utc: "UTC" },
        { code: "GL", country: "Greenland", timezone: "America/Danmarkshavn", utc: "UTC" },
        { code: "GG", country: "Guernsey", timezone: "Europe/Guernsey", utc: "UTC" },
        { code: "GN", country: "Guinea", timezone: "Africa/Conakry", utc: "UTC" },
        { code: "GW", country: "Guinea-Bissau", timezone: "Africa/Bissau", utc: "UTC" },
        { code: "IS", country: "Iceland", timezone: "Atlantic/Reykjavik", utc: "UTC" },
        { code: "IE", country: "Ireland", timezone: "Europe/Dublin", utc: "UTC" },
        { code: "IM", country: "Isle of Man", timezone: "Europe/Isle_of_Man", utc: "UTC" },
        { code: "JE", country: "Jersey", timezone: "Europe/Jersey", utc: "UTC" },
        { code: "LR", country: "Liberia", timezone: "Africa/Monrovia", utc: "UTC" },
        { code: "ML", country: "Mali", timezone: "Africa/Bamako", utc: "UTC" },
        { code: "MR", country: "Mauritania", timezone: "Africa/Nouakchott", utc: "UTC" },
        { code: "PT", country: "Portugal", timezone: "Atlantic/Madeira", utc: "UTC" },
        { code: "PT", country: "Portugal", timezone: "Europe/Lisbon", utc: "UTC" },
        { code: "SH", country: "Saint Helena, Ascension, Tristan da Cunha", timezone: "Atlantic/St_Helena", utc: "UTC" },
        { code: "ST", country: "São Tomé and Príncipe", timezone: "Africa/Sao_Tome", utc: "UTC" },
        { code: "SN", country: "Senegal", timezone: "Africa/Dakar", utc: "UTC" },
        { code: "SL", country: "Sierra Leone", timezone: "Africa/Freetown", utc: "UTC" },
        { code: "ES", country: "Spain", timezone: "Atlantic/Canary", utc: "UTC" },
        { code: "TG", country: "Togo", timezone: "Africa/Lome", utc: "UTC" },
        { code: "GB", country: "United Kingdom", timezone: "Europe/London", utc: "UTC" },
        { code: "AL", country: "Albania", timezone: "Europe/Tirane", utc: "UTC+01:00" },
        { code: "DZ", country: "Algeria", timezone: "Africa/Algiers", utc: "UTC+01:00" },
        { code: "AD", country: "Andorra", timezone: "Europe/Andorra", utc: "UTC+01:00" },
        { code: "AO", country: "Angola", timezone: "Africa/Luanda", utc: "UTC+01:00" },
        { code: "AT", country: "Austria", timezone: "Europe/Vienna", utc: "UTC+01:00" },
        { code: "BE", country: "Belgium", timezone: "Europe/Brussels", utc: "UTC+01:00" },
        { code: "BJ", country: "Benin", timezone: "Africa/Porto-Novo", utc: "UTC+01:00" },
        { code: "BA", country: "Bosnia and Herzegovina", timezone: "Europe/Sarajevo", utc: "UTC+01:00" },
        { code: "CM", country: "Cameroon", timezone: "Africa/Douala", utc: "UTC+01:00" },
        { code: "CF", country: "Central African Republic", timezone: "Africa/Bangui", utc: "UTC+01:00" },
        { code: "TD", country: "Chad", timezone: "Africa/Ndjamena", utc: "UTC+01:00" },
        { code: "CG", country: "Congo", timezone: "Africa/Brazzaville", utc: "UTC+01:00" },
        { code: "CD", country: "Congo, the Democratic Republic of the", timezone: "Africa/Kinshasa", utc: "UTC+01:00" },
        { code: "HR", country: "Croatia", timezone: "Europe/Zagreb", utc: "UTC+01:00" },
        { code: "CZ", country: "Czech Republic", timezone: "Europe/Prague", utc: "UTC+01:00" },
        { code: "DK", country: "Denmark", timezone: "Europe/Copenhagen", utc: "UTC+01:00" },
        { code: "GQ", country: "Equatorial Guinea", timezone: "Africa/Malabo", utc: "UTC+01:00" },
        { code: "FR", country: "France", timezone: "Europe/Paris", utc: "UTC+01:00" },
        { code: "GA", country: "Gabon", timezone: "Africa/Libreville", utc: "UTC+01:00" },
        { code: "DE", country: "Germany", timezone: "Europe/Berlin", utc: "UTC+01:00" },
        { code: "DE", country: "Germany", timezone: "Europe/Busingen", utc: "UTC+01:00" },
        { code: "GI", country: "Gibraltar", timezone: "Europe/Gibraltar", utc: "UTC+01:00" },
        { code: "VA", country: "Holy See (Vatican City State)", timezone: "Europe/Vatican", utc: "UTC+01:00" },
        { code: "HU", country: "Hungary", timezone: "Europe/Budapest", utc: "UTC+01:00" },
        { code: "IT", country: "Italy", timezone: "Europe/Rome", utc: "UTC+01:00" },
        { code: "LI", country: "Liechtenstein", timezone: "Europe/Vaduz", utc: "UTC+01:00" },
        { code: "LU", country: "Luxembourg", timezone: "Europe/Luxembourg", utc: "UTC+01:00" },
        { code: "MK", country: "Macedonia, the Former Yugoslav Republic of", timezone: "Europe/Skopje", utc: "UTC+01:00" },
        { code: "MT", country: "Malta", timezone: "Europe/Malta", utc: "UTC+01:00" },
        { code: "MC", country: "Monaco", timezone: "Europe/Monaco", utc: "UTC+01:00" },
        { code: "ME", country: "Montenegro", timezone: "Europe/Podgorica", utc: "UTC+01:00" },
        { code: "MA", country: "Morocco", timezone: "Africa/Casablanca", utc: "UTC+01:00" },
        { code: "MA", country: "Morocco", timezone: "Africa/El_Aaiun", utc: "UTC+01:00" },
        { code: "NL", country: "Netherlands", timezone: "Europe/Amsterdam", utc: "UTC+01:00" },
        { code: "NE", country: "Niger", timezone: "Africa/Niamey", utc: "UTC+01:00" },
        { code: "NG", country: "Nigeria", timezone: "Africa/Lagos", utc: "UTC+01:00" },
        { code: "NO", country: "Norway", timezone: "Europe/Oslo", utc: "UTC+01:00" },
        { code: "PL", country: "Poland", timezone: "Europe/Warsaw", utc: "UTC+01:00" },
        { code: "SM", country: "San Marino", timezone: "Europe/San_Marino", utc: "UTC+01:00" },
        { code: "RS", country: "Serbia", timezone: "Europe/Belgrade", utc: "UTC+01:00" },
        { code: "SK", country: "Slovakia", timezone: "Europe/Bratislava", utc: "UTC+01:00" },
        { code: "SI", country: "Slovenia", timezone: "Europe/Ljubljana", utc: "UTC+01:00" },
        { code: "ES", country: "Spain", timezone: "Africa/Ceuta", utc: "UTC+01:00" },
        { code: "ES", country: "Spain", timezone: "Europe/Madrid", utc: "UTC+01:00" },
        { code: "SJ", country: "Svalbard and Jan Mayen", timezone: "Arctic/Longyearbyen", utc: "UTC+01:00" },
        { code: "SE", country: "Sweden", timezone: "Europe/Stockholm", utc: "UTC+01:00" },
        { code: "CH", country: "Switzerland", timezone: "Europe/Zurich", utc: "UTC+01:00" },
        { code: "TN", country: "Tunisia", timezone: "Africa/Tunis", utc: "UTC+01:00" },
        { code: "BW", country: "Botswana", timezone: "Africa/Gaborone", utc: "UTC+02:00" },
        { code: "BG", country: "Bulgaria", timezone: "Europe/Sofia", utc: "UTC+02:00" },
        { code: "BI", country: "Burundi", timezone: "Africa/Bujumbura", utc: "UTC+02:00" },
        { code: "CD", country: "Congo, the Democratic Republic of the", timezone: "Africa/Lubumbashi", utc: "UTC+02:00" },
        { code: "CY", country: "Cyprus", timezone: "Asia/Famagusta", utc: "UTC+02:00" },
        { code: "CY", country: "Cyprus", timezone: "Asia/Nicosia", utc: "UTC+02:00" },
        { code: "EG", country: "Egypt", timezone: "Africa/Cairo", utc: "UTC+02:00" },
        { code: "EE", country: "Estonia", timezone: "Europe/Tallinn", utc: "UTC+02:00" },
        { code: "FI", country: "Finland", timezone: "Europe/Helsinki", utc: "UTC+02:00" },
        { code: "GR", country: "Greece", timezone: "Europe/Athens", utc: "UTC+02:00" },
        { code: "IL", country: "Israel", timezone: "Asia/Jerusalem", utc: "UTC+02:00" },
        { code: "LV", country: "Latvia", timezone: "Europe/Riga", utc: "UTC+02:00" },
        { code: "LB", country: "Lebanon", timezone: "Asia/Beirut", utc: "UTC+02:00" },
        { code: "LS", country: "Lesotho", timezone: "Africa/Maseru", utc: "UTC+02:00" },
        { code: "LY", country: "Libya", timezone: "Africa/Tripoli", utc: "UTC+02:00" },
        { code: "LT", country: "Lithuania", timezone: "Europe/Vilnius", utc: "UTC+02:00" },
        { code: "MW", country: "Malawi", timezone: "Africa/Blantyre", utc: "UTC+02:00" },
        { code: "MD", country: "Moldova, Republic of", timezone: "Europe/Chisinau", utc: "UTC+02:00" },
        { code: "MZ", country: "Mozambique", timezone: "Africa/Maputo", utc: "UTC+02:00" },
        { code: "NA", country: "Namibia", timezone: "Africa/Windhoek", utc: "UTC+02:00" },
        { code: "PS", country: "Palestine, State of", timezone: "Asia/Gaza", utc: "UTC+02:00" },
        { code: "PS", country: "Palestine, State of", timezone: "Asia/Hebron", utc: "UTC+02:00" },
        { code: "RO", country: "Romania", timezone: "Europe/Bucharest", utc: "UTC+02:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Kaliningrad", utc: "UTC+02:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Moscow", utc: "UTC+02:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Volgograd", utc: "UTC+02:00" },
        { code: "RW", country: "Rwanda", timezone: "Africa/Kigali", utc: "UTC+02:00" },
        { code: "ZA", country: "South Africa", timezone: "Africa/Johannesburg", utc: "UTC+02:00" },
        { code: "SS", country: "South Sudan", timezone: "Africa/Juba", utc: "UTC+02:00" },
        { code: "SD", country: "Sudan", timezone: "Africa/Khartoum", utc: "UTC+02:00" },
        { code: "SZ", country: "Swaziland", timezone: "Africa/Mbabane", utc: "UTC+02:00" },
        { code: "UA", country: "Ukraine", timezone: "Europe/Kyiv", utc: "UTC+02:00" },
        { code: "ZM", country: "Zambia", timezone: "Africa/Lusaka", utc: "UTC+02:00" },
        { code: "ZW", country: "Zimbabwe", timezone: "Africa/Harare", utc: "UTC+02:00" },
        { code: "AX", country: "Åland Islands", timezone: "Europe/Mariehamn", utc: "UTC+02:00" },
        { code: "AQ", country: "Antarctica", timezone: "Antarctica/Syowa", utc: "UTC+03:00" },
        { code: "BH", country: "Bahrain", timezone: "Asia/Bahrain", utc: "UTC+03:00" },
        { code: "BY", country: "Belarus", timezone: "Europe/Minsk", utc: "UTC+03:00" },
        { code: "KM", country: "Comoros", timezone: "Indian/Comoro", utc: "UTC+03:00" },
        { code: "DJ", country: "Djibouti", timezone: "Africa/Djibouti", utc: "UTC+03:00" },
        { code: "ER", country: "Eritrea", timezone: "Africa/Asmara", utc: "UTC+03:00" },
        { code: "ET", country: "Ethiopia", timezone: "Africa/Addis_Ababa", utc: "UTC+03:00" },
        { code: "IQ", country: "Iraq", timezone: "Asia/Baghdad", utc: "UTC+03:00" },
        { code: "JO", country: "Jordan", timezone: "Asia/Amman", utc: "UTC+03:00" },
        { code: "KE", country: "Kenya", timezone: "Africa/Nairobi", utc: "UTC+03:00" },
        { code: "KW", country: "Kuwait", timezone: "Asia/Kuwait", utc: "UTC+03:00" },
        { code: "MG", country: "Madagascar", timezone: "Indian/Antananarivo", utc: "UTC+03:00" },
        { code: "YT", country: "Mayotte", timezone: "Indian/Mayotte", utc: "UTC+03:00" },
        { code: "QA", country: "Qatar", timezone: "Asia/Qatar", utc: "UTC+03:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Kirov", utc: "UTC+03:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Moscow", utc: "UTC+03:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Volgograd", utc: "UTC+03:00" },
        { code: "SA", country: "Saudi Arabia", timezone: "Asia/Riyadh", utc: "UTC+03:00" },
        { code: "SO", country: "Somalia", timezone: "Africa/Mogadishu", utc: "UTC+03:00" },
        { code: "SY", country: "Syrian Arab Republic", timezone: "Asia/Damascus", utc: "UTC+03:00" },
        { code: "TZ", country: "Tanzania, United Republic of", timezone: "Africa/Dar_es_Salaam", utc: "UTC+03:00" },
        { code: "TR", country: "Turkey", timezone: "Europe/Istanbul", utc: "UTC+03:00" },
        { code: "UG", country: "Uganda", timezone: "Africa/Kampala", utc: "UTC+03:00" },
        { code: "UA", country: "Ukraine", timezone: "Europe/Simferopol", utc: "UTC+03:00" },
        { code: "YE", country: "Yemen", timezone: "Asia/Aden", utc: "UTC+03:00" },
        { code: "IR", country: "Iran, Islamic Republic of", timezone: "Asia/Tehran", utc: "UTC +03:30" },
        { code: "AM", country: "Armenia", timezone: "Asia/Yerevan", utc: "UTC+04:00" },
        { code: "AZ", country: "Azerbaijan", timezone: "Asia/Baku", utc: "UTC+04:00" },
        { code: "GE", country: "Georgia", timezone: "Asia/Tbilisi", utc: "UTC+04:00" },
        { code: "MU", country: "Mauritius", timezone: "Indian/Mauritius", utc: "UTC+04:00" },
        { code: "OM", country: "Oman", timezone: "Asia/Muscat", utc: "UTC+04:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Astrakhan", utc: "UTC+04:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Samara", utc: "UTC+04:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Saratov", utc: "UTC+04:00" },
        { code: "RU", country: "Russian Federation", timezone: "Europe/Ulyanovsk", utc: "UTC+04:00" },
        { code: "RE", country: "Réunion", timezone: "Indian/Reunion", utc: "UTC+04:00" },
        { code: "SC", country: "Seychelles", timezone: "Indian/Mahe", utc: "UTC+04:00" },
        { code: "AE", country: "United Arab Emirates", timezone: "Asia/Dubai", utc: "UTC+04:00" },
        { code: "AF", country: "Afghanistan", timezone: "Asia/Kabul", utc: "UTC +04:30" },
        { code: "AQ", country: "Antarctica", timezone: "Antarctica/Vostok", utc: "UTC+05:00" },
        { code: "TF", country: "French Southern Territories", timezone: "Indian/Kerguelen", utc: "UTC+05:00" },
        { code: "KZ", country: "Kazakhstan", timezone: "Asia/Almaty", utc: "UTC+05:00" },
        { code: "KZ", country: "Kazakhstan", timezone: "Asia/Aqtau", utc: "UTC+05:00" },
        { code: "KZ", country: "Kazakhstan", timezone: "Asia/Aqtobe", utc: "UTC+05:00" },
        { code: "KZ", country: "Kazakhstan", timezone: "Asia/Atyrau", utc: "UTC+05:00" },
        { code: "KZ", country: "Kazakhstan", timezone: "Asia/Oral", utc: "UTC+05:00" },
        { code: "KZ", country: "Kazakhstan", timezone: "Asia/Qostanay", utc: "UTC+05:00" },
        { code: "KZ", country: "Kazakhstan", timezone: "Asia/Qyzylorda", utc: "UTC+05:00" },
        { code: "MV", country: "Maldives", timezone: "Indian/Maldives", utc: "UTC+05:00" },
        { code: "PK", country: "Pakistan", timezone: "Asia/Karachi", utc: "UTC+05:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Yekaterinburg", utc: "UTC+05:00" },
        { code: "TJ", country: "Tajikistan", timezone: "Asia/Dushanbe", utc: "UTC+05:00" },
        { code: "TM", country: "Turkmenistan", timezone: "Asia/Ashgabat", utc: "UTC+05:00" },
        { code: "UZ", country: "Uzbekistan", timezone: "Asia/Samarkand", utc: "UTC+05:00" },
        { code: "UZ", country: "Uzbekistan", timezone: "Asia/Tashkent", utc: "UTC+05:00" },
        { code: "IN", country: "India", timezone: "Asia/Kolkata", utc: "UTC+05:30" },
        { code: "LK", country: "Sri Lanka", timezone: "Asia/Colombo", utc: "UTC+05:30" },
        { code: "NP", country: "Nepal", timezone: "Asia/Kathmandu", utc: "UTC+05:45" },
        { code: "BD", country: "Bangladesh", timezone: "Asia/Dhaka", utc: "UTC+06:00" },
        { code: "BT", country: "Bhutan", timezone: "Asia/Thimphu", utc: "UTC+06:00" },
        { code: "IO", country: "British Indian Ocean Territory", timezone: "Indian/Chagos", utc: "UTC+06:00" },
        { code: "CN", country: "China", timezone: "Asia/Urumqi", utc: "UTC+06:00" },
        { code: "KG", country: "Kyrgyzstan", timezone: "Asia/Bishkek", utc: "UTC+06:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Omsk", utc: "UTC+06:00" },
        { code: "CC", country: "Cocos (Keeling) Islands", timezone: "Indian/Cocos", utc: "UTC+06:30" },
        { code: "MM", country: "Myanmar", timezone: "Asia/Yangon", utc: "UTC+06:30" },
        { code: "AQ", country: "Antarctica", timezone: "Antarctica/Davis", utc: "UTC+07:00" },
        { code: "KH", country: "Cambodia", timezone: "Asia/Phnom_Penh", utc: "UTC+07:00" },
        { code: "CX", country: "Christmas Island", timezone: "Indian/Christmas", utc: "UTC+07:00" },
        { code: "ID", country: "Indonesia", timezone: "Asia/Jakarta", utc: "UTC+07:00" },
        { code: "ID", country: "Indonesia", timezone: "Asia/Pontianak", utc: "UTC+07:00" },
        { code: "LA", country: "Lao People's Democratic Republic", timezone: "Asia/Vientiane", utc: "UTC+07:00" },
        { code: "MN", country: "Mongolia", timezone: "Asia/Hovd", utc: "UTC+07:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Barnaul", utc: "UTC+07:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Krasnoyarsk", utc: "UTC+07:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Novokuznetsk", utc: "UTC+07:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Novosibirsk", utc: "UTC+07:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Tomsk", utc: "UTC+07:00" },
        { code: "TH", country: "Thailand", timezone: "Asia/Bangkok", utc: "UTC+07:00" },
        { code: "VN", country: "Viet Nam", timezone: "Asia/Ho_Chi_Minh", utc: "UTC+07:00" },
        { code: "AQ", country: "Antarctica", timezone: "Antarctica/Casey", utc: "UTC+08:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Perth", utc: "UTC+08:00" },
        { code: "BN", country: "Brunei Darussalam", timezone: "Asia/Brunei", utc: "UTC+08:00" },
        { code: "CN", country: "China", timezone: "Asia/Shanghai", utc: "UTC+08:00" },
        { code: "HK", country: "Hong Kong", timezone: "Asia/Hong_Kong", utc: "UTC+08:00" },
        { code: "ID", country: "Indonesia", timezone: "Asia/Makassar", utc: "UTC+08:00" },
        { code: "MO", country: "Macao", timezone: "Asia/Macau", utc: "UTC+08:00" },
        { code: "MY", country: "Malaysia", timezone: "Asia/Kuala_Lumpur", utc: "UTC+08:00" },
        { code: "MY", country: "Malaysia", timezone: "Asia/Kuching", utc: "UTC+08:00" },
        { code: "MN", country: "Mongolia", timezone: "Asia/Ulaanbaatar", utc: "UTC+08:00" },
        { code: "PH", country: "Philippines", timezone: "Asia/Manila", utc: "UTC+08:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Irkutsk", utc: "UTC+08:00" },
        { code: "SG", country: "Singapore", timezone: "Asia/Singapore", utc: "UTC+08:00" },
        { code: "TW", country: "Taiwan, Province of China", timezone: "Asia/Taipei", utc: "UTC+08:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Eucla", utc: "UTC+08:45" },
        { code: "ID", country: "Indonesia", timezone: "Asia/Jayapura", utc: "UTC+09:00" },
        { code: "JP", country: "Japan", timezone: "Asia/Tokyo", utc: "UTC+09:00" },
        { code: "KP", country: "Korea, Democratic People's Republic of", timezone: "Asia/Pyongyang", utc: "UTC+09:00" },
        { code: "KR", country: "Korea, Republic of", timezone: "Asia/Seoul", utc: "UTC+09:00" },
        { code: "PW", country: "Palau", timezone: "Pacific/Palau", utc: "UTC+09:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Chita", utc: "UTC+09:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Khandyga", utc: "UTC+09:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Yakutsk", utc: "UTC+09:00" },
        { code: "TL", country: "Timor-Leste", timezone: "Asia/Dili", utc: "UTC+09:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Darwin", utc: "UTC+09:30" },
        { code: "AQ", country: "Antarctica", timezone: "Antarctica/DumontDUrville", utc: "UTC+10:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Brisbane", utc: "UTC+10:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Lindeman", utc: "UTC+10:00" },
        { code: "GU", country: "Guam", timezone: "Pacific/Guam", utc: "UTC+10:00" },
        { code: "FM", country: "Micronesia, Federated States of", timezone: "Pacific/Chuuk", utc: "UTC+10:00" },
        { code: "MP", country: "Northern Mariana Islands", timezone: "Pacific/Saipan", utc: "UTC+10:00" },
        { code: "PG", country: "Papua New Guinea", timezone: "Pacific/Port_Moresby", utc: "UTC+10:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Ust-Nera", utc: "UTC+10:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Vladivostok", utc: "UTC+10:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Adelaide", utc: "UTC+10:30" },
        { code: "AU", country: "Australia", timezone: "Australia/Broken_Hill", utc: "UTC+10:30" },
        { code: "AU", country: "Australia", timezone: "Antarctica/Macquarie", utc: "UTC+11:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Hobart", utc: "UTC+11:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Lord_Howe", utc: "UTC+11:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Melbourne", utc: "UTC+11:00" },
        { code: "AU", country: "Australia", timezone: "Australia/Sydney", utc: "UTC+11:00" },
        { code: "FM", country: "Micronesia, Federated States of", timezone: "Pacific/Kosrae", utc: "UTC+11:00" },
        { code: "FM", country: "Micronesia, Federated States of", timezone: "Pacific/Pohnpei", utc: "UTC+11:00" },
        { code: "NC", country: "New Caledonia", timezone: "Pacific/Noumea", utc: "UTC+11:00" },
        { code: "PG", country: "Papua New Guinea", timezone: "Pacific/Bougainville", utc: "UTC+11:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Magadan", utc: "UTC+11:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Sakhalin", utc: "UTC+11:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Srednekolymsk", utc: "UTC+11:00" },
        { code: "SB", country: "Solomon Islands", timezone: "Pacific/Guadalcanal", utc: "UTC+11:00" },
        { code: "VU", country: "Vanuatu", timezone: "Pacific/Efate", utc: "UTC+11:00" },
        { code: "FJ", country: "Fiji", timezone: "Pacific/Fiji", utc: "UTC+12:00" },
        { code: "KI", country: "Kiribati", timezone: "Pacific/Tarawa", utc: "UTC+12:00" },
        { code: "MH", country: "Marshall Islands", timezone: "Pacific/Kwajalein", utc: "UTC+12:00" },
        { code: "MH", country: "Marshall Islands", timezone: "Pacific/Majuro", utc: "UTC+12:00" },
        { code: "NR", country: "Nauru", timezone: "Pacific/Nauru", utc: "UTC+12:00" },
        { code: "NF", country: "Norfolk Island", timezone: "Pacific/Norfolk", utc: "UTC+12:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Anadyr", utc: "UTC+12:00" },
        { code: "RU", country: "Russian Federation", timezone: "Asia/Kamchatka", utc: "UTC+12:00" },
        { code: "TV", country: "Tuvalu", timezone: "Pacific/Funafuti", utc: "UTC+12:00" },
        { code: "UM", country: "United States Minor Outlying Islands", timezone: "Pacific/Wake", utc: "UTC+12:00" },
        { code: "WF", country: "Wallis and Futuna", timezone: "Pacific/Wallis", utc: "UTC+12:00" },
        { code: "AQ", country: "Antarctica", timezone: "Antarctica/McMurdo", utc: "UTC+12:00" },
        { code: "KI", country: "Kiribati", timezone: "Pacific/Kanton", utc: "UTC+12:00" },
        { code: "NZ", country: "New Zealand", timezone: "Pacific/Auckland", utc: "UTC+12:00" },
        { code: "WS", country: "Samoa", timezone: "Pacific/Apia", utc: "UTC+12:00" },
        { code: "TK", country: "Tokelau", timezone: "Pacific/Fakaofo", utc: "UTC+12:00" },
        { code: "TO", country: "Tonga", timezone: "Pacific/Tongatapu", utc: "UTC+12:00" },
        { code: "NZ", country: "New Zealand", timezone: "Pacific/Chatham", utc: "UTC+13:45" },
        { code: "KI", country: "Kiribati", timezone: "Pacific/Kiritimati", utc: "UTC+14:00" }
    ];




    useEffect(() => {
        const storedTimeZone = sessionStorage.getItem("SystemTimeZone");
        setSystemTimeZone(storedTimeZone);
        const records = JSON.parse(sessionStorage.getItem('Records'));
        setcurrentUser(records[0]);
        const profilePicture = sessionStorage.getItem('CustomerImage'); // Assuming you store the profile picture URL in sessionStorage
        //const profilePicture = records[0].CustomerImage; // Assuming you store the profile picture URL in sessionStorage
        if (!profilePicture) {
            setProfileImage(defaultuserImage); // Set the default image if profilePicture is null or empty
            //console.log("Defaultuser Image:")
        } else {
            const base64Image = profilePicture.startsWith("data:image/") ? profilePicture : `data:image/png;base64,${profilePicture}`;
            //console.log("Base64 Image:", base64Image)
            setProfileImage(base64Image); // Set the base64 image string
        }
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // Validate file format
            const validFormats = ["image/jpeg", "image/jpg", "image/png"];
            if (!validFormats.includes(file.type)) {
                setTimeout(() => showAlert("Invalid file format. Please upload a JPG, JPEG, or PNG image.", 'warning'), 0);
                return;
            }

            // Validate file size (max 200KB)
            const maxSizeInKB = 200;
            if (file.size > maxSizeInKB * 1024) {
                setTimeout(() => showAlert("File size exceeds 200KB. Please upload a smaller image.", 'warning'), 0);
                return;
            }

            // File is valid
            // Convert the image file to base64 and update the state
            const reader = new FileReader();

            reader.onloadend = () => {
                // reader.result is the base64-encoded string
                const base64Image = reader.result.split(',')[1]; // Remove the prefix "data:image/jpeg;base64,"

                // Update the profile image preview
                setProfileImage(reader.result);

                // Enable edit mode
                setIsEditable(true);

                // Store the base64-encoded image in the state
                setCustomerImage(base64Image);
            };

            // Read the file as a data URL (base64 encoded string)
            reader.readAsDataURL(file);
        }
    };




    const handleSave = () => {

        setIsLoading(true);
        const updatedData = {
            ActionType: "update_user_profile",
            CustomerID: sessionStorage.getItem('CustomerID'),
        };
        // Dynamically add SystemTimeZone if available
        if (systemTimeZone) {
            updatedData.TimeZone = systemTimeZone;
        }
        if (customerImage) {
            updatedData.CustomerImage = customerImage;
        }

        updateUserProfileData(updatedData);

    };

    const handleChange = (event) => {
        setSystemTimeZone(event.target.value);
        console.log("Selected TimeZone:", event.target.value);
    };
    const handleEdit = () => {
        setIsEditable(true); // Enable the dropdown for editing
    };


    const handleCancelEdit = () => {
        setSystemTimeZone(systemTimeZone); // Revert to original time zone
        setIsEditable(false);
    };

    const updateUserProfileData = async (data) => {
        //alert(`Data saved successfully! Here is the form data:\n${JSON.stringify(data, null, 2)}`);
        try {
            const requestBody = data;
            console.log('Requesting Body:', requestBody);
            const response = await AxiosInstance.post('/manage-resource', requestBody);
            setLoading(false);
            setIsLoading(false);
            setIsEditable(false);
            console.log('Response Body:', response);
            if (response.data.status == 'success') {
                setTimeout(() => showAlert("Successgully Updated", 'success'), 0);
                setIsEditable(false);
                sessionStorage.setItem('SystemTimeZone', systemTimeZone);

                sessionStorage.setItem('CustomerImage', customerImage);

            } else {
                setTimeout(() => showAlert("Failed to Update", 'failure'), 0);
            }
        } catch (err) {
            //setLoading(false);
            //console.log('Error fetching server zones:', err); // Log the full error for more details
            //setMessage("Error loading data. Please try again.");
            //setTimeout(() => showAlert("Error loading data. Please try again.", 'danger'), 0);
            //setTimeout(() => showAlert("Error loading data. Please try again.", 'danger'), 0);
            setLoading(false);
            setIsLoading(false);
            console.error("Error:", err); // This will log the full error object
            if (err.response) {
                // If the error has a response, it means the server responded with an error
                console.error("Response Error:", err.response.data);
            } else if (err.request) {
                // If there was no response from the server
                console.error("Request Error:", err.request);
            } else {
                // General error
                console.error("General Error:", err.message);
            }
        } finally {
            setLoading(false);
            setIsLoading(false);
        }
    };
    return (

        <>
            <Header setModalType={setModalType} />
            <SideBar />
            <div className="app-container">
                <main id="main" className="main">
                    <PageTitle title={"Profile"} subtitle={"User Profile"} />
                    <AllModals modalType={modalType} handleClose={handleClose} setModalType={setModalType} />
                    <div className="col-md-8 p-4 rounded profile-custom-container">
                        {/* Profile Image and Full Name */}
                        <div className="d-flex align-items-center mb-2">
                            <div className="profile-container position-relative" style={{ width: "80px", height: "80px", cursor: "pointer" }}>
                                {/* Profile Image */}
                                <label htmlFor="profileImageInput" style={{ cursor: "pointer" }}>
                                    <img
                                        src={profileImage}
                                        alt="Profile"
                                        className="rounded-circle"
                                        width="80"
                                        height="80"
                                        style={{
                                            transition: "transform 0.3s",
                                            objectFit: 'scale-down', // Ensures the image fits inside the circle
                                            borderRadius: '50%', // Ensures the container is circular
                                            display: 'block', // Prevents unwanted spacing below the image
                                        }}
                                    />
                                </label>
                                {/* Hidden File Input */}
                                <input
                                    type="file"
                                    id="profileImageInput"
                                    className="d-none"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div className="text-start ms-3">
                                <h4 style={{ marginBottom: "0.2rem", lineHeight: "0.5" }}>{UserName}</h4>
                                <small className="text-muted">Click to update your profile picture</small>
                            </div>

                        </div>

                        {/* Profile Details */}
                        <div className="row text-align-start custom-spacing">
                            <div className="row mt-3 custom-alignment">
                                <div className="col-md-3 text-end">Email</div>
                                <div className="col-md-9 text-start">{EmailID}</div>
                            </div>

                            <div className="row mt-1 custom-alignment">
                                <div className="col-md-3 text-end">Account Type</div>
                                <div className="col-md-9 text-start">{AccountType}</div>
                            </div>

                            <div className="row mt-1 align-items-center custom-alignment">
                                <div className="col-md-3 text-end">System TimeZone</div>
                                <div className="col-md-9 text-start position-relative">
                                    <select
                                        className="form-select"
                                        aria-label="Select System TimeZone"
                                        value={systemTimeZone || ""}
                                        onChange={handleChange}
                                        disabled={!isEditable} // Disable if not in edit mode
                                    >
                                        <option value="" disabled>
                                            Select timezone
                                        </option>
                                        {selectTimeZones.map((timezone) => (
                                            <option key={`${timezone.utc}-${timezone.timezone}`} value={`${timezone.timezone}`}>
                                                ({timezone.utc}): {timezone.country} ({timezone.timezone})
                                            </option>
                                        ))}
                                    </select>
                                    {/* Close icon appears only in edit mode */}
                                    {isEditable && (
                                        <button
                                            className="close-icon"
                                            onClick={handleCancelEdit}
                                            aria-label="Cancel Edit"
                                            style={{
                                                fontSize: "14px", // Optional: Adjust font size for better alignment
                                                padding: "4px",   // Optional: Adjust padding for a smaller look
                                                width: "25px",
                                                height: "25px",
                                                right: "-1px"
                                            }}
                                        >
                                            &times;
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="d-flex justify-content-end mt-4">
                                    {!isEditable ? (
                                        <button className="btn btn-primary save-edit-fixed-width" onClick={handleEdit}>
                                            Edit
                                        </button>
                                    ) : (
                                        <button className="btn btn-primary save-edit-fixed-width" onClick={handleSave}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm me-2"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    wait...
                                                </>
                                            ) : (
                                                "Save Changes"
                                            )}

                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>


                    </div>


                </main>
            </div>
            <Footer />
            <BackToTop />
        </>

    )
}

export default UserProfile