import AxiosInstance from '../Components/RestApi/AxiosInstance';
import axios from "axios";

export const getUTCDateTime = () => {
    const now = new Date();

    // Get the various components of the date
    const year = now.getUTCFullYear();
    const month = ('0' + (now.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + now.getUTCDate()).slice(-2);
    const hours = ('0' + now.getUTCHours()).slice(-2);
    const minutes = ('0' + now.getUTCMinutes()).slice(-2);
    const seconds = ('0' + now.getUTCSeconds()).slice(-2);

    // Format the date and time
    const utcDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return utcDateTime;
};

export const getUTCDateTimeWithOffset = (utcOffsetString) => {
    const now = new Date(); // Current local time

    // Convert the local time to UTC
    const currentUtcTime = new Date(
        now.getTime() + now.getTimezoneOffset() * 60 * 1000
    ); // Adjust local time to UTC

    // Extract the offset part (e.g., "+5:00" or "-4:00") using regex
    const match = utcOffsetString.match(/UTC([+-])(\d{1,2}):(\d{2})/);
    if (!match) {
        throw new Error("Invalid UTC offset format. Use 'UTC+HH:MM' or 'UTC-HH:MM'.");
    }

    const sign = match[1]; // "+" or "-"
    const hours = parseInt(match[2], 10); // Extract hours
    const minutes = parseInt(match[3], 10); // Extract minutes

    // Convert offset to total milliseconds
    const offsetInMilliseconds = (hours * 60 + minutes) * 60 * 1000;
    const offsetMultiplier = sign === "+" ? 1 : -1;

    // Apply the offset to the UTC time
    const targetTime = new Date(
        currentUtcTime.getTime() + offsetMultiplier * offsetInMilliseconds
    );

    // Format the date and time
    const year = targetTime.getFullYear();
    const month = ('0' + (targetTime.getMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + targetTime.getDate()).slice(-2);
    const hoursFormatted = ('0' + targetTime.getHours()).slice(-2);
    const minutesFormatted = ('0' + targetTime.getMinutes()).slice(-2);
    const secondsFormatted = ('0' + targetTime.getSeconds()).slice(-2);

    // Return formatted date and time
    return `${year}-${month}-${day} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
};

export const formatISOToCustom = (isoDateString) => {
    // Parse the input ISO date string to a JavaScript Date object
    const date = new Date(isoDateString);

    if (isNaN(date)) {
        throw new Error("Invalid ISO date string.");
    }

    // Extract the date and time components
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // Format and return the result
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const adjustTimeWithUTCOffset = (isoDateString, utcOffsetString) => {
    // Parse the input ISO date string to a JavaScript Date object
    const inputDate = new Date(isoDateString);

    if (isNaN(inputDate)) {
        throw new Error("Invalid date format. Please use a valid ISO 8601 date string.");
    }

    // Extract the offset part (e.g., "+5:30" or "-4:00") using regex
    const match = utcOffsetString.match(/UTC([+-])(\d{1,2}):(\d{2})/);
    if (!match) {
        throw new Error("Invalid UTC offset format. Use 'UTC+HH:MM' or 'UTC-HH:MM'.");
    }

    const sign = match[1]; // "+" or "-"
    const hours = parseInt(match[2], 10); // Extract hours
    const minutes = parseInt(match[3], 10); // Extract minutes

    // Convert offset to total milliseconds
    const offsetInMilliseconds = (hours * 60 + minutes) * 60 * 1000;
    const offsetMultiplier = sign === "+" ? 1 : -1;

    // Apply the offset to the input date
    const adjustedTime = new Date(
        inputDate.getTime() + offsetMultiplier * offsetInMilliseconds
    );

    // Format the adjusted date and time
    const year = adjustedTime.getFullYear();
    const month = ('0' + (adjustedTime.getMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + adjustedTime.getDate()).slice(-2);
    const hoursFormatted = ('0' + adjustedTime.getHours()).slice(-2);
    const minutesFormatted = ('0' + adjustedTime.getMinutes()).slice(-2);
    const secondsFormatted = ('0' + adjustedTime.getSeconds()).slice(-2);

    // Return the formatted adjusted time
    return `${year}-${month}-${day} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
};



// export const convertToCustomFormat = (dateString) => {
//     if (!dateString) return "";

//     const date = new Date(dateString); // Parse the input date string

//     // Extract the components of the date
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
//     const day = String(date.getDate()).padStart(2, "0");
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");

//     // Combine components in the desired format
//     return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// };


// export const adjustWithUTCOffset = (isoDateString, utcOffsetString) => {
//     // Parse the input ISO date string to a JavaScript Date object
//     const inputDate = new Date(isoDateString);

//     if (isNaN(inputDate)) {
//         throw new Error("Invalid date format. Please use a valid ISO 8601 date string.");
//     }

//     // Extract the offset part (e.g., "+5:30" or "-4:00") using regex
//     const match = utcOffsetString.match(/UTC([+-])(\d{1,2}):(\d{2})/);
//     if (!match) {
//         throw new Error("Invalid UTC offset format. Use 'UTC+HH:MM' or 'UTC-HH:MM'.");
//     }

//     const sign = match[1]; // "+" or "-"
//     const hours = parseInt(match[2], 10); // Extract hours
//     const minutes = parseInt(match[3], 10); // Extract minutes

//     // Convert offset to total milliseconds
//     const offsetInMilliseconds = (hours * 60 + minutes) * 60 * 1000;
//     const offsetMultiplier = sign === "+" ? 1 : -1;

//     // Apply the offset to the input date
//     const adjustedTime = new Date(
//         inputDate.getTime() + offsetMultiplier * offsetInMilliseconds
//     );

//     // Format the adjusted date and time
//     const year = adjustedTime.getFullYear();
//     const month = ('0' + (adjustedTime.getMonth() + 1)).slice(-2); // Months are zero-indexed
//     const day = ('0' + adjustedTime.getDate()).slice(-2);
//     const hoursFormatted = ('0' + adjustedTime.getHours()).slice(-2);
//     const minutesFormatted = ('0' + adjustedTime.getMinutes()).slice(-2);
//     const secondsFormatted = ('0' + adjustedTime.getSeconds()).slice(-2);
//     const millisecondsFormatted = ('00' + adjustedTime.getMilliseconds()).slice(-3);

//     // Return the formatted adjusted time
//     return `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}:${secondsFormatted}.${millisecondsFormatted}`;
// };



// export const applyUTCOffset = (dateString, utcOffset) => {
//     // Parse the date string into a JavaScript Date object
//     const date = new Date(dateString.replace(" ", "T")); // Replace space with 'T' to ensure valid ISO format

//     // Extract the sign and hours/minutes from the UTC offset
//     const match = utcOffset.match(/UTC([+-])(\d{1,2}):(\d{2})/);
//     if (!match) {
//         throw new Error("Invalid UTC offset format. Expected format: 'UTC+hh:mm' or 'UTC-hh:mm'");
//     }

//     const sign = match[1] === "+" ? 1 : -1;
//     const hours = parseInt(match[2], 10);
//     const minutes = parseInt(match[3], 10);

//     // Calculate the total offset in milliseconds
//     const totalOffsetMs = sign * ((hours * 60 + minutes) * 60 * 1000);

//     // Apply the offset to the original date
//     const adjustedDate = new Date(date.getTime() + totalOffsetMs);

//     // Format the adjusted date back into the desired format
//     const formattedDate = adjustedDate.toISOString().replace("T", " ").slice(0, 19);

//     return `${formattedDate}`;
// }





// export const convertToTimezoneUTCOffset = (utcTime, offset) => {
//     // Parse the UTC time
//     const utcDate = new Date(utcTime);

//     // Extract hours and minutes from the offset
//     const [sign, hours, minutes] = offset.match(/([+-])(\d{2}):(\d{2})/).slice(1);

//     // Convert the offset to milliseconds
//     const offsetMilliseconds =
//         (parseInt(hours, 10) * 60 + parseInt(minutes, 10)) * 60 * 1000;

//     // Adjust the time based on the offset
//     const adjustedTime =
//         sign === "+"
//             ? new Date(utcDate.getTime() + offsetMilliseconds)
//             : new Date(utcDate.getTime() - offsetMilliseconds);

//     // Format the adjusted time
//     const year = adjustedTime.getFullYear();
//     const month = String(adjustedTime.getMonth() + 1).padStart(2, "0");
//     const day = String(adjustedTime.getDate()).padStart(2, "0");
//     const hoursLocal = String(adjustedTime.getHours()).padStart(2, "0");
//     const minutesLocal = String(adjustedTime.getMinutes()).padStart(2, "0");
//     const seconds = String(adjustedTime.getSeconds()).padStart(2, "0");
//     const milliseconds = String(adjustedTime.getMilliseconds()).padStart(3, "0");

//     return `${year}-${month}-${day} ${hoursLocal}:${minutesLocal}:${seconds}.${milliseconds}`;
// };


// export const convertToTimezone = (utcTime, timezone) => {
//     // Extract the offset part from the timezone string (e.g., "-4:30" from "UTC-4:30")
//     const offsetMatch = timezone.match(/UTC([+-]\d{1,2}:\d{2})/);
//     if (!offsetMatch) {
//         throw new Error("Invalid timezone format. Use 'UTC±HH:mm'.");
//     }
//     const offset = offsetMatch[1];

//     // Parse the UTC time
//     const utcDate = new Date(utcTime);

//     // Extract hours and minutes from the offset
//     const [sign, hours, minutes] = offset.match(/([+-])(\d{1,2}):(\d{2})/).slice(1);

//     // Convert the offset to milliseconds
//     const offsetMilliseconds =
//         (parseInt(hours, 10) * 60 + parseInt(minutes, 10)) * 60 * 1000;

//     // Adjust the time based on the offset
//     const adjustedTime =
//         sign === "+"
//             ? new Date(utcDate.getTime() + offsetMilliseconds)
//             : new Date(utcDate.getTime() - offsetMilliseconds);

//     // Format the adjusted time
//     const year = adjustedTime.getFullYear();
//     const month = String(adjustedTime.getMonth() + 1).padStart(2, "0");
//     const day = String(adjustedTime.getDate()).padStart(2, "0");
//     const hoursLocal = String(adjustedTime.getHours()).padStart(2, "0");
//     const minutesLocal = String(adjustedTime.getMinutes()).padStart(2, "0");
//     const seconds = String(adjustedTime.getSeconds()).padStart(2, "0");
//     const milliseconds = String(adjustedTime.getMilliseconds()).padStart(3, "0");

//     return `${year}-${month}-${day} ${hoursLocal}:${minutesLocal}:${seconds}.${milliseconds}`;
// };


// utils/timeRange.js

// export const calculateTimeRange = (timeZone = "UTC") => {
//     const currentTime = new Date(); // Current system time
//     const startTime = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000); // Subtract 24 hours

//     // Format options for converting to the specified time zone
//     const formatOptions = {
//         timeZone: timeZone,
//         hour12: false,
//         year: "numeric",
//         month: "2-digit",
//         day: "2-digit",
//         hour: "2-digit",
//         minute: "2-digit",
//         second: "2-digit",
//     };

//     // Convert currentTime and startTime to the specified time zone
//     const formattedEndTime = new Intl.DateTimeFormat("en-US", formatOptions).format(currentTime);
//     const formattedStartTime = new Intl.DateTimeFormat("en-US", formatOptions).format(startTime);

//     return {
//         start: formattedStartTime,
//         end: formattedEndTime,
//     };
// };


// export const calculateTimeRangeFromUTC = (timeZoneOffset, rangeType) => {
//     // Step 1: Parse time zone offset (e.g., UTC+5:30 or UTC-4:30)
//     const offsetMatch = timeZoneOffset.match(/UTC([+-])(\d{1,2}):(\d{2})/);
//     if (!offsetMatch) throw new Error("Invalid time zone format. Use UTC±hh:mm");

//     const sign = offsetMatch[1] === "+" ? 1 : -1;
//     const offsetHours = parseInt(offsetMatch[2], 10) * sign;
//     const offsetMinutes = parseInt(offsetMatch[3], 10) * sign;

//     // Step 2: Adjust current UTC time with offset
//     const currentUTC = new Date();
//     const localTime = new Date(currentUTC);
//     localTime.setUTCHours(localTime.getUTCHours() + offsetHours);
//     localTime.setUTCMinutes(localTime.getUTCMinutes() + offsetMinutes);

//     // Step 3: Calculate the start time based on range type
//     const startTime = new Date(localTime);

//     switch (rangeType) {
//         case "lastHour":
//             startTime.setHours(localTime.getHours() - 1);
//             break;
//         case "last24Hours":
//             startTime.setHours(localTime.getHours() - 24);
//             break;
//         case "lastWeek":
//             startTime.setDate(localTime.getDate() - 7);
//             break;
//         case "lastMonth":
//             startTime.setMonth(localTime.getMonth() - 1);
//             break;
//         case "thisMonth":
//             startTime.setDate(1);
//             startTime.setHours(0, 0, 0, 0);
//             break;
//         default:
//             break;
//     }

//     // Step 4: Format the date as "YYYY-MM-DD HH:mm:ss"
//     const formatDate = (date) => {
//         const year = date.getFullYear();
//         const month = String(date.getMonth() + 1).padStart(2, "0");
//         const day = String(date.getDate()).padStart(2, "0");
//         const hours = String(date.getHours()).padStart(2, "0");
//         const minutes = String(date.getMinutes()).padStart(2, "0");
//         const seconds = String(date.getSeconds()).padStart(2, "0");
//         return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
//     };

//     return {
//         start: formatDate(startTime),
//         end: formatDate(localTime),
//     };
// };


// Working code




// export const calculateTimeRangeFromTimeZone = (timeZone, rangeType) => {
//     // Step 1: Get current UTC time
//     const currentUTC = new Date();
//     console.log('Function Currect UTC Input:', currentUTC); // Debug
//     // Step 2: Extract time zone offset from 'UTC+5:30'
//     const match = timeZone.match(/UTC([+-])(\d{1,2}):(\d{2})/);
//     if (!match) {
//         throw new Error('Invalid time zone specified');
//     }

//     const offsetSign = match[1] === '+' ? 1 : -1;
//     const offsetHours = parseInt(match[2], 10) * offsetSign;
//     const offsetMinutes = parseInt(match[3], 10) * offsetSign;

//     // console.log('Offset Hours:', offsetHours); // Debug
//     // console.log('Offset Minutes:', offsetMinutes); // Debug
//     // 11:30:00 + 5
//     // x + 00

//     // Step 3: Adjust current UTC time with the offset
//     const localTime = new Date(currentUTC);
//     console.log('Function currentUTC Input:', localTime); // Debug
//     localTime.setUTCHours(localTime.getUTCHours() + offsetHours);
//     localTime.setUTCMinutes(localTime.getUTCMinutes() + offsetMinutes);
//     console.log('Function Currect Local UTC Input:', localTime); // Debug
//     //console.log('Local Time:', localTime.toISOString()); // Debug

//     // Step 4: Calculate the start time based on range type
//     const startTime = new Date(localTime);

//     switch (rangeType) {
//         case "lastHour":
//             startTime.setHours(localTime.getHours() - 1);
//             break;
//         case "last24Hours":
//             startTime.setHours(localTime.getHours() - 24);
//             break;
//         case "lastWeek":
//             startTime.setDate(localTime.getDate() - 7);
//             break;
//         case "lastMonth":
//             startTime.setMonth(localTime.getMonth() - 1);
//             break;
//         case "thisMonth":
//             // Set to first day of the current month and reset time to 00:00:00
//             startTime.setMonth(localTime.getMonth()); // Set year, month, and first day of the month
//             startTime.setDate(1);
//             startTime.setHours(0, 0, 0, 0); // Midnight
//             // const localStartTime = new Date('Sun Dec 01 2024 00:00:00 GMT+0530')
//             //     .toISOString()  // Convert to UTC
//             //     .replace(/(.*)\..+/, '$1Z'); // Remove milliseconds for clean format

//             // console.log('Start This Month Local Time:', localStartTime); // Debug
//             console.log('Function Range Input:', rangeType); // Debug
//             console.log('Function TimeZone Input:', timeZone); // Debug

//             break;
//         default:
//             break;
//     }

//     // Calculate UTC start and end 
//     const startUTC = new Date(startTime.getTime() - offsetHours * 3600000 - offsetMinutes * 60000);
//     const endUTC = new Date(localTime.getTime() - offsetHours * 3600000 - offsetMinutes * 60000);
//     console.log('Function StartTime Input:', startTime); // Debug
//     // console.log('Start Time (UTC):', startUTC.toISOString()); // Debug
//     // console.log('End Time (UTC):', endUTC.toISOString()); // Debug
//     // console.log('Selected Start Time (UTC):', startTime); // Debug
//     return {
//         startLocal: startTime.toISOString(),
//         endLocal: localTime.toISOString(),
//         startUTC: startUTC.toISOString(),
//         endUTC: endUTC.toISOString(),
//     };
// };

export const convertToTimeZone = (timeZone) => {
    const options = {
        timeZone,
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const parts = formatter.formatToParts(new Date());

    return new Date(Date.UTC(
        parseInt(parts.find(p => p.type === 'year').value, 10),
        parseInt(parts.find(p => p.type === 'month').value, 10) - 1,
        parseInt(parts.find(p => p.type === 'day').value, 10),
        parseInt(parts.find(p => p.type === 'hour').value, 10),
        parseInt(parts.find(p => p.type === 'minute').value, 10),
        parseInt(parts.find(p => p.type === 'second').value, 10)
    ));
};


const calculateTimeZoneOffset = (timeZone) => {
    // Create a date for the current time
    const date = new Date();

    // Get the local time for the specified time zone
    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone,
        timeZoneName: "short",
    });

    // Format the date and extract the time zone name
    const parts = formatter.formatToParts(date);
    const timeZoneName = parts.find(part => part.type === "timeZoneName")?.value;

    if (!timeZoneName) {
        throw new Error("Could not determine time zone offset.");
    }

    // Calculate the offset in minutes using Date and Intl.DateTimeFormat
    const utcDate = new Date(date.toISOString()); // Ensure UTC reference
    const localDate = new Date(date.toLocaleString("en-US", { timeZone })); // Time zone adjusted

    const offsetMinutes = (utcDate - localDate) / (1000 * 60); // Offset in minutes
    return offsetMinutes;
};

export const getDateTimeInTimezone = (timezone) => {
    try {
        const options = {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(new Date());

        const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value
            }-${parts.find(p => p.type === 'day').value}`;
        const formattedTime = `${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value
            }:${parts.find(p => p.type === 'second').value}`;

        return `${formattedDate} ${formattedTime}`;
    } catch (error) {
        console.error('Invalid timezone or error occurred:', error);
        return 'Invalid timezone';
    }
};

export const getUTCDateTimeFromTimezone = (timezone) => {
    try {
        const options = {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(new Date());

        const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value
            }-${parts.find(p => p.type === 'day').value}`;
        const formattedTime = `${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value
            }:${parts.find(p => p.type === 'second').value}`;

        const date = new Date(
            `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value}T${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`
        );

        const utcDateTime = date.toUTCString(); // Get UTC Date/Time

        return utcDateTime;
    } catch (error) {
        console.error('Invalid timezone or error occurred:', error);
        return 'Invalid timezone';
    }
};

export const getUTCTime = (mytimeZone) => {
    try {
        const date = new Date();
        const options = { timeZone: mytimeZone, hour12: true };

        // Formatting the date and time based on the user's input timezone
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            ...options,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(date);

        const formattedTime = new Intl.DateTimeFormat('en-US', {
            ...options,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }).format(date);

        return ({ formattedDate, formattedTime });
    } catch (error) {
        return ('Invalid timezone');
    }

};
export const calculateDateRange = (timezone, timeRange) => {

    const userCurrenTime = getUTCTime(timezone);
    console.log("userCurrenDate: ", userCurrenTime.formattedDate);
    console.log("userCurrenTime: ", userCurrenTime.formattedTime);
    const currectUserDateTime = userCurrenTime.formattedDate + ', ' + userCurrenTime.formattedTime;
    console.log("TimeZone: ", timezone);
    console.log("currectUserDateTime: ", currectUserDateTime);
    const inputZone = Intl.DateTimeFormat().resolvedOptions().timeZone || timezone;
    const utcZone = 'UTC';

    const nowInInputZone = new Date().toLocaleString('en-US', { timeZone: inputZone });
    console.log("nowInInputZone: ", nowInInputZone);
    const now = new Date(currectUserDateTime);
    console.log("now: ", now);
    let startDate, endDate;

    switch (timeRange) {
        case 'last30Days':
            startDate = now.setDate(now.getDate() - 30);
            endDate = now;
            break;
        case 'lastMonth':
            startDate = new Date(now.setMonth(now.getMonth() - 1));
            startDate.setDate(1); // Set to first day of the month
            endDate = new Date(now.setMonth(now.getMonth(), 0)); // Last day of the previous month
            break;
        case 'thisMonth':
            startDate = new Date(now.setDate(1)); // First day of the current month
            endDate = new Date(now.setDate(0)); // Last day of the current month
            break;
        case 'last60Minutes':
            startDate = new Date(now.setMinutes(now.getMinutes() - 60));
            endDate = now;
            break;
        case 'last24Hours':
            startDate = new Date(now.setHours(now.getHours() - 24));
            endDate = now;
            break;
        case 'last7Days':
            startDate = new Date(now.setDate(now.getDate() - 7));
            endDate = now;
            break;
        default:
            throw new Error(`Unsupported time range: ${timeRange}`);
    }

    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: inputZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        hour12: false, // Use 24-hour format
    });

    const utcFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: utcZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        hour12: false, // Use 24-hour format
    });

    const formattedStartDate = formatter.format(startDate);
    const formattedEndDate = formatter.format(endDate);

    const utcFormattedStartDate = utcFormatter.format(startDate);
    const utcFormattedEndDate = utcFormatter.format(endDate);

    return {
        startLocal: formattedStartDate,
        endLocal: formattedEndDate,
        startUTC: utcFormattedStartDate,
        endUTC: utcFormattedEndDate
    };
};



export const calculateTimeRangeFromTimeZone = (timeZone, rangeType) => {
    // Step 1: Get current time in specified time zone
    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone,
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });

    const formatToDate = (date) => {
        const [formattedDate, formattedTime] = formatter.format(date).split(", ");
        const [month, day, year] = formattedDate.split("/");
        const [hour, minute, second] = formattedTime.split(":");
        return new Date(year, month - 1, day, hour, minute, second);
    };

    const currentTime = formatToDate(new Date());

    const startTime = new Date(currentTime);
    const endTime = new Date(currentTime);

    // Step 2: Adjust time based on range type
    switch (rangeType) {
        case "last60Minutes":
            startTime.setHours(currentTime.getHours() - 1);
            break;
        case "last24Hours":
            startTime.setHours(currentTime.getHours() - 24);
            break;
        case "lastWeek":
            startTime.setDate(currentTime.getDate() - 7);
            break;
        case "last30Days":
            startTime.setMonth(currentTime.getMonth() - 1);
            break;
        case "lastMonth":
            startTime.setDate(currentTime.getDate() - 30);
            break;
        case "thisMonth":
            startTime.setDate(1);
            startTime.setHours(0, 0, 0, 0); // Midnight start of the month
            break;
        default:
            throw new Error("Unsupported range type");
    }

    // Step 3: Calculate UTC equivalents
    const startUTC = new Date(startTime.getTime() + startTime.getTimezoneOffset() * 60000);
    const endUTC = new Date(endTime.getTime() + endTime.getTimezoneOffset() * 60000);

    return {
        startLocal: startTime.toISOString(),
        endLocal: endTime.toISOString(),
        startUTC: startUTC.toISOString(),
        endUTC: endUTC.toISOString(),
    };
};

export const calculateTimeRangeFromTimeZon = (timeZone, rangeType) => {
    const currentTime = convertToTimeZone(timeZone);
    const offsetMinutes = calculateTimeZoneOffset(timeZone);
    console.log("Offset in minutes:", offsetMinutes);

    const startTime = new Date(currentTime); // Clone the current time
    const endTime = new Date(currentTime); // Clone the current time for end time

    switch (rangeType) {
        case "lastHour":
            startTime.setHours(currentTime.getHours() - 1);
            break;
        case "last24Hours":
            startTime.setHours(currentTime.getHours() - 24);
            break;
        case "lastWeek":
            startTime.setDate(currentTime.getDate() - 7);
            break;
        case "last30Days":
            startTime.setMonth(currentTime.getMonth() - 1);
            break;
        case "thisMonth":
            //startTime.setMonth(1);
            startTime.setDate(1);
            startTime.setHours(0); // Midnight at the start of the month
            break;
        default:
            throw new Error("Unsupported range type");
    }

    // Convert local times to UTC for start and end
    const offsetHours = currentTime.getTimezoneOffset() / -60; // Convert offset back to hours
    const offsetMilliseconds = offsetHours * 3600000;

    const startUTC = new Date(startTime.getTime() - offsetMilliseconds);
    const endUTC = new Date(endTime.getTime() - offsetMilliseconds);

    return {
        startLocal: startTime.toISOString(),
        endLocal: endTime.toISOString(),
        startUTC: startUTC.toISOString(),
        endUTC: endUTC.toISOString(),
    };
};

// export const calculateTimeRangeFromTimeZon = (timeZone, rangeType) => {
//     // Step 1: Get current UTC time
//     const currentUTC = new Date(); // Current UTC time
//     console.log('Function Current UTC Input:', currentUTC); // Debug

//     // Step 2: Extract time zone offset from 'UTC+5:30'
//     const match = timeZone.match(/UTC([+-])(\d{1,2}):(\d{2})/);
//     if (!match) {
//         throw new Error('Invalid time zone specified');
//     }

//     const offsetSign = match[1] === '+' ? 1 : -1;
//     const offsetHours = parseInt(match[2], 10) * offsetSign;
//     const offsetMinutes = parseInt(match[3], 10) * offsetSign;

//     // Step 3: Adjust current UTC time with the offset
//     const localTime = new Date(currentUTC);
//     console.log('Adjusted Local Time with Time Zone Offset:', localTime); // Debug
//     localTime.setUTCHours(localTime.getUTCHours() + offsetHours);
//     localTime.setUTCMinutes(localTime.getUTCMinutes() + offsetMinutes);

//     // Step 4: Calculate the start time based on range type
//     const startTime = new Date(localTime);

//     switch (rangeType) {
//         case "lastHour":
//             startTime.setHours(localTime.getHours() - 1);
//             break;
//         case "last24Hours":
//             startTime.setHours(localTime.getHours() - 24);
//             break;
//         case "lastWeek":
//             startTime.setDate(localTime.getDate() - 7);
//             break;
//         case "lastMonth":
//             startTime.setMonth(localTime.getMonth() - 1);
//             break;
//         case "thisMonth":
//             startTime.setMonth(localTime.getMonth()); // Set month to current month
//             startTime.setDate(1); // Set to first day of the month
//             startTime.setHours(0, 0, 0, 0); // Midnight
//             break;
//         default:
//             throw new Error('Unsupported range type');
//     }

//     // Calculate UTC start and end 
//     const startUTC = new Date(startTime.getTime() - offsetHours * 3600000 - offsetMinutes * 60000);
//     const endUTC = new Date(localTime.getTime() - offsetHours * 3600000 - offsetMinutes * 60000);

//     console.log('Function StartTime Output:', startTime); // Debug
//     console.log('Start Time (UTC):', startUTC.toISOString()); // Debug
//     console.log('End Time (UTC):', endUTC.toISOString()); // Debug

//     return {
//         startLocal: startTime.toISOString(),
//         endLocal: localTime.toISOString(),
//         startUTC: startUTC.toISOString(),
//         endUTC: endUTC.toISOString(),
//     };
// };

export const getUserCustomCalendarLocalDateTime = (userTimeZone) => {
    const now = new Date();

    try {
        // Use Intl.DateTimeFormat to get the current time in the specified time zone
        const formatter = new Intl.DateTimeFormat("en-US", {
            timeZone: userTimeZone,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false, // Use 24-hour format
        });

        // Format the current time
        const parts = formatter.formatToParts(now);
        const year = parts.find((p) => p.type === "year").value;
        const month = parts.find((p) => p.type === "month").value;
        const day = parts.find((p) => p.type === "day").value;
        const hour = parts.find((p) => p.type === "hour").value;
        const minute = parts.find((p) => p.type === "minute").value;
        const second = parts.find((p) => p.type === "second").value;

        // Return formatted datetime for datetime-local input
        return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    } catch (error) {
        console.error("Invalid time zone or error in formatting:", error);
        // Fallback to UTC if time zone is invalid
        return now.toISOString().slice(0, 19);
    }
};



export const getCurrentCalendarLocalDateTime = (userTimeZone) => {
    const now = new Date();

    // Parse the offset from the timeZone string
    const offsetMatch = userTimeZone.match(/UTC([+-])(\d{1,2}):(\d{2})/);

    if (!offsetMatch) {
        console.error("Invalid time zone format. Please use 'UTC±HH:MM'.");
        return now.toISOString(); // Fallback to UTC
    }

    const sign = offsetMatch[1] === "+" ? 1 : -1;
    const offsetHours = parseInt(offsetMatch[2], 10) * sign;
    const offsetMinutes = parseInt(offsetMatch[3], 10) * sign;

    // Adjust the current UTC time to the local time
    const localNow = new Date(now);
    localNow.setHours(localNow.getUTCHours() + offsetHours);
    localNow.setMinutes(localNow.getUTCMinutes() + offsetMinutes);

    // Format local datetime as "YYYY-MM-DDTHH:mm:ss"
    const year = localNow.getFullYear();
    const month = String(localNow.getMonth() + 1).padStart(2, "0");
    const day = String(localNow.getDate()).padStart(2, "0");
    const hours = String(localNow.getHours()).padStart(2, "0");
    const minutes = String(localNow.getMinutes()).padStart(2, "0");
    const seconds = String(localNow.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};



export const getprofileAndUTCTimeLatest = (myTimeZone, timeRange) => {
    const now = new Date(); // Current date and time

    // Format the current date for the specified time zone
    const formatToTimezone = (date) =>
        new Intl.DateTimeFormat('en-US', {
            timeZone: myTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZoneName: 'short',
        }).format(date);

    // Current time in the specified time zone
    const endTime = now.toISOString();

    // Adjust the start time based on the time range
    const userDateStart = new Date(now); // Clone `now`
    switch (timeRange) {
        case 'last60Minutes':
            userDateStart.setMinutes(userDateStart.getMinutes() - 60);
            break;
        case 'last24Hours':
            userDateStart.setHours(userDateStart.getHours() - 24);
            break;
        case 'lastWeek':
            userDateStart.setDate(userDateStart.getDate() - 7);
            break;
        case 'last30Days':
            userDateStart.setDate(userDateStart.getDate() - 30);
            break;
        case 'lastMonth':
            userDateStart.setMonth(userDateStart.getMonth() - 1);
            break;
        case 'thisMonth':
            userDateStart.setDate(1);
            userDateStart.setHours(0, 0, 0, 0);
            break;
        default:
            break;
    }

    const utcStartTime = userDateStart.toISOString(); // Start time in UTC
    const profileStart = formatToTimezone(userDateStart); // Start time in the specified time zone
    const profileEnd = formatToTimezone(now); // End time in the specified time zone
    // console.log("getprofileAndUTCTime Time Range Need to Adjested: ", userDateStart);
    // console.log("getprofileAndUTCTime Current Time Range Need to Adjested: ", userDateStart.toISOString());
    // console.log("getprofileAndUTCTime TimeZone: ", myTimeZone);
    // console.log("getprofileAndUTCTime Time Range Selected: ", timeRange);
    // console.log("getprofileAndUTCTime Start Time In Zone: ", cleanTimeRange(profileStart));
    // console.log("getprofileAndUTCTime End Time In Zone: ", cleanTimeRange(profileEnd));
    // console.log("getprofileAndUTCTime Start Time In UTC: ", utcStartTime);
    // console.log("getprofileAndUTCTime End Time In UTC: ", endTime);

    return {
        profileEnd: cleanTimeRange(profileEnd),
        profileStart: cleanTimeRange(profileStart),
        utcEndTime: endTime,
        utcStartTime: utcStartTime,
    };
};






//Working Code
export const extractErrorSnippet = (errorText, length = 50) => {
    if (!errorText) return "No error text provided";

    const firstColonIndex = errorText.indexOf(":");
    if (firstColonIndex === -1) return "No colon found in the error text";

    // Get the substring starting from the colon, limited to the specified length
    const snippet = errorText.substring(firstColonIndex + 1, firstColonIndex + 1 + length).trim();
    return snippet || "No content available after the colon";
};

export const truncateText = (text, maxLength) => {
    return text && text.length > maxLength ? text.slice(0, maxLength) + "..." : text
};


export const cleanTimeRange = (datetime) => {
    const datePart = datetime.split(",")[0]; // Extract "12/19/2024"
    const timePart = datetime.split(",")[1].trim().split(" ")[0]; // Extract "12:35:07"
    const cleanDateTime = `${datePart.replace(/\//g, "-")} ${timePart}`; // Replace "/" with "-" and concatenate

    return cleanDateTime;
}


export const formatUTCTimestamp = (isoTimestamp) => {
    return isoTimestamp.replace('T', ' ').replace('Z', '');
};


export const getprofileAndUTCTime = (myTimeZone, timeRange) => {
    const now = new Date(); // Current date and time

    // Format the current date for the specified time zone
    const formatToTimezone = (date) =>
        new Intl.DateTimeFormat('en-US', {
            timeZone: myTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZoneName: 'short',
        }).format(date);

    // Current time in the specified time zone
    const endTime = now.toISOString();

    // Adjust the start time based on the time range
    const userDateStart = new Date(now); // Clone `now`
    //const userDateStart = "Thu Dec 19 2024 08:13:44 GMT-0500 (Eastern Standard Time)"; // Clone `now`
    switch (timeRange) {
        case 'last60Minutes':
            userDateStart.setMinutes(userDateStart.getMinutes() - 60);
            break;
        case 'last24Hours':
            userDateStart.setHours(userDateStart.getHours() - 24);
            break;
        case 'lastWeek':
            userDateStart.setDate(userDateStart.getDate() - 7);
            break;
        case 'last30Days':
            userDateStart.setDate(userDateStart.getDate() - 30);
            break;
        case 'lastMonth':
            userDateStart.setMonth(userDateStart.getMonth() - 1);
            break;
        case 'thisMonth':
            userDateStart.setDate(1);
            userDateStart.setHours(0, 0, 0, 0);
            break;
        default:
            break;
    }

    const utcStartTime = userDateStart.toISOString(); // Start time in UTC
    const profileStart = formatToTimezone(userDateStart); // Start time in the specified time zone
    const profileEnd = formatToTimezone(now); // End time in the specified time zone
    // console.log("getprofileAndUTCTime Time Range Need to Adjested: ", userDateStart);
    // console.log("getprofileAndUTCTime Current Time Range Need to Adjested: ", userDateStart.toISOString());
    // console.log("getprofileAndUTCTime TimeZone: ", myTimeZone);
    // console.log("getprofileAndUTCTime Time Range Selected: ", timeRange);
    // console.log("getprofileAndUTCTime Start Time In Zone: ", cleanTimeRange(profileStart));
    // console.log("getprofileAndUTCTime End Time In Zone: ", cleanTimeRange(profileEnd));
    // console.log("getprofileAndUTCTime Start Time In UTC: ", utcStartTime);
    // console.log("getprofileAndUTCTime End Time In UTC: ", endTime);

    return {
        endLocal: cleanTimeRange(profileEnd),
        startLocal: cleanTimeRange(profileStart),
        endUTC: endTime,
        startUTC: utcStartTime,
    };
};



export const convertTableDataToUserTimeZone = (utcDateString, timeZone) => {
    // Create a date instance representing the current time in the given time zone
    const now = new Date();

    // Format the date to get the time in the target time zone
    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone,
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
    });

    const formattedTime = formatter.format(now); // Get the local time in the target zone

    // Create a Date object from the formatted time in the target zone
    const [hours, minutes] = formattedTime.split(":").map(Number);
    const targetDate = new Date(now);
    targetDate.setUTCHours(hours);
    targetDate.setUTCMinutes(minutes);

    // Calculate the offset in minutes
    const offsetMinutes = (targetDate.getTime() - now.getTime()) / (1000 * 60);

    // Convert offset to "UTC±HH:mm" format
    const sign = offsetMinutes >= 0 ? "+" : "-";
    const absOffset = Math.abs(offsetMinutes);
    const offsetHours = Math.floor(absOffset / 60)
        .toString()
        .padStart(2, "0");
    const offsetMins = (absOffset % 60).toString().padStart(2, "0");

    const utcOffset = `UTC${sign}${offsetHours}:${offsetMins}`;
    //console.log('Converted UTC Offset Here:', utcOffset);


    return adjustTimeWithUTCOffset(utcDateString, utcOffset);
}


export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email); // Returns true or false
};

export const getAccessTokenFromStorage = () => {
    const token = localStorage.getItem('accessToken');
    const expiry = localStorage.getItem('accessTokenExpiry');
    const currentTime = Date.now() / 1000; // current time in seconds

    if (token && expiry && currentTime < expiry) {
        return token; // If token is valid
    }
    return null; // Token is expired or doesn't exist
};

const setAccessTokenInStorage = (token, expiry) => {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('accessTokenExpiry', expiry);
};

export const fetchClientDetails = async () => {
    try {
        const requestBody = {
            'DetailsPage': 'user_details',
            'CustomerID': sessionStorage.getItem('CustomerID'),
        }
        // Replace with your actual API endpoint to fetch client details
        console.log('Client Request Details: ', requestBody);
        const response = await AxiosInstance.post('/failure-message', requestBody);
        console.log('Client Details: ', response);
        if (response.status === 200) {
            // Assuming you need some data from the client details response
            return response.data; // Return clientID or any relevant details
        } else {
            console.error('Failed to fetch client details');
            return null;
        }
    } catch (error) {
        console.error('Error fetching client details:', error);
        return null;
    }
};

//getAccessToken = async (tokenUrl, clientId, clientSecret) => {

export const fetchAccessToken = async (conditions) => {
    try {
        console.log("Fetch assecc token Utility print: ", conditions);
        const response = await getAccessToken(conditions.message.TokenURL, conditions.message.ClientID, conditions.message.ClientSecret); // Example endpoint
        console.log("Common Utility print: ", response);
        console.log("Satus code Utility print: ", response.status);
        if (response.status === 200) {
            const { access_token, expires_in } = response.data; // Assuming response contains accessToken and expiryTime
            const expiryDate = Math.floor(Date.now() / 1000) + expires_in; // Calculate expiry time in seconds
            setAccessTokenInStorage(access_token, expiryDate); // Save token and expiry in local storage
            return access_token; // Return the new token
        } else {
            console.error('Failed to fetch access token');
            return null;
        }
    } catch (error) {
        console.error('Error fetching access token:', error);
        return null;
    }
};


export const getAccessToken = async (tokenUrl, clientId, clientSecret) => {
    try {

        const payload = { "grant_type": "client_credentials" };

        const response = await axios.post(tokenUrl, payload, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            auth: {
                username: clientId,
                password: clientSecret,
            },
        });
        console.log("Response access token:", response);
        return response;
    } catch (error) {
        console.error("Error fetching access token:", error);
        throw error;
    }
};


export const retryMessage = async (hostUrl, token, data) => {
    try {
        const postData = {
            EndPoint: data.EndPoint,
            ReceiverSystem: data.ReceiverSystem,
            Payload: data.Payload,
            ApplicationID: data.ApplicationID,
            SenderSystem: data.SenderSystem,
            Action: data.Action,
            SenderAdapter: data.SenderAdapter,
            Encryption: data.Encryption === "1" ? "1" : "0",
        };

        const headers = {
            //'Access-Control-Allow-Origin': '*',
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        };
        console.log("Request Body:", postData);
        console.log("Request Header:", headers);
        //const url = 'https://610d062ctrial.it-cpitrial05-rt.cfapps.us10-001.hana.ondemand.com/http/inbound';
        const response = await axios.post(hostUrl, postData, headers);

        console.log("Response Data:", response.data);
        return response;
    } catch (error) {
        console.error("Error in retryMessage function:", error);
        throw error;
    }
};


