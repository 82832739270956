import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Reusable function to check if the user is logged in and maintain session data
const useCheckAuth = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve session data from sessionStorage
        const EmailID = sessionStorage.getItem('EmailID');
        const customerID = sessionStorage.getItem('CustomerID');
        const userName = sessionStorage.getItem('UserName');
        const accountType = sessionStorage.getItem('AccountType');

        // Check if all necessary session data exists
        if (EmailID && customerID && userName && accountType) {
            // If session is valid, redirect to the dashboard
            navigate('/overview');
        } else {
            // If session data is missing, clear sessionStorage and redirect to login
            sessionStorage.clear(); // Clear any potentially corrupted session data
            navigate('/login');
        }
    }, [navigate]);
};

export default useCheckAuth;
