import React, { useState } from "react";
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import AllModals from '../../ProHelpers/AllModals';

function SecurityFeatures() {
    const EmailID = sessionStorage.getItem('EmailID');
    const [modalType, setModalType] = useState(null);

    const handleClose = () => setModalType(null);
    return (
        <>
            <Header setModalType={setModalType} />
            <SideBar />
            <div className="app-container">
                <main id="main" className="main">
                    <PageTitle title={"Settings"} subtitle={"Features"} />
                    <AllModals modalType={modalType} handleClose={handleClose} setModalType={setModalType} />
                    <div>SecurityFeatures</div>
                </main>
            </div>
            <Footer />
            <BackToTop />
        </>
    )
}

export default SecurityFeatures