import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import './MonitoringOverall.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker'; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import { useAlert } from '../../ProHelpers/AlertContext';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import { getprofileAndUTCTime, formatUTCTimestamp } from '../../ProHelpers/CommonUtils'
import AllModals from '../../ProHelpers/AllModals';

function MonitoringOverall() {
    const EmailID = sessionStorage.getItem('EmailID');
    //const systemTimeZone = sessionStorage.getItem('SystemTimeZone');
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(false);
    const [responseData, setresponseData] = useState([]);
    const [message, setMessage] = useState("Please wait, loading...");
    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
    const [calculatedTimeRange, setCalculatedTimeRange] = useState({ start: null, end: null });
    const [timeRange, setTimeRange] = useState("last24Hours");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [localPageSize, setLocalPageSize] = useState(25);
    const [modalType, setModalType] = useState(null);

    const handleClose = () => setModalType(null);
    const [calendarStart, setCalendarStart] = useState(null);
    const [calendarEnd, setCalendarEnd] = useState(null);

    const [customStart, setCustomStart] = useState(null);
    const [customEnd, setCustomEnd] = useState(null);
    const [gotoInputValue, setGotoInputValue] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [iFlowNameFilter, setIFlowNameFilter] = useState(null);
    const [packageNameFilter, setPackageNameFilter] = useState(null);
    const [senderFilter, setSenderFilter] = useState(null);
    const [receiverFilter, setReceiverFilter] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
    const [currentUser, setcurrentUser] = useState([]); // Spinner state
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState('');

    // const getTimeZone = (timeZone) => {
    //     if (!timeZone) {
    //         // Fallback to browser timezone if systemTimeZone is null or empty
    //         return Intl.DateTimeFormat().resolvedOptions().timeZone;
    //     }
    //     return timeZone;
    // };

    const getTimeZone = (timeZone) => {
        if (!timeZone) {
            // Fallback to browser timezone if systemTimeZone is null or empty
            const resolvedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // Correct the "Calcutta" to "Kolkata"
            if (resolvedTimeZone === "Asia/Calcutta") {
                return "Asia/Kolkata"; // Map Calcutta to Kolkata
            }

            return resolvedTimeZone;
        }
        return timeZone;
    };

    //const userTimeZone = calculateTimeRangeFromTimeZone(getTimeZone(systemTimeZone), "last24Hours");

    const gotoPage = (page) => {
        setCurrentPage(page);
        setIsTableLoading(true);
        fetchDataAPI({
            'DetailsPage': false,
            'EmailID': EmailID,
            'Start': calculatedTimeRange.startUTC,
            'End': calculatedTimeRange.endUTC,
        }, page, localPageSize);
    };

    //  Start of all Handlers
    const handleSidebarItemClick = () => {
        localStorage.setItem("updatedTimeRange", "last24Hours");
    };

    const handlePageSizeChange = (event) => {
        const newPageSize = Number(event.target.value); // Get the new page size from the select input
        setLocalPageSize(newPageSize);// Set the new page size to the state
        setPageSize(newPageSize);
    };

    const handleOnChangeTimeRange = (event) => {
        const selectedValue = event.target.value;
        // const userTimeZone = sessionStorage.getItem('SystemTimeZone') || "";
        const userTimeZone = currentUser.TimeZone || "";
        const userTimeRange = getprofileAndUTCTime(getTimeZone(userTimeZone), selectedValue);
        if (selectedValue !== "custom") {
            if (userTimeRange) {
                setCalculatedTimeRange(userTimeRange); // Update state with the calculated range
            }
            setShowCustomDatePicker(false)
            setTimeRange(selectedValue);
        } else {
            setCustomStart("");
            setCustomEnd("");
            setSelectedStartDate("");
            setSelectedEndDate("");
            setTimeRange(selectedValue);
            setShowCustomDatePicker(true);
        }
    };

    const handleResetFilters = () => {
        setIFlowNameFilter(null);
        setPackageNameFilter(null);
        setSenderFilter(null);
        setReceiverFilter(null);
        setFilteredData(responseData); // Reset to all data
    };

    const handleSort = (key) => {
        // Toggle sorting order
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);

        // Sort the data
        const sortedData = [...responseData].sort((a, b) => {
            if (newSortOrder === 'asc') {
                return a[key].localeCompare(b[key]); // Ascending
            } else {
                return b[key].localeCompare(a[key]); // Descending
            }
        });

        // Update the state or table data
        setFilteredData(sortedData);
    };

    const handleConfirmClick = () => {
        let calTimeRange;
        let conditions;

        if (timeRange !== "custom") {
            localStorage.setItem("updatedTimeRange", timeRange)
            calTimeRange = getprofileAndUTCTime(getTimeZone(currentUser.TimeZone), timeRange);
            if (calTimeRange) {
                setCalculatedTimeRange(calTimeRange); // Update state with the calculated range
            }
            console.log("Stored Time Range: ", timeRange);
        } else {
            // If "custom" is selected, use custom date-time values
            if (!customStart || !customEnd) {

                console.error("Custom date-time values are missing.");
                setTimeout(() => showAlert("Custom date time values are missing.", 'failure'), 0);
                return; // Exit if custom dates are not provided
            }

            if (customStart && customEnd) {
                setCalculatedTimeRange({ start: customStart, end: customEnd }); // Update state with the calculated range
            }
        }
        setIsTableLoading(true);
        conditions = {
            DetailsPage: false,
            EmailID: EmailID,
            End: timeRange === "custom" ? customEnd : calTimeRange.endUTC,
            Start: timeRange === "custom" ? customStart : calTimeRange.startUTC,
        };

        fetchDataAPI(conditions, currentPage, localPageSize);
    };


    //  Start the execution
    useEffect(() => {
        setIsTableLoading(true);
        const records = JSON.parse(sessionStorage.getItem('Records'));
        setcurrentUser(records[0]);
        // const currentTime = convertToTimeZone(systemTimeZone);
        console.log('Current loggedin:', records[0]);
        console.log(`Current user timezone ${records[0].TimeZone}:`, getTimeZone(records[0].TimeZone));
        const storedTimeRange = localStorage.getItem("updatedTimeRange") || "last24Hours";
        const systemTimeZone = records[0].TimeZone;
        let currentUserDateTime;
        //if (systemTimeZone && systemTimeZone != "") {
        setShowCustomDatePicker(false);
        if (storedTimeRange) {
            currentUserDateTime = getprofileAndUTCTime(getTimeZone(systemTimeZone), storedTimeRange);
            setTimeRange(storedTimeRange);
        }
        if (currentUserDateTime) {
            setCalculatedTimeRange(currentUserDateTime); // Update state with the calculated range
            setCustomStart(currentUserDateTime.startUTC);
            setCustomEnd(currentUserDateTime.endUTC);
        }

        fetchDataAPI({
            'DetailsPage': false,
            'EmailID': EmailID,
            'End': currentUserDateTime.endUTC,
            'Start': currentUserDateTime.startUTC,
        }, 1, localPageSize);
        //}

    }, [localPageSize]);



    //  My code starts


    //  API all start
    const fetchDataAPI = async (condition = {}, Page, PageSize) => {
        const requestBody = { ...condition, Page, PageSize };
        //setLoading(true);
        //setMessage("Please Wait, Loading...");

        console.log("Request Body:", requestBody);

        try {
            const response = await AxiosInstance.post('/message-processinglog', requestBody);
            console.log('Response Data:', response.data);
            setIsTableLoading(true);
            if (response.data.status === "success" && response.data.message && response.data.message.data.length > 0) {
                setresponseData(response.data.message.data);
                setFilteredData(response.data.message.data);

                // Assuming the server returns totalItems and pageSize
                setTotalPages(Math.ceil(response.data.message.total_records / localPageSize));
                setCurrentPage(response.data.message.current_page);
                setLoading(false);
            } else {

                setresponseData([]);
                setFilteredData([]);
                setTotalPages(1); // Set to 1 if no data is available
            }
        } catch (err) {
            //console.error('Error fetching data:', err);
            setresponseData([]);
            setFilteredData([]);
            setIsTableLoading(true);
            setTotalPages(1);
        } finally {
            setLoading(false);
            setIsTableLoading(false);
        }
    };


    // API all end

    // filter section start

    // const handlerOnchangeFilter = (filter, setFilter) => {
    //     applyFilters({
    //         iFlowName: iFlowNameFilter,
    //         packageName: packageNameFilter,
    //         senderName: senderFilter,
    //         receiverName: receiverFilter,
    //         [filter]: selectedOption
    //     });
    // }

    const getUniqueOptions = (key) => {
        return [...new Set(responseData.map(item => item[key]))]
            .filter(item => item) // remove empty values
            .map(item => ({ value: item, label: item }));
    };

    const handleFilterChange = (filter, setFilter) => (selectedOption) => {
        setFilter(selectedOption);
        // const filtered = filteredData.filter((row) =>
        //     row[filterType]?.toLowerCase().includes(selectedOption.value.toLowerCase())
        // );
        // setFilteredData(filtered); // Update the table with filtered data
        applyFilters({
            iFlowName: iFlowNameFilter,
            packageName: packageNameFilter,
            senderName: senderFilter,
            receiverName: receiverFilter,
            [filter]: selectedOption
        });
    };

    const applyFilters = (filters) => {
        setFilteredData(
            responseData.filter(item =>
                (!filters.iFlowName || item.IFlowName === filters.iFlowName?.value) &&
                (!filters.packageName || item.PackageName === filters.packageName?.value) &&
                (!filters.senderName || item.Sender === filters.senderName?.value) &&
                (!filters.receiverName || item.Receiver === filters.receiverName?.value)
            )
        );
    };

    // filter section stop
    // Table data set up start
    const customComponents = {
        DropdownIndicator: () => null, // Removes the dropdown icon
        IndicatorSeparator: () => null, // Removes the separator
    };

    const columns = React.useMemo(
        () => [
            { Header: 'Package Name', accessor: 'PackageName', width: 300 },
            { Header: 'IFlow Name', accessor: 'IFlowName', width: 300 },
            { Header: 'Sender', accessor: 'Sender', width: 200 },
            { Header: 'Receiver', accessor: 'Receiver', width: 200 },
            {
                Header: 'Successful',
                accessor: 'Successful',
                width: 40,
                Cell: ({ row }) => {
                    const successfulValue = row.values.Successful; // Extract Retry value
                    return successfulValue > 0 ? (
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault(); // Prevents default link behavior
                                navigate('/monitoring/overall/details', {
                                    state: {
                                        messageOverallData: row.original,
                                        header: 'COMPLETED',
                                        dateRange: {
                                            startEndTime: {
                                                start: calculatedTimeRange.startUTC,
                                                end: calculatedTimeRange.endUTC,
                                                startLocal: calculatedTimeRange.startLocal,
                                                endLocal: calculatedTimeRange.endLocal
                                            }
                                        },
                                    },
                                });
                            }}
                            style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            {successfulValue}
                        </a>
                    ) : (
                        <span>{successfulValue}</span> // Display plain text for value 0
                    );
                },
            },

            {
                Header: 'Retry',
                accessor: 'Retry',
                width: 40,
                Cell: ({ row }) => {
                    const retryValue = row.values.Retry; // Extract Retry value
                    return retryValue > 0 ? (
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault(); // Prevents default link behavior
                                navigate('/monitoring/overall/details', {
                                    state: {
                                        messageOverallData: row.original,
                                        header: 'RETRY',
                                        dateRange: {
                                            startEndTime: {
                                                start: calculatedTimeRange.startUTC,
                                                end: calculatedTimeRange.endUTC,
                                                startLocal: calculatedTimeRange.startLocal,
                                                endLocal: calculatedTimeRange.endLocal
                                            }
                                        },
                                    },
                                });
                            }}
                            style={{ color: '#0d6efd', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            {retryValue}
                        </a>
                    ) : (
                        <span>{retryValue}</span> // Display plain text for value 0
                    );
                },
            },
            {
                Header: 'Error',
                accessor: 'Error',
                width: 40,
                Cell: ({ row }) => {
                    const errorValue = row.values.Error; // Extract Retry value
                    return errorValue > 0 ? (
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault(); // Prevents default link behavior
                                navigate('/monitoring/overall/details', {
                                    state: {
                                        messageOverallData: row.original,
                                        header: 'FAILED',
                                        dateRange: {
                                            startEndTime: {
                                                start: calculatedTimeRange.startUTC,
                                                end: calculatedTimeRange.endUTC,
                                                startLocal: calculatedTimeRange.startLocal,
                                                endLocal: calculatedTimeRange.endLocal
                                            }
                                        },
                                    },
                                });
                            }}
                            style={{ color: '#0d6efd', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            {errorValue}
                        </a>
                    ) : (
                        <span>{errorValue}</span> // Display plain text for value 0
                    );
                },
            },

            {
                Header: 'Total',
                accessor: 'Total',
                width: 40,
                Cell: ({ row }) => (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault(); // Prevents default link behavior
                            navigate('/monitoring/overall/detailsall', {
                                state: {
                                    messageOverallData: row.original,
                                    header: 'Total',
                                    dateRange: {
                                        startEndTime: {
                                            start: calculatedTimeRange.startUTC,
                                            end: calculatedTimeRange.endUTC,
                                            startLocal: calculatedTimeRange.startLocal,
                                            endLocal: calculatedTimeRange.endLocal
                                        }
                                    }
                                }
                            });
                        }}
                        style={{ color: '#0d6efd', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        {row.values.Total}
                    </a>
                ),
            },
        ],
        [navigate, filteredData]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize, // Keep this for useTable's state
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: { pageIndex: 0, pageSize: localPageSize }, // Use localPageSize here
            manualPagination: false,
        },
        usePagination
    );




    //Table data setup end
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    // const handleCustomStartOnChange = (date) => {
    //     const userTimeZone = currentUser.TimeZone;
    //     if (date) {
    //         const options = {
    //             userTimeZone,
    //             year: 'numeric',
    //             month: '2-digit',
    //             day: '2-digit',
    //             hour: '2-digit',
    //             minute: '2-digit',
    //             second: '2-digit',
    //             hour12: false,
    //         };

    //         // Format the date to user's timezone
    //         const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    //         console.log('Selected Start Date in Specified Timezone:', formattedDate);

    //         // Convert the formatted date to UTC
    //         const utcStartDate = new Date(formattedDate.toLocaleString('en-US', { timeZone: 'UTC' }));
    //         console.log('Local Start Date:', formattedDate.toString());
    //         console.log('Converted UTC Start Date:', utcStartDate.toISOString());

    //         // Format and store the start date
    //         const [datePart, timePart] = formattedDate.split(", ");
    //         const [month, day, year] = datePart.split("/");
    //         const formattedUserDate = `${year}-${month}-${day} ${timePart}`;
    //         setSelectedStartDate(formattedUserDate);
    //         setCustomStart(utcStartDate.toISOString());

    //         // Validate the start date
    //         if (utcStartDate > customEnd) {
    //             setTimeout(() => showAlert("Custom start date/time must not be later than the end date/time.", 'failure'), 0);
    //             setCustomStart(null);
    //         } else {
    //             validateCustomDates();
    //         }
    //     }
    // };
    // const handleCustomEndOnChange = (date) => {
    //     const userTimeZone = currentUser.TimeZone;
    //     if (date) {
    //         const options = {
    //             userTimeZone,
    //             year: 'numeric',
    //             month: '2-digit',
    //             day: '2-digit',
    //             hour: '2-digit',
    //             minute: '2-digit',
    //             second: '2-digit',
    //             hour12: false,
    //         };

    //         // Format the date to user's timezone
    //         const formattedEndDate = new Intl.DateTimeFormat('en-US', options).format(date);
    //         console.log('Selected End Date in Specified Timezone:', formattedEndDate);

    //         // Convert the formatted date to UTC
    //         const utcEndDate = new Date(formattedEndDate.toLocaleString('en-US', { timeZone: 'UTC' }));
    //         console.log('Local End Date:', formattedEndDate.toString());
    //         console.log('Converted UTC End Date:', utcEndDate.toISOString());

    //         // Format and store the end date
    //         const [datePart, timePart] = formattedEndDate.split(", ");
    //         const [month, day, year] = datePart.split("/");
    //         const formattedUserDate = `${year}-${month}-${day} ${timePart}`;
    //         setSelectedEndDate(formattedUserDate);
    //         setCustomEnd(utcEndDate.toISOString());

    //         // Validate the end date
    //         if (utcEndDate < customStart) {
    //             setTimeout(() => showAlert("Custom end date/time must not be earlier than the start date/time.", 'failure'), 0);
    //             setCustomEnd(null);
    //         } else {
    //             validateCustomDates();
    //         }
    //     }
    // };
    // // Function to validate both start and end dates
    // const validateCustomDates = () => {
    //     if (customStart && customEnd) {
    //         const utcStartDate = new Date(customStart);
    //         const utcEndDate = new Date(customEnd);

    //         if (utcStartDate > utcEndDate) {
    //             setTimeout(() => showAlert("Custom start date/time must not be later than the end date/time.", 'failure'), 0);
    //             setCustomStart(null); // Reset start date
    //             setCustomEnd(null);   // Reset end date
    //         }
    //     }
    // };
    const handleCustomStartOnChange = (date) => {
        const userTimeZone = currentUser.TimeZone;
        if (date) {
            const options = {
                userTimeZone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            };
            // Use `toLocaleString` to get the datetime in the specified timezone
            const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
            console.log('Selected Start Date in Specified Timezone:', formattedDate);
            // Convert the formatted datetime to a UTC datetime string

            const utcStartDate = new Date(formattedDate.toLocaleString('en-US', { timeZone: 'UTC' }));

            console.log('Local Start Date:', formattedDate.toString());
            console.log('Converted UTC Start Date:', utcStartDate.toISOString());
            // Convert to a JavaScript Date object
            // Split the input into date and time parts
            const [datePart, timePart] = formattedDate.split(", ");

            // Rearrange the date part from MM/DD/YYYY to YYYY-MM-DD
            const [month, day, year] = datePart.split("/");
            const formattedUserDate = `${year}-${month}-${day} ${timePart}`;
            setSelectedStartDate(formattedUserDate);
            setCustomStart(utcStartDate.toISOString());
        }
    };
    const handleCustomEndOnChange = (date) => {
        const userTimeZone = currentUser.TimeZone;
        if (date) {
            const options = {
                userTimeZone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            };
            // Use `toLocaleString` to get the datetime in the specified timezone
            const formattedEndDate = new Intl.DateTimeFormat('en-US', options).format(date);
            console.log('Selected Date in Specified Timezone:', formattedEndDate);
            // Convert the formatted datetime to a UTC datetime string

            const utcEndDate = new Date(
                formattedEndDate.toLocaleString('en-US', { timeZone: 'UTC' })
            );

            console.log('Local End Date:', formattedEndDate.toString());
            console.log('Converted UTC End Date:', utcEndDate.toISOString());
            // Split the input into date and time parts
            const [datePart, timePart] = formattedEndDate.split(", ");

            // Rearrange the date part from MM/DD/YYYY to YYYY-MM-DD
            const [month, day, year] = datePart.split("/");
            const formattedUserDate = `${year}-${month}-${day} ${timePart}`;
            setSelectedEndDate(formattedUserDate);
            setCustomEnd(utcEndDate.toISOString());
        }
    };
    return (
        <>
            <Header setModalType={setModalType} />
            <SideBar onSidebarItemClick={handleSidebarItemClick} />
            <div className="app-container">
                <main id="main" className="main">
                    <PageTitle title={"Overall Message"} subtitle={"Messages"} />
                    <AllModals modalType={modalType} handleClose={handleClose} setModalType={setModalType} />
                    <div className="monitoring-page">

                    </div>
                    <div className="table-top-right">
                        <div className="left-align">
                            <div className="time-range-selector">
                                <label>
                                    Time:{' '}
                                    <select value={timeRange} onChange={handleOnChangeTimeRange}>
                                        <option value="last60Minutes">Last 60 Minutes</option>
                                        <option value="last24Hours">Last 24 Hours</option>
                                        <option value="lastWeek">Last Week</option>
                                        <option value="last30Days">Last 30 Days</option>
                                        {/* <option value="thisMonth">This Month</option> */}
                                        <option value="custom">Custom</option>
                                    </select>
                                </label>
                            </div>
                            {showCustomDatePicker ? (
                                <>
                                    Start: &nbsp;
                                    <div className="custom-calendar-divtag rounded bg-white">
                                        <DatePicker
                                            selected={selectedStartDate ? new Date(selectedStartDate) : null}
                                            onChange={handleCustomStartOnChange}
                                            customInput={
                                                <div className="custom-calendar-datepicker d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        className="text-center w-100 custom-calendar-input-field"
                                                        placeholder="Select start date time"
                                                        value={selectedStartDate || ''}
                                                        readOnly
                                                    />
                                                    <span className="custom-calendar-icon ms-2"><i className="bi bi-calendar2-range-fill"></i></span>
                                                </div>
                                            }
                                            showTimeSelect
                                            dateFormat="Pp"
                                        />
                                    </div>
                                    &nbsp;&nbsp;End: &nbsp;
                                    <div className="custom-calendar-divtag rounded bg-white">
                                        <DatePicker
                                            selected={selectedEndDate ? new Date(selectedEndDate) : null}
                                            onChange={handleCustomEndOnChange}
                                            customInput={
                                                <div className="custom-calendar-datepicker d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        className="text-center w-100 custom-calendar-input-field"
                                                        placeholder="Select end date time"
                                                        value={selectedEndDate || ''}
                                                        readOnly
                                                    />
                                                    <span className="custom-calendar-icon ms-2"><i className="bi bi-calendar2-range-fill"></i></span>
                                                </div>
                                            }
                                            showTimeSelect
                                            dateFormat="Pp"
                                        />
                                    </div>
                                </>
                                // <div className="custom-date-picker">
                                //     <label>
                                //         From:  &nbsp;
                                //         <input
                                //             type="datetime-local"
                                //             step="1" // Allows seconds selection
                                //             value={calendarStart}
                                //             onChange={handleCustomStartChange}
                                //             max={getCurrentCalendarLocalDateTime("UTC+3:00")}
                                //         />
                                //     </label>
                                //     <label>
                                //         To:  &nbsp;<input
                                //             type="datetime-local"
                                //             step="1" // Allows seconds selection
                                //             value={calendarEnd}
                                //             onChange={handleCustomEndChange}
                                //             max={getCurrentCalendarLocalDateTime("UTC+3:00")}
                                //         />
                                //     </label>
                                // </div>
                            ) : (
                                <div className="selected-range">
                                    {calculatedTimeRange.startLocal && calculatedTimeRange.endLocal && (
                                        <p><b>Start</b>: {formatUTCTimestamp(calculatedTimeRange.startLocal)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>End</b>: {formatUTCTimestamp(calculatedTimeRange.endLocal)}</p>
                                    )}
                                </div>)}
                            {/* bi bi-search <button className="global-button btn-lg" onClick={handleConfirmClick} ><i className='bi bi-check'></i></button> */}
                            <i className="bi bi-search custom-confirm-icon"
                                onClick={handleConfirmClick}></i>

                        </div>

                    </div>
                    {isTableLoading ? (<div className="tab-panel">
                        <div className="w-100 p-4 shadow rounded bg-light destination-tb-custom-border"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <span
                                className="spinner-border spinner-border-lg text-primary"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '10px' }}
                            ></span></div></div>) : (<>
                                <table {...getTableProps()} className="monitoring-table">
                                    <thead>
                                        {/* Filter Row */}
                                        <tr className="filter-row">
                                            <th className="column-header">
                                                <div className="customsearch-wrapper">
                                                    <div className="customsearch-input-container">
                                                        <i className="bi bi-search customsearch-icon"></i>
                                                        <Select
                                                            options={getUniqueOptions('PackageName')}
                                                            value={packageNameFilter}
                                                            onChange={handleFilterChange('packageName', setPackageNameFilter)}
                                                            placeholder="Package Name"
                                                            className="customsearch-input"
                                                            components={customComponents}
                                                            styles={{
                                                                control: (base) => ({
                                                                    ...base,
                                                                    border: 'none', // Removes border
                                                                    boxShadow: 'none', // Removes shadow
                                                                    height: '20px',        // Reduces the height
                                                                    minHeight: '20px',
                                                                    alignContent: 'center'
                                                                }),

                                                            }}
                                                        />
                                                    </div>
                                                    <i
                                                        className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                        onClick={() => handleSort('PackageName')} // Pass the key to sort
                                                        style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                    ></i>
                                                </div>
                                            </th>
                                            <th className="column-header">
                                                <div className="customsearch-wrapper">
                                                    <div className="customsearch-input-container">
                                                        <i className="bi bi-search customsearch-icon"></i>
                                                        <Select
                                                            options={getUniqueOptions('IFlowName')}
                                                            value={iFlowNameFilter}
                                                            onChange={handleFilterChange('iFlowName', setIFlowNameFilter)}
                                                            placeholder="IFlow Name"
                                                            className="customsearch-input"
                                                            components={customComponents}
                                                            styles={{
                                                                control: (base) => ({
                                                                    ...base,
                                                                    border: 'none', // Removes border
                                                                    boxShadow: 'none', // Removes shadow
                                                                    height: '20px',        // Reduces the height
                                                                    minHeight: '20px',
                                                                    alignContent: 'center'
                                                                }),

                                                            }}
                                                        />
                                                    </div>
                                                    <i
                                                        className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                        onClick={() => handleSort('IFlowName')} // Pass the key to sort
                                                        style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                    ></i>
                                                </div>

                                            </th>
                                            <th className="column-header">
                                                <div className="customsearch-wrapper">
                                                    <div className="customsearch-input-container">
                                                        <i className="bi bi-search customsearch-icon"></i>
                                                        <Select
                                                            options={getUniqueOptions('Sender')}
                                                            value={senderFilter}
                                                            onChange={handleFilterChange('senderName', setSenderFilter)}
                                                            placeholder="Sender"
                                                            className="customsearch-input"
                                                            components={customComponents}
                                                            styles={{
                                                                control: (base) => ({
                                                                    ...base,
                                                                    border: 'none', // Removes border
                                                                    boxShadow: 'none', // Removes shadow
                                                                    height: '20px',        // Reduces the height
                                                                    minHeight: '20px',
                                                                    alignContent: 'center'
                                                                }),

                                                            }}
                                                        />
                                                    </div>
                                                    <i
                                                        className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                        onClick={() => handleSort('Sender')} // Pass the key to sort
                                                        style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                    ></i>
                                                </div>

                                            </th>
                                            <th className="column-header">
                                                <div className="customsearch-wrapper">
                                                    <div className="customsearch-input-container">
                                                        <i className="bi bi-search customsearch-icon"></i>
                                                        <Select
                                                            options={getUniqueOptions('Receiver')}
                                                            value={receiverFilter}
                                                            onChange={handleFilterChange('receiverName', setReceiverFilter)}
                                                            placeholder="Receiver"
                                                            className="customsearch-input"
                                                            components={customComponents}
                                                            styles={{
                                                                control: (base) => ({
                                                                    ...base,
                                                                    border: 'none', // Removes border
                                                                    boxShadow: 'none', // Removes shadow
                                                                    height: '20px',        // Reduces the height
                                                                    minHeight: '20px',
                                                                    alignContent: 'center'
                                                                }),

                                                            }}
                                                        />
                                                    </div>
                                                    <i
                                                        className={`bi ${sortOrder === 'asc' ? 'bi-sort-up-alt' : 'bi-sort-down'} custom-sort-icon`}
                                                        onClick={() => handleSort('Receiver')} // Pass the key to sort
                                                        style={{ cursor: 'pointer' }} // Pointer cursor for clickable icons
                                                    ></i>
                                                </div>

                                            </th>
                                            <th className="column-header">
                                                {/* <i class="bi bi-repeat"></i><i class="bi bi-arrow-repeat"></i> */}
                                                <i
                                                    className="bi bi-repeat custom-repeat-icon"
                                                    title="Reset Filter"
                                                    // style={{ cursor: "pointer", fontSize: "1.2rem", width: "1rem", height: "1rem", padding: "0.2rem", }}
                                                    onClick={handleResetFilters}
                                                ></i>

                                            </th>
                                        </tr>

                                        {/* Column Headers Row */}
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps()} key={column.id} className="column-header" style={{ width: column.width }}>
                                                        {column.render('Header')}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody {...getTableBodyProps()}>
                                        {page.length === 0 ? (
                                            <tr>
                                                <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                                    No data found.
                                                </td>
                                            </tr>
                                        ) : (
                                            page.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr
                                                        {...row.getRowProps()}
                                                        key={row.id}
                                                        className={`custom-row-height ${i % 2 === 0 ? 'row-grey' : 'row-white'}`}
                                                    >
                                                        {row.cells.map((cell) => (
                                                            <td {...cell.getCellProps()} key={cell.column.id}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                                <div className="details-table-top-left">
                                    {/* Pagination controls */}
                                    <div className="details-pagination">

                                        <div className="pagination-controls">
                                            <button onClick={() => gotoPage(1)} disabled={currentPage === 1}>
                                                <i className="fas fa-angle-double-left"></i>
                                            </button>
                                            <button onClick={() => gotoPage(currentPage - 1)} disabled={currentPage === 1}>
                                                <i className="fas fa-angle-left"></i>
                                            </button>
                                            <span>
                                                Page{' '}
                                                {currentPage} of {totalPages}
                                            </span>
                                            <button onClick={() => gotoPage(currentPage + 1)} disabled={currentPage === totalPages}>
                                                <i className="fas fa-angle-right"></i>
                                            </button>
                                            <button onClick={() => gotoPage(totalPages)} disabled={currentPage === totalPages}>
                                                <i className="fas fa-angle-double-right"></i>
                                            </button>
                                            {/* Input Box and Go Button */}
                                            <div className="pagination-goto-page">
                                                <input
                                                    type="number"
                                                    min="1"
                                                    max={totalPages}
                                                    value={gotoInputValue}
                                                    onChange={(e) => setGotoInputValue(e.target.value)}
                                                    placeholder="Go to"
                                                    className="pagination-goto-input"
                                                />
                                                <button
                                                    onClick={() => gotoPage(Number(gotoInputValue))}
                                                    disabled={!gotoInputValue || gotoInputValue < 1 || gotoInputValue > totalPages}
                                                    className="pagination-goto-button"
                                                >
                                                    Go
                                                </button>

                                            </div>
                                        </div>
                                        <div className="details-page-size-selector">
                                            <label>
                                                <select value={localPageSize} onChange={handlePageSizeChange}>
                                                    {[25, 50, 75, 100].map(size => (
                                                        <option key={size} value={size}>
                                                            {size}
                                                        </option>
                                                    ))}
                                                </select>{' '}
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </>
                    )}

                </main >
            </div >
            <Footer />
            <BackToTop />
        </>

    )
}

export default MonitoringOverall