import React, { useEffect, useState } from 'react'
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import { useAlert } from '../../ProHelpers/AlertContext';
import defaultuserImage from '../../Resource/logo.png';
import { useTable, usePagination } from 'react-table';
import AllModals from '../../ProHelpers/AllModals';

function BrandingFeatures() {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const [logoPreview, setLogoPreview] = useState('');
    const { showAlert } = useAlert();
    const [companyLogo, setCompanyLogo] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");
    const [showModal, setShowModal] = useState(false);
    //const customerAddress = sessionStorage.getItem('CustomerAddress');
    const customerID = sessionStorage.getItem('CustomerID');
    const EmailID = sessionStorage.getItem('EmailID');
    const FirstName = sessionStorage.getItem('FirstName');
    const UserName = sessionStorage.getItem('UserName');
    const AccountType = sessionStorage.getItem('AccountType');
    const CustomerImage = sessionStorage.getItem('CustomerImage');

    const [billingAddress, setBillingAddress] = useState(null);
    const [companyImage, setCompanyImage] = useState(null);
    const [isEditable, setIsEditable] = useState(false); // Toggle between editable and read-only
    const [isLoading, setIsLoading] = useState(false); // Spinner state
    const [currentUser, setcurrentUser] = useState([]); // Spinner state
    const [issaveFeature, setIsSaveFeature] = useState(false);
    const [isEdit, setIsEdit] = useState(null);
    const [isDisplay, setIsDisplay] = useState(null);
    const editValue = 1;
    const [modalType, setModalType] = useState(null);

    const handleClose = () => setModalType(null);
    useEffect(() => {
        const records = JSON.parse(sessionStorage.getItem('Records'));
        setcurrentUser(records[0]);
        console.log("Session Data Array: ", records[0]);
        //console.log("Session Data: ", records[0].AccountType);
        const address = sessionStorage.getItem("CustomerAddress");
        const logo = sessionStorage.getItem("CompanyLogo");
        //setBillingAddress(records[0].CustomerAddress);
        setBillingAddress(address);
        //const logo = records[0].CompanyLogo; // Assuming you store the profile picture URL in sessionStorage
        if (!logo) {
            setCompanyLogo(defaultuserImage); // Set the default image if profilePicture is null or empty
        } else {
            const base64Image = logo.startsWith("data:image/") ? logo : `data:image/png;base64,${logo}`;
            //console.log("Base64 Image:", base64Image)
            setCompanyLogo(base64Image); // Set the base64 image string
        }
        // Initialize checkbox state
        //console.log('OnLoad Session Data:', records[0]);
        console.log("Page Onload Edit: ", records[0].PayloadEdit, "Display: ", records[0].PayloadView);
    }, []);

    const handleCancelEdit = () => {
        setBillingAddress(billingAddress); // Revert to original time zone
        setIsEditable(false);
    };

    const handleChange = (event) => {
        setBillingAddress(event.target.value);
        // console.log("Selected TimeZone:", event.target.value);
    };


    const handleSave = () => {

        setIsLoading(true);
        const updatedData = {
            ActionType: "update_company_profile",
            CustomerID: sessionStorage.getItem('CustomerID'),
        };
        // Dynamically add SystemTimeZone if available
        if (billingAddress) {
            updatedData.CustomerAddress = billingAddress;
        }
        if (companyImage) {
            updatedData.CompanyLogo = companyImage;
        }
        console.log('Requesting Body on Save:', updatedData);
        updateUserProfileData(updatedData);

    };

    const handleEdit = () => {
        setIsEditable(true); // Enable the dropdown for editing
    };

    const handlerSaveFeature = () => {
        //console.log("isDisplay: ", isDisplay, "isEdit: ", isEdit);

        setIsSaveFeature(true); // Enable the dropdown for editing
        const featureData = {
            ActionType: "update_feature",
            CustomerID: currentUser.CustomerID, //sessionStorage.getItem('CustomerID'),
        };

        if (!isDisplay) {
            featureData.PayloadView = "0";
            featureData.PayloadEdit = "0";
        } else if (isDisplay || currentUser.PayloadView === 1) {
            featureData.PayloadView = isDisplay ? "1" : "0";
            featureData.PayloadEdit = isEdit ? "1" : "0";
        }


        // if (isDisplay) {
        //     featureData.CompanyLogo = 1;
        // }
        updateFeatureDataAPI(featureData);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // Validate file format
            const validFormats = ["image/jpeg", "image/jpg", "image/png"];
            if (!validFormats.includes(file.type)) {
                setTimeout(() => showAlert("Invalid file format. Please upload a JPG, JPEG, or PNG image.", 'warning'), 0);
                return;
            }

            // Validate file size (max 200KB)
            const maxSizeInKB = 200;
            if (file.size > maxSizeInKB * 1024) {
                setTimeout(() => showAlert("File size exceeds 200KB. Please upload a smaller image.", 'warning'), 0);
                return;
            }

            // File is valid
            // Convert the image file to base64 and update the state
            const reader = new FileReader();

            reader.onloadend = () => {
                // reader.result is the base64-encoded string
                const base64Image = reader.result.split(',')[1]; // Remove the prefix "data:image/jpeg;base64,"

                // Update the profile image preview
                setCompanyLogo(reader.result);

                // Enable edit mode
                setIsEditable(true);

                // Store the base64-encoded image in the state
                setCompanyImage(base64Image);
            };

            // Read the file as a data URL (base64 encoded string)
            reader.readAsDataURL(file);
        }
    };


    const updateFeatureDataAPI = async (data) => {
        //alert(`Data saved successfully! Here is the form data:\n${JSON.stringify(data, null, 2)}`);
        try {
            const requestBody = data;
            console.log('Requesting Body:', requestBody);
            const response = await AxiosInstance.post('/manage-resource', requestBody);
            console.log('Response Body:', response);
            if (response.data.status == 'success') {
                setTimeout(() => showAlert("Successgully Updated", 'success'), 0);
                const records = JSON.parse(sessionStorage.getItem('Records'));
                if (isDisplay || Number(records[0].PayloadView) === 1) {
                    records[0] = {
                        ...records[0],
                        PayloadView: requestBody.PayloadView, // or some other modification based on your needs
                        PayloadEdit: requestBody.PayloadEdit, // or some other modification based on your needs
                    };
                } else {
                    records[0] = {
                        ...records[0],
                        PayloadView: 0, // or some other modification based on your needs
                        PayloadEdit: 0, // or some other modification based on your needs
                    };
                }
                console.log('Updated Session Array:', records);
                // Store the updated array back into session storage
                sessionStorage.setItem('Records', JSON.stringify(records));
                setcurrentUser(records[0]);
            } else {
                setTimeout(() => showAlert("Failed to Update", 'failure'), 0);
            }
        } catch (err) {
            setIsSaveFeature(false);
            console.error("Error:", err); // This will log the full error object
        } finally {
            setIsSaveFeature(false);
        }
    };

    const updateUserProfileData = async (data) => {
        //alert(`Data saved successfully! Here is the form data:\n${JSON.stringify(data, null, 2)}`);
        try {
            const requestBody = data;
            console.log('Requesting Body:', requestBody);
            const response = await AxiosInstance.post('/manage-resource', requestBody);
            console.log('Response Body:', response);
            if (response.data.status == 'success') {
                setTimeout(() => showAlert("Successgully Updated", 'success'), 0);
                setIsEditable(false);
                const records = JSON.parse(sessionStorage.getItem('Records'));
                // records[0] = {
                //     ...records[0],
                //     CustomerAddress: requestBody.CustomerAddress, // or some other modification based on your needs
                // };
                // if (companyImage) {
                //     records[0] = {
                //         ...records[0],
                //         CompanyLogo: requestBody.CompanyLogo, // or some other modification based on your needs
                //     }
                // }
                // console.log('Updated Session Array:', records);
                // // Store the updated array back into session storage
                // sessionStorage.setItem('Records', JSON.stringify(records));
                // setcurrentUser(records[0]);
                sessionStorage.setItem('CustomerAddress', billingAddress);
                if (companyImage) {
                    sessionStorage.setItem('CompanyLogo', companyImage);
                }
            } else {
                setTimeout(() => showAlert("Failed to Update", 'failure'), 0);
            }
        } catch (err) {
            setLoading(false);
            setIsLoading(false);
            console.error("Error:", err); // This will log the full error object
        } finally {
            setLoading(false);
            setIsLoading(false);
        }
    };

    const featureData = [
        {
            name: "Payload",
            edit: "",
            display: "",
        }
        // ,
        // {
        //     name: "PGP",
        //     edit: 2,
        //     display: 2,
        // },
    ];

    const columns = React.useMemo(
        () => [
            {
                Header: "Feature",
                accessor: "name",
                width: 200,
                style: { textAlign: "center" },
            },
            {
                Header: "Display",
                accessor: "display",
                width: 100,
                style: { textAlign: "center" },
                Cell: ({ row }) => {
                    const [isChecked, setIsChecked] = useState(Number(currentUser.PayloadView) === 1);
                    const handleDisplayChange = (e) => {
                        const isCheck = e.target.checked;
                        // console.log(`Edit Checkbox for row ${row.index}:`, isChecked ? 1 : 0);
                        // Update the currentUser data or call an API as needed
                        setcurrentUser((prevState) => ({
                            ...prevState,
                            PayloadView: isCheck ? 1 : 0,
                            PayloadEdit: isCheck ? 1 : 0,
                        }));
                        row.original.display = isCheck ? 1 : 0;
                        setIsDisplay(isCheck);
                        setIsChecked(isCheck);
                        setIsEdit(isCheck);
                        console.log("OnClick Display Edit: ", currentUser.PayloadEdit, "Display: ", isCheck);
                    };

                    return (
                        <div className="d-flex">
                            <label className="feature-switch">
                                <input
                                    type="checkbox"
                                    id={`display-toggle-${row.index}`}
                                    checked={isChecked} // Set based on row data
                                    onChange={handleDisplayChange}
                                />
                                <span className="feature-slider"></span>
                            </label>
                        </div>
                    );
                },
            },
            {
                Header: "Edit",
                accessor: "edit",
                width: 100,
                style: { textAlign: "center" },
                Cell: ({ row }) => {
                    const [isChecked, setIsChecked] = useState(Number(currentUser.PayloadEdit) === 1);
                    const [isDisplayChecked, setIsDisplayChecked] = useState(Number(currentUser.PayloadView) === 1);
                    //console.log('Is Checked:', isChecked);
                    const handleEditChange = (e) => {
                        const isCheck = e.target.checked;
                        // alert("Edit", isCheck);
                        // console.log(`Edit Checkbox for row ${row.index}:`, isChecked ? 1 : 0);
                        // Update the currentUser data or call an API as needed
                        setcurrentUser((prevState) => ({
                            ...prevState,
                            PayloadEdit: isCheck ? 1 : 0,
                        }));
                        row.original.edit = isCheck ? 1 : 0;
                        setIsEdit(isCheck);
                        setIsChecked(isCheck);
                        console.log("OnClick Edit Edit: ", isCheck, "Display: ", currentUser.PayloadView);
                    };
                    return (
                        <div style={{ textAlign: "center" }}>
                            <div className="d-flex">
                                <label className="feature-switch">
                                    <input
                                        type="checkbox"
                                        id={`edit-toggle-${row.index}`}
                                        checked={isChecked} // Set based on row data
                                        disabled={!isDisplayChecked}
                                        style={{
                                            cursor: !isDisplayChecked ? "not-allowed" : "pointer", // Add not-allowed cursor
                                            background: "black"
                                        }}
                                        onChange={handleEditChange}
                                    />
                                    <span className="feature-slider"></span>
                                </label>
                            </div>
                        </div>
                    );
                },
            },

        ],
        [currentUser]
    );

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow } =
        useTable({
            columns,
            data: featureData,
        });

    return (

        <>
            <Header setModalType={setModalType} />
            <SideBar />
            <div className="app-container">
                <main id="main" className="main">
                    <AllModals modalType={modalType} handleClose={handleClose} setModalType={setModalType} />
                    <PageTitle title={"Settings"} subtitle={"Branding and Features"} />
                    <>
                        <div className='row'>
                            <div className="col-md-7 p-4 rounded profile-custom-container">
                                {/* Profile Image and Full Name */}
                                <div className="d-flex align-items-center">
                                    <div className="profile-container position-relative" style={{ width: "80px", height: "80px", cursor: "pointer" }}>
                                        {/* Profile Image */}
                                        <label htmlFor="profileImageInput" style={{ cursor: "pointer" }}>
                                            <img
                                                src={companyLogo}
                                                alt="Profile"
                                                className="rounded-circle"
                                                width="75"
                                                height="75"
                                                style={{
                                                    transition: "transform 0.3s",
                                                    objectFit: 'scale-down', // Ensures the image fits inside the circle
                                                    borderRadius: '50%', // Ensures the container is circular
                                                    display: 'block', // Prevents unwanted spacing below the image
                                                }}
                                            />
                                        </label>
                                        {/* Hidden File Input */}
                                        <input
                                            type="file"
                                            id="profileImageInput"
                                            className="d-none"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                        />
                                    </div>

                                    <div className="text-start ms-3">
                                        <h4 style={{ marginBottom: "0.2rem", lineHeight: "0.5" }}>{currentUser.CustomerName}</h4>
                                        <small className="text-muted">Click to update your profile picture</small>
                                    </div>

                                </div>
                                {/* Company Profile Details */}
                                <div className="row text-align-start">
                                    <div className="row mt-1 custom-alignment">
                                        <div className="col-md-3 text-end">Email :</div>
                                        <div className="col-md-9 text-start">{currentUser.EmailID}</div>
                                    </div>
                                    <div className="row mt-1 custom-alignment">
                                        <div className="col-md-3 text-end">Created on :</div>
                                        <div className="col-md-9 text-start">{currentUser.CreatedOn}</div>
                                    </div>
                                    <div className="row mt-1 custom-alignment">
                                        <div className="col-md-3 text-end">Billing cycle :</div>
                                        <div className="col-md-9 text-start">Annually</div>
                                    </div>
                                    <div className="row mt-1 custom-alignment">
                                        <div className="col-md-3 text-end">Billing address :</div>
                                        <div className="col-md-9 text-start position-relative">
                                            <textarea
                                                className='branding-billing-address'
                                                aria-label="Billing Address"
                                                onChange={handleChange}
                                                value={billingAddress || ""}
                                                disabled={!isEditable} // Disable if not in edit mode
                                            />
                                            {/* Close icon appears only in edit mode */}
                                            {isEditable && (
                                                <button
                                                    className="close-icon"
                                                    aria-label="Cancel Edit"
                                                    onClick={handleCancelEdit}
                                                    style={{
                                                        //height: "30px", // Adjust the height as needed
                                                        fontSize: "14px", // Optional: Adjust font size for better alignment
                                                        padding: "4px",   // Optional: Adjust padding for a smaller look
                                                        width: "25px",
                                                        height: "25px",
                                                        right: "-1px"
                                                    }}
                                                >
                                                    &times;
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-flex justify-content-end mt-4">
                                            {!isEditable ? (
                                                <button className="btn btn-primary save-edit-fixed-width" onClick={handleEdit}>
                                                    Edit
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary save-edit-fixed-width" onClick={handleSave}>
                                                    {isLoading ? (
                                                        <>
                                                            <span
                                                                className="spinner-border spinner-border-sm me-2"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                            Please wait...
                                                        </>
                                                    ) : (
                                                        "Save Changes"
                                                    )}

                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* Company Features Details */}
                            <div className="col-md-4 ms-2 p-4 rounded profile-custom-container position-relative">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="text-start">
                                        {/* <h4 style={{ marginBottom: "0.2rem", lineHeight: "0.5" }}>Features</h4> */}
                                        <table {...getTableProps()} className="table table-striped table-bordered">
                                            <thead>
                                                {headerGroups.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()} >
                                                        {headerGroup.headers.map((column) => (
                                                            <th {...column.getHeaderProps()} style={{ width: column.width }}>{column.render("Header")}</th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {rows.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                                            No data found.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    rows.map((row) => {
                                                        prepareRow(row);
                                                        return (
                                                            <tr {...row.getRowProps()} key={row.id} style={{
                                                                marginLeft: "50px",
                                                            }}>
                                                                {row.cells.map((cell) => (
                                                                    <td {...cell.getCellProps()} key={cell.column.id} style={{
                                                                        padding: "4px", // Reduce padding
                                                                        lineHeight: "1", // Reduce line-height
                                                                    }}>{cell.render("Cell")}</td>
                                                                ))}
                                                            </tr>
                                                        );
                                                    })
                                                )

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="position-absolute w-100" style={{ bottom: "30px", right: "20px" }}>
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-primary save-edit-fixed-width" onClick={handlerSaveFeature}>
                                            {issaveFeature ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm me-2"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    Please wait...
                                                </>
                                            ) : (
                                                "Save"
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="profile-container position-relative" style={{ width: "100px", height: "100px", cursor: "pointer" }}>

                                    </div> */}
                                {/* <div className="d-flex justify-content-between m-4">
                                           
                                            <div className="d-flex flex-column text-end me-4">
                                                <label htmlFor="toggle1" style={{ marginBottom: "15px" }}>Payload Edit</label>
                                                <label htmlFor="toggle2">Payload Display</label>
                                            </div>

                                          
                                            <div className="d-flex flex-column">
                                                <div style={{ marginBottom: "10px" }}>
                                                    <label className="feature-switch">
                                                        <input type="checkbox" id="toggle1" />
                                                        <span className="feature-slider"></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className="feature-switch">
                                                        <input type="checkbox" id="toggle2" />
                                                        <span className="feature-slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div> */}

                            </div>
                        </div>
                    </>
                </main>
            </div >
            <Footer />
            <BackToTop />
        </>

    )
}

export default BrandingFeatures




