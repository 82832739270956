import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import './EditMessage.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import AllModals from '../../ProHelpers/AllModals';
import AxiosInstance from '../RestApi/AxiosInstance';
import { useAlert } from '../../ProHelpers/AlertContext';
import { Modal, Button, Form } from 'react-bootstrap';

function EditMessagePayload() {
    const EmailID = sessionStorage.getItem('EmailID');
    const CustomerID = sessionStorage.getItem('CustomerID');
    const [responseData, setResponseData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [textAreaValue, setTextAreaValue] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [textareaValue, setTextareaValue] = useState("");
    const [showMessageUpdateModal, setshowMessageUpdateModal] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");


    const [modalType, setModalType] = useState(null);
    const handleClose = () => setModalType(null);

    const [isSearching, setIsSearching] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    const [isShowEditable, setIsShowEditable] = useState('hide');
    const { showAlert } = useAlert();


    const { messageID } = useParams();
    const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value);
    };


    // Extract searchQuery from the address bar when the component mounts
    useEffect(() => {
        if (messageID) {
            setSearchQuery(messageID);
            setIsShowEditable('show');
            fetchDataAPI(messageID); // Automatically fetch data if `searchQuery` is in the URL
        }
    }, [messageID]);


    // Decode the initial data when available
    React.useEffect(() => {
        if (responseData[0]?.Payload) {
            console.log("Data on response UseEfect: ", responseData[0]?.Payload);
            setTextareaValue(atob(responseData[0]?.Payload)); // Decode base64 data
        }
    }, [responseData[0]?.Payload]);


    const handleEdit = () => {
        setIsEditable(true); // Enable the dropdown for editing
    };

    const handleCancelEdit = () => {
        setIsEditable(false);
    };

    const handleSave = () => {
        setshowMessageUpdateModal(true);

    };

    const handleMessageSaveAPI = () => {
        setIsSaving(true);
        if (confirmationText === "Update") {
            const reEncodedPayload = btoa(textareaValue); // Convert the text back to Base64
            //console.log("Re-encoded Payload:", reEncodedPayload);
            saveMessageAPI(reEncodedPayload);
        } else {
            setIsSaving(false);
            setConfirmationText("");
        }
    }
    // Function to handle input change and capture the search value
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    //Trigger search on When clicked on search icon.
    const handleSearchMessage = () => {
        if (searchQuery.trim()) {
            setIsShowEditable('show');
            fetchDataAPI(searchQuery);
        } else {
            console.log('No search query provided.');
        }
    }

    const handleCloseModal = () => {
        setshowMessageUpdateModal(false);
    };

    // Trigger search on Enter key press.
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchMessage();
        }
    };

    const fetchDataAPI = async (searchQuery) => {
        setIsSearching(true);
        setTextareaValue("");
        const requestBody = {
            'DetailsPage': 'editpayload',
            'CustomerID': CustomerID,
            'MessageID': searchQuery,
        };
        try {
            console.log("Request Body:", requestBody);
            const response = await AxiosInstance.post('/failure-message', requestBody);
            console.log('Response Data:', response.data);

            if (response.status === 200) {
                setResponseData(response.data.message);
            } else {
                console.log('Else Message:', response.data.message);
                setResponseData([]);
            }
        } catch (err) {
            setResponseData([]);
        } finally {
            setIsSearching(false);
        }
    };


    const saveMessageAPI = async (enData) => {

        const requestBody = {
            'DetailsPage': 'savepayload',
            'CustomerID': CustomerID,
            'MessageID': searchQuery,
            'Payload': enData,
        };
        try {
            console.log("Request Body:", requestBody);
            const response = await AxiosInstance.post('/failure-message', requestBody);
            console.log('Response Data:', response.data);
            setTimeout(() => showAlert(response.data.message, response.data.status), 0);
            if (response.status === 200) {
                console.log('Message Response Data:', response.data.message);
                setResponseData(response.data.message);
                setshowMessageUpdateModal(false);
            } else {
                console.log('Else Message:', response.data.message);
                setResponseData([]);
            }
        } catch (err) {

            setResponseData([]);
            setConfirmationText("");
        } finally {
            setIsSaving(false);
            setIsEditable(false);
            setConfirmationText("");
        }
    };


    return (
        <>
            <Header setModalType={setModalType} />
            <SideBar />
            <div className="app-container">
                <main id="main" className="main">
                    <PageTitle title={"Edit Payload"} subtitle={"Edit Payload"} />
                    <AllModals modalType={modalType} handleClose={handleClose} setModalType={setModalType} />
                    <Modal className="custom-modal-end" show={showMessageUpdateModal} onHide={handleCloseModal} centered backdrop="static" keyboard={false}>
                        <div style={{ position: 'relative' }}>
                            <button
                                className="custom-modal-close-btn m-1"
                                onClick={handleCloseModal}
                                aria-label="Close"
                            >
                                &times; {/* Close icon */}
                            </button>
                        </div>
                        <Modal.Header>
                            <Modal.Title>Confirm Save Message</Modal.Title>
                        </Modal.Header>
                        {/* Two-column layout */}
                        <Modal.Body className="custom-modal-body" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '20px', // Optional, for spacing
                        }}>
                            <Form>
                                <Form.Group controlId="confirmationText">
                                    {searchQuery && (  // Check if SystemName is not null or empty
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundColor: '#f8d7da',
                                            padding: '20px',
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                            alignItems: 'center', // Center items horizontally
                                            justifyContent: 'center', // Center items vertically
                                            gap: '10px', // Add spacing between elements
                                        }}>

                                            <div className="row">
                                                <h5 style={{ fontSize: "22px", marginBottom: "1px", marginTop: "1px" }}>Do you want to update message ?</h5>
                                                <p style={{ fontSize: "13px", marginBottom: "1px", marginTop: "1px" }}>Message ID : {searchQuery}</p>
                                                <h6>Please type 'Update' to confirm</h6>
                                                <Form.Control
                                                    style={{
                                                        width: '300px',
                                                        marginLeft: '70px',
                                                    }}
                                                    type="text"
                                                    placeholder="Enter Update to confirm"
                                                    value={confirmationText}
                                                    onChange={(e) => setConfirmationText(e.target.value)}
                                                />
                                            </div>

                                        </div>
                                    )}
                                </Form.Group>
                            </Form>

                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal} style={{
                                padding: '5px 15px',
                                fontSize: '14px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}>
                                Close
                            </Button>
                            <Button variant="primary"
                                disabled={confirmationText !== "Update"}
                                onClick={handleMessageSaveAPI}
                                style={{
                                    padding: '5px 15px',
                                    fontSize: '14px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                }}>
                                {isSaving ? (
                                    <>
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        Please wait...
                                    </>
                                ) : (
                                    "Confirm"
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <>
                        <div className='row'>
                            <div className="col-md-10 p-4 rounded profile-custom-container" style={{ height: '60%' }}>
                                {/* Search Bar */}
                                <div className="mb-3 search-editmessage-container">
                                    <input
                                        type="text"
                                        placeholder="Type message id to search..."
                                        className="search-editmessage-input"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        onKeyDown={handleKeyDown}
                                        style={{
                                            paddingRight: '30px', // Add padding on the right to make space for the icon
                                        }}
                                    />
                                    <i className="search-editmessage-icon bi bi-search" style={{ fontSize: '15px' }} onClick={handleSearchMessage}></i> {/* Search icon */}
                                </div>
                                {isShowEditable === 'show' && (
                                    <>
                                        {isSearching ? (
                                            <div className="tab-panel">
                                                <div
                                                    className="w-100 p-4 shadow rounded bg-light destination-tb-custom-border"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <span
                                                        className="spinner-border spinner-border-lg text-primary"
                                                        role="status"
                                                        aria-hidden="true"
                                                        style={{ marginRight: '10px' }}
                                                    ></span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {responseData && Object.keys(responseData).length > 0 ? (
                                                    <>
                                                        {/* Label and ReadOnly Input Box */}
                                                        <label
                                                            htmlFor="readonlyInput"
                                                            style={{
                                                                fontSize: '16px',
                                                                marginBottom: '5px',
                                                                color: '#808080',
                                                            }}
                                                        >
                                                            Application ID
                                                        </label>

                                                        <div className="mb-3">
                                                            <input
                                                                type="text"
                                                                value={responseData[0]?.ApplicationID}
                                                                placeholder="This will be application id"
                                                                className="search-editmessage-input"
                                                                readOnly
                                                                style={{
                                                                    width: '40%', // Decreased width
                                                                    padding: '10px',
                                                                    fontSize: '15px',
                                                                    height: '35px',
                                                                    borderRadius: '5px',
                                                                    border: '1px solid #ccc',
                                                                    backgroundColor: '#ccc',
                                                                    color: '#555',
                                                                    marginTop: '5px',
                                                                    cursor: 'not-allowed',
                                                                }}
                                                            />
                                                        </div>
                                                        {/* Label and ReadOnly Textarea */}
                                                        <label
                                                            htmlFor="readonlyTextarea"
                                                            style={{
                                                                fontSize: '16px',
                                                                marginBottom: '5px',
                                                                color: '#808080',
                                                            }}
                                                        >
                                                            Payload Message
                                                        </label>

                                                        <div className="mb-3">
                                                            <textarea
                                                                value={textareaValue}
                                                                onChange={handleTextareaChange}
                                                                placeholder="This will be the payload"
                                                                className="search-editmessage-input"
                                                                readOnly={!isEditable}
                                                                style={{
                                                                    width: '100%', // Decreased width
                                                                    padding: '10px',
                                                                    fontSize: '15px',
                                                                    height: '210px', // Adjust height for textarea
                                                                    borderRadius: '5px',
                                                                    border: '1px solid #ccc',
                                                                    backgroundColor: '#f7f7f7',
                                                                    color: '#555',
                                                                    marginTop: '5px',
                                                                    backgroundColor: isEditable ? '#fff' : '#ccc', // Change background when editable
                                                                    cursor: isEditable ? 'text' : 'not-allowed', // Change cursor when editable
                                                                    resize: 'none', // Prevent resizing of the textarea
                                                                    overflowY: 'auto',
                                                                }}
                                                            />
                                                        </div>

                                                        {/* Buttons at the bottom right */}
                                                        <div
                                                            className="text-end"
                                                            style={{ marginTop: '15px' }}
                                                        >
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                onClick={handleCancelEdit}
                                                                style={{
                                                                    padding: '5px 15px',
                                                                    fontSize: '14px',
                                                                    marginRight: '10px', // Spacing between buttons
                                                                    borderRadius: '5px',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                            {!isEditable ? (
                                                                <button
                                                                    className="btn btn-primary save-edit-fixed-width"
                                                                    onClick={handleEdit}
                                                                    style={{
                                                                        padding: '5px 15px',
                                                                        fontSize: '14px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    Edit
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="btn btn-primary save-edit-fixed-width"
                                                                    onClick={handleSave}
                                                                    style={{
                                                                        padding: '5px 15px',
                                                                        fontSize: '14px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    Save Changes
                                                                </button>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            padding: '20px',
                                                            fontSize: '16px',
                                                            color: '#999',
                                                        }}
                                                    >
                                                        No data found
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                            </div>

                        </div>
                    </>

                </main>
            </div>
            <Footer />
            <BackToTop />
        </>
    )
}

export default EditMessagePayload