import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import Link for navigation
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import userRegistration from '../../../Resource/user-registration.png'
import Logo from '../../../ProHelpers/Logo'
import loginImage from '../../../Resource/loginImage.png'; // Add your image here
import { useAlert } from '../../../ProHelpers/AlertContext';
import openEye from '../../../Resource/openeye-bg.png';
import closeEye from '../../../Resource/closeeye-bg.png';
import { hashPassword } from '../../../ProHelpers/AuthUtils';
import AxiosInstance from '../../../Components/RestApi/AxiosInstance';

function UserRegistrationPage() {
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();
    const { status, message, reason } = state || {};
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const { showAlert } = useAlert();
    console.log("complete-user-registration Data: ", status, " --message-- ", message, " --reason-- ", reason);
    const [formData, setFormData] = useState({
        FirstName: "",
        LastName: "",
        Password: "",
        ConfirmPassword: "",
        ActivationCode: "",
    });
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const passwordRequirements = (
        <div className="password-requirements">
            <p>Password must include:</p>
            <ul>
                <li>At least 8 characters</li>
                <li>One uppercase letter</li>
                <li>One lowercase letter</li>
                <li>One number</li>
                <li>One special character</li>
            </ul>
        </div>
    );
    // Function to validate password
    const validatePassword = (password) => {
        // Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        return pattern.test(password);
    };

    const validateFormData = () => {

        if (!formData.FirstName.trim()) {
            setTimeout(() => showAlert("First name is required", 'failure'), 0);
            return false;
        }
        if (!formData.LastName.trim()) {
            setTimeout(() => showAlert("First name is required", 'failure'), 0);
            return false;
        }
        if (!formData.Password.trim()) {
            setTimeout(() => showAlert("Password is required", 'failure'), 0);
            return false;
        }
        if (!validatePassword(formData.Password)) {
            setTimeout(() => showAlert(passwordRequirements, 'warning'), 0);
            return false;
        }
        if (formData.Password !== formData.ConfirmPassword) {
            setTimeout(() => showAlert('Oops! Looks like the Confirm Passwords You Entered Don\'t Match. Try again?', 'warning'), 0);
            return false;
        }
        return true;

    };

    const isButtonDisabled = () => {
        return !isTermsChecked || !validateFormData();
    };

    const registerUserDataAPI = async () => {

        try {
            setIsSubmitting(true);
            const hashedPassword = await hashPassword(formData.ConfirmPassword);
            const formDataWithHashedPassword = {
                ...formData,
                ActionType: 'update_user',
                ActivationCode: message.ActivationCode,
                CustomerPassword: hashedPassword,  // Replace plain password with hashed password
            };
            const { Password, ConfirmPassword, ...finalFormData } = formDataWithHashedPassword; // This delete the Password , ConfirmPassword from the formData
            console.log("Request Data: ", finalFormData);

            const response = await AxiosInstance.post('/ResetPassword', finalFormData);
            console.log("Response Data: ", response)

            // setLoading(false);
            setTimeout(() => showAlert(response.data.message, response.data.status), 0);
            if (response.data.status === "success") {
                navigate('/login');
            }

        } catch (err) {
            setIsSubmitting(false);
            console.log('Error fetching server zones:', err); // Log the full error for more details

        } finally {
            setIsSubmitting(false);
            setFormData({
                FirstName: "",
                LastName: "",
                Password: "",
                ConfirmPassword: "",
                ActivationCode: "",
            });
        }
    };

    return (


        <Container
            fluid
            className="d-flex align-items-center justify-content-center vh-100"
            style={{ background: "#b3e5fc" }}
        >
            <div className="shadow-lg rounded" style={{ width: "80%", maxWidth: "900px" }}>
                {/* Left section with image and logo */}
                <Row className="g-0">
                    {/* Left Side Image */}

                    <Col md={6} className="p-0">
                        <div
                            style={{
                                backgroundColor: "#ffffff",
                                backgroundImage: `url(${userRegistration})`, // Replace with your image URL
                                backgroundSize: "contain", // Ensure the whole image is visible
                                backgroundRepeat: "no-repeat", // Prevent tiling
                                backgroundPosition: "center", // Center the image
                                height: "100%",
                                borderRadius: "10px 0 0 10px",
                                position: "relative",
                            }}
                            className="h-100"
                        >
                            <div
                                style={{
                                    position: "absolute",
                                }}
                            >
                                <Link to="/">
                                    <Logo className="logo" />
                                </Link>
                            </div>
                        </div>
                    </Col>

                    {/* Right Side Form */}
                    <Col
                        md={6}
                        className="p-4 d-flex align-items-center justify-content-center" style={{ borderRadius: "0 10px 10px 0", background: "#fff" }}>
                        {status == "failure" ? (
                            <div style={{ height: "57vh", width: "100%", maxWidth: "400px", background: "#fff" }}>
                                <h2 className="text-left mb-4 app-branding-title" style={{ fontSize: "1.5rem" }}>Regisgration failed</h2>
                                <div style={{
                                    width: "100%",
                                    display: "flex", // Flexbox for centering
                                    flexDirection: "column", // Vertical stacking
                                    alignItems: "center", // Center items vertically
                                    justifyContent: "center", // Center items horizontally
                                    paddingTop: "4rem", // Add padding around the card for spacing

                                }}>
                                    <div className="d-flex align-items-center justify-content-center" style={{
                                        backgroundColor: "#ffe6e6", // Lighter red background
                                        color: "#a94442", // Softer red text color
                                        textAlign: "center", // Center align text
                                        padding: "0.8rem", // Padding around the message
                                        borderRadius: "8px", // Rounded corners
                                        width: "100%", // Optional: Control width of the message box
                                    }}>
                                        <p style={{ fontSize: "1rem" }}>{message}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div style={{
                                height: "55vh", width: "100%", maxWidth: "400px", background: "#fff",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: "30px",
                            }}>
                                <h2 className="text-left mb-4 app-branding-title" style={{ fontSize: "1.5rem" }}>Please Complete the Process</h2>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formFirstName">
                                            <Form.Label style={{ fontSize: "0.85rem" }}>First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="First Name"
                                                name="FirstName"
                                                value={formData.FirstName}
                                                onChange={(event) => {
                                                    setFormData({ ...formData, FirstName: event.target.value })
                                                }}
                                                // isInvalid={formData.FirstName.length < 4 || formData.FirstName.length > 15}
                                                style={{ fontSize: "0.85rem" }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formLastName">
                                            <Form.Label style={{ fontSize: "0.85rem" }}>Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Last Name"
                                                name="LastName"
                                                value={formData.LastName}
                                                onChange={(event) => {
                                                    setFormData({ ...formData, LastName: event.target.value })
                                                }}
                                                style={{ fontSize: "0.85rem" }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formPassword">
                                            <Form.Label style={{ fontSize: "0.85rem" }}>Password</Form.Label>
                                            <Form.Control
                                                type={passwordVisible ? 'text' : 'password'}
                                                placeholder="Enter your password"
                                                name="Password"
                                                value={formData.Password}
                                                onChange={(event) => {
                                                    setFormData({ ...formData, Password: event.target.value })
                                                }}
                                                // isInvalid={formData.password === ""}
                                                style={{ fontSize: "0.85rem" }} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formConfirmPassword">
                                            <Form.Label style={{ fontSize: "0.85rem" }}>Confirm Password</Form.Label>
                                            <div className="password-wrapper">
                                                <Form.Control
                                                    type={passwordVisible ? 'text' : 'password'}
                                                    placeholder="Confirm your password"
                                                    name="ConfirmPassword"
                                                    value={formData.ConfirmPassword}
                                                    onChange={(event) => {
                                                        setFormData({ ...formData, ConfirmPassword: event.target.value })
                                                    }}
                                                    // isInvalid={formData.confirmPassword === ""}
                                                    style={{ fontSize: "0.85rem" }}
                                                />
                                                <button type="button" className="show-hide-btn" onClick={togglePasswordVisibility}>
                                                    {passwordVisible ? (
                                                        <img src={openEye} alt="Hide Password" />
                                                    ) : (
                                                        <img src={closeEye} alt="Show Password" />
                                                    )}
                                                </button>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group className="mb-3" controlId="formTerms">
                                    <Form.Check
                                        type="checkbox"
                                        label="I agree to the terms and conditions"
                                        checked={isTermsChecked}
                                        onChange={(event) => setIsTermsChecked(event.target.checked)}
                                        style={{ fontSize: "0.85rem", cursor: "pointer" }}
                                    />
                                </Form.Group>
                                <div className="registration-btn-container mt-2">
                                    <Button type="submit" className="w-50 app-branding-button" disabled={isButtonDisabled()} onClick={registerUserDataAPI}>
                                        {isSubmitting ? (
                                            <>
                                                <span
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                Please wait...
                                            </>
                                        ) : (
                                            "Register"
                                        )}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>

        </Container >

    );
}

export default UserRegistrationPage