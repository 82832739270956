import React, { useState, useEffect } from "react";
import { useTable } from 'react-table';
import DatePicker from "react-datepicker";
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import "./DestinationConnectivity.css";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from '../../ProHelpers/AlertContext';
import deleteImg from '../../Resource/delete-img.png';
import updateImg from '../../Resource/update-img.jpg';
import testconnectionImg from '../../Resource/testconnection-img.png';
import { maskString } from '../../ProHelpers/AuthUtils'
import { Modal, Button, Form } from 'react-bootstrap';
import AllModals from '../../ProHelpers/AllModals';

function DestinationConnectivity() {
    const { showAlert } = useAlert();
    const [modalType, setModalType] = useState(null);
    const handleClose = () => setModalType(null);

    const [message, setMessage] = useState("Please wait, loading...");
    const [name, setName] = useState('');
    const [endpointURL, setEndpointURL] = useState('');
    const [clientID, setClientID] = useState('');
    const [tokenURL, setTokenURL] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [expiryDate, setExpiryDate] = useState(null);
    const [systemTypes, setSystemTypes] = useState([]);
    const [selectedSystemType, setSelectedSystemType] = useState(''); // State for selected option
    const [isCreateVisible, setIsCreateVisible] = useState('cancel');
    const [screenMessage, setScreenMessage] = useState("");
    const [isConnectionSuccessful, setIsConnectionSuccessful] = useState(false);
    const customerID = sessionStorage.getItem('CustomerID');
    const [connectionData, setConnectionData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDeleteloading, setIsDeleteLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isTCLoading, setIsTCLoading] = useState(false);
    const [isTestingLoading, setIsTestingLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [isLoadingRow, setIsLoadingRow] = React.useState(1);

    const defaultFormData = {
        SystemName: "",
        TokenURL: "",
        ClientID: "",
        ClientSecret: "",
        HostURL: "",
        SystemType: "",
        Expiry: "",
    };
    const [formData, setFormData] = React.useState(defaultFormData);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [itemToDelete, setItemToDelete] = useState(null);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const handleDeleteClick = (rowData) => {
        setItemToDelete(rowData);
        setIsDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        setIsDeleteLoading(true);
        if (confirmationText === "Delete") {
            try {
                //const response = await deleteItem(itemToDelete.id);
                const requestBody = { ActionType: "delete", Type: "cpi", CustomerID: sessionStorage.getItem('CustomerID'), SNo: itemToDelete.SNo };
                // console.log('Requested Data :', requestBody);
                const response = await AxiosInstance.post('/manage-resource', requestBody);
                //console.log('Response Data :', response.data.message);

                if (response.data.message === "success") {
                    setTimeout(() => showAlert("Setting Deleted successfully!", 'success'), 0);
                    //alert("Item deleted successfully!");
                    // Optionally, update state or remove item from table view
                    fetchConnectionData();
                } else {
                    setTimeout(() => showAlert('Failed to delete item. Please try again.', 'failure'), 0);
                    //alert("Failed to delete item. Please try again.");
                }
            } catch (error) {
                setLoading(false);
                console.error("Error deleting item:", error);
                //alert("An error occurred while deleting. Please try again.");
                setTimeout(() => showAlert('An error occurred while deleting. Please try again.', 'failure'), 0);
            } finally {
                setLoading(false);
                setIsDeleteModalOpen(false);
                setIsDeleteLoading(false);
                setConfirmationText("");
            }
        } else {
            setLoading(false);
            setTimeout(() => showAlert("The text doesn't match. Please type 'Delete' to confirm.", 'failure'), 0);
            // alert("The text doesn't match. Please type 'DELETE' to confirm.");
        }
    };

    const handleCancelDelete = () => {
        setIsDeleteModalOpen(false);
        setConfirmationText("");
    };

    useEffect(() => {
        // const CustomerID = sessionStorage.getItem('CustomerID');
        // setCustomerID(CustomerID);
        setIsTableLoading(true);
        fetchSystemTypes();
        fetchConnectionData();

    }, []);

    const hiddenColumns = React.useMemo(() => ["SNo", "TokenUrl", "ClientID", "ClientSecret"], []); // List of columns to hide

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "SystemName", // accessor is the key in the data
                width: 150,
            },
            {
                Header: "System Type",
                accessor: "SystemType",
                width: 150,
            },
            {
                Header: "Host URL",
                accessor: "HostUrl",
                width: 450,
                Cell: ({ value }) => {
                    return <span>{maskString(value, 50, 100)}</span>; // You can adjust the visible length here
                },
            },
            {
                Header: "Actions",
                accessor: "actions",
                width: 50,
                Cell: ({ row }) => {
                    const isRowLoading = isLoadingRow === row.original.SNo; // Check if this row is loading
                    //console.log('Selected Row SNo:', row.original.SNo, 'isRowLoading:', isRowLoading);
                    return (
                        <div className="d-flex">
                            {/* View Icon */}
                            <i
                                className="bi bi-info-square-fill custom-moreinfo-icon"
                                title="View"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleViewClick(row.original)}
                            ></i>
                            {/* Edit Icon */}
                            <i
                                className="bi bi-pencil-fill custom-edit-icon"
                                title="Edit"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleToUpdateClick(row.original)}
                            ></i>

                            {isRowLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm text-primary"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginLeft: '10px', marginRight: '10px', marginTop: '5px' }}
                                ></span>
                            ) : (
                                <>
                                    {/* Connection Icon */}
                                    < i
                                        className="bi bi-box-arrow-up-right custom-connectiontest-icon"
                                        title="Test Connection"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleTestConnectionOnClick(row.original)}
                                    ></i>
                                </>
                            )
                            }
                            {/* Delete Icon */}
                            <i
                                className="bi bi-trash-fill custom-delete-icon"
                                title="Delete"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDeleteClick(row.original)}
                            ></i>
                        </div >
                    );
                },
            },
            // Hidden columns (data included but not displayed)
            {
                accessor: "SNo", // Unique identifier
                Header: "", // No header
                width: 0,
                Cell: () => null, // No cell content
                style: { display: "none" },
            },
            {
                accessor: "TokenUrl", // Unique identifier
                Header: "", // No header
                width: 0,
                Cell: () => null, // No cell content
                style: { display: "none" },
            },
            {
                accessor: "ClientID", // Unique identifier
                Header: "", // No header
                width: 0,
                Cell: () => null, // No cell content
                style: { display: "none" },
            }, {
                accessor: "ClientSecret", // Unique identifier
                Header: "", // No header
                width: 0,
                Cell: () => null, // No cell content
                style: { display: "none" },
            },


        ],
        [isLoadingRow]
    );

    const handleViewClick = (rowData) => {
        setSelectedRowData(rowData); // Store row data
        setShowViewModal(true); // Show the View modal
    };
    const handleCloseModal = () => {
        setShowViewModal(false);
        setSelectedRowData(null);
    };
    // Use the useTable hook
    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow } =
        useTable({
            columns,
            data: connectionData,
            initialState: { hiddenColumns },
        }

        );

    const fetchSystemTypes = async () => {
        setLoading(true);
        setMessage("Fetching data...");
        try {
            const response = await AxiosInstance.get('/get-options'); // Adjust the path to your API
            //console.log("ENV value: ", process.env.REACT_APP_BASE_URL);
            if (response?.data?.message && Array.isArray(response.data.message)) {
                //console.log('Response Data:', response);
                setSystemTypes(response.data.message); // Adjust based on your response structure
            } else {
                // console.log('Unexpected data format ', response);
            }
        } catch (err) {
            //console.error('Error fetching server zones:', err); // Log the full error for more details
            setMessage("Error loading data. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event, fieldName) => {
        if (fieldName === "Expiry") {
            // For DatePicker
            setFormData({
                ...formData,
                [fieldName]: event, // Directly assign the selected date
            });
        } else {
            const { name, value } = event.target;
            setFormData({
                ...formData,
                [name]: value, // Dynamically updates other keys in formData
            });
        }
    };
    const validateFormData = () => {
        const requiredFields = ["SystemName", "Expiry", "SystemType", "HostURL"]; // Add all required field names here
        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0) {
            setTimeout(() => showAlert('Please fill in the missing fields', 'warning'), 0);
            return false; // Validation failed
        }
        return true; // Validation passed
    };

    const handleSave = () => {
        if (validateFormData()) {
            setIsSaveLoading(true);
            if (isEditing) {
                const updatedUpdateFormData = {
                    ...formData,
                    ActionType: "update", // Add the key-value pair to formData
                    CustomerID: sessionStorage.getItem('CustomerID'),
                    SNo: itemToDelete.SNo,
                };

                //console.log("Updated FormData:", updatedUpdateFormData); // Debugging step
                // Set the formData state (if needed later)
                setFormData(updatedUpdateFormData);

                setLoading(true);
                setMessage("Fetching data...");
                //alert(`Data saved successfully! Here is the form data:\n${JSON.stringify(formData, null, 2)}`);
                manageConnectionData(updatedUpdateFormData);

            } else {

                const updatedSaveFormData = {
                    ...formData,
                    ActionType: "save", // Add the key-value pair to formData
                    CustomerID: sessionStorage.getItem('CustomerID'),
                };

                // console.log("Updated FormData:", updatedSaveFormData); // Debugging step
                // Set the formData state (if needed later)
                setFormData(updatedSaveFormData);

                setLoading(true);
                setMessage("Fetching data...");
                //alert(`Data saved successfully! Here is the form data:\n${JSON.stringify(formData, null, 2)}`);
                manageConnectionData(updatedSaveFormData);
            }

        }
    };

    const handleCreateAction = () => {
        //setCustomerID(sessionStorage.getItem('CustomerID'))
        //fetchSystemTypes();
        setIsEditing(false);
        setFormData(defaultFormData);
        setIsConnectionSuccessful(false)
        setIsCreateVisible("create");
    };

    const handleCancel = () => {
        setFormData(defaultFormData); // Reset form data
        setIsEditing(false); // Exit edit mode
        setIsCreateVisible("cancel");
    };


    const handleTestConnectionOnClick = async (rowData) => {
        //setIsTestingLoading(true);
        //console.log('Row SNo:', rowData.SNo);
        setIsLoadingRow(rowData.SNo);
        try {
            // Show spinner

            const result = await testConnectionAPI(rowData.TokenURL, rowData.ClientID, rowData.ClientSecret);
            //setIsConnectionSuccessful(result);
            //setLoading(false);
            //setIsLoading(false);
            if (result) {
                setTimeout(() => showAlert(`Connection Successfully Tested for ${rowData.SystemName}`, 'success'), 0);
            } else {
                setTimeout(() => showAlert('Unable to connect to the API. Please try again.', 'failure'), 0);
            }
        } catch (error) {
            // Handle error
            console.error('API call failed:', error);
        } finally {
            setIsLoadingRow(null); // Reset the loading state
            //setIsTestingLoading(false);
            //setIsLoading(false); // Hide spinner after API call completes
        }
    };

    const handleTestConnectionAPI = async (rowData) => {
        //setLoading(true);
        const result = await testConnectionAPI(rowData.TokenURL, rowData.ClientID, rowData.ClientSecret);
        setIsConnectionSuccessful(result);
        setLoading(false);
        setIsLoading(false);
        if (result) {
            setTimeout(() => showAlert(`Connection Successfully Tested for ${rowData.SystemName}`, 'success'), 0);
        } else {
            setTimeout(() => showAlert('Unable to connect to the API. Please try again.', 'danger'), 0);
        }
    }

    const handleToUpdateClick = (rowData) => {
        // Map the row data to your formData structure
        setIsConnectionSuccessful(false);
        setFormData({
            SystemName: rowData.SystemName || "",
            HostURL: rowData.HostUrl || "",
            ClientID: rowData.ClientID || "",
            ClientSecret: "********************************************",
            Expiry: rowData.Expiry ? new Date(rowData.Expiry) : null, // Convert date string to Date object
            SystemType: rowData.SystemType || "",
            TokenURL: rowData.TokenURL || "",
            SNo: rowData.SNo || "",
        });
        setIsCreateVisible("create")
        // Optionally, set a flag to switch to edit mode
        setItemToDelete(rowData);
        setIsEditing(true);
    };

    const fetchConnectionData = async () => {

        const requestBody = { 'ActionType': 'get', 'CustomerID': sessionStorage.getItem('CustomerID') };
        setLoading(true);
        setMessage("Fetching data...");
        try {
            const response = await AxiosInstance.post('/manage-resource', requestBody); // Adjust the path to your API
            //console.log("Response Data: ", response);
            if (response && response.data && response.data.message) {
                if (response.data.message.length > 0) {
                    if (response.data.status == "success") {
                        // console.log("Response Get Data: ", response.data.message);
                        setConnectionData(response.data.message);
                    } else {
                        // console.log("Response Get Data: ", response.data.message);
                        // console.log("Response Get Data: ", response.data.status);
                    }
                } else {
                    // console.log("No data found.");
                    setConnectionData([]); // Clear previous data if necessary
                }
            } else {
                // console.log("Empty or invalid response.");
                setConnectionData([]); // Clear previous data if necessary
            }
        } catch (err) {
            //console.error('Error fetching server zones:', err); // Log the full error for more details
            setMessage("Error loading data. Please try again.");
        } finally {
            setLoading(false);
            setIsTableLoading(false);
        }
    };


    const manageConnectionData = async (data) => {
        //alert(`Data saved successfully! Here is the form data:\n${JSON.stringify(data, null, 2)}`);
        try {
            const requestBody = data;
            //console.log('Requested Data :', requestBody);
            const response = await AxiosInstance.post('/manage-resource', requestBody); // Adjust the path to your API
            //console.log('Response Data:', response);
            //console.log('Single Data:', response.data.status);

            if (response.data.status == 'success') {
                setTimeout(() => showAlert(response.data.message, 'success'), 0);
                setIsEditing(false);
                setFormData(defaultFormData);
                setIsConnectionSuccessful(false);
                fetchConnectionData();
            } else {
                setTimeout(() => showAlert(response.data.message, 'danger'), 0);
            }
        } catch (err) {
            setLoading(false);
            //console.log('Error fetching server zones:', err); // Log the full error for more details
            setMessage("Error loading data. Please try again.");
        } finally {
            setLoading(false);
            setIsSaveLoading(false);
        }
    };


    const handleTestConnection = async () => {
        const { TokenURL, ClientID, ClientSecret } = formData;
        setLoading(true);
        setIsTCLoading(true);
        //console.log("Form Dat: ", formData);
        if (!TokenURL || !ClientID || !ClientSecret) {
            setTimeout(() => showAlert('Please fill required fields for connection testing.', 'warning'), 0);
            setIsTCLoading(false);
            return;
        }
        const result = await testConnectionAPI(TokenURL, ClientID, ClientSecret);
        // console.log("Connection Result: ", result)
        setIsConnectionSuccessful(result);
        setLoading(false);

        if (result) {
            setTimeout(() => showAlert(' Connection Successfully Tested.', 'success'), 0);
        } else {
            setTimeout(() => showAlert('Unable to connect to the API. Please try again.', 'failure'), 0);
        }
    };


    const testConnectionAPI = async (TokenURL, ClientID, ClientSecret) => {
        if (!TokenURL || !ClientID || !ClientSecret) {
            setTimeout(() => showAlert('Please fill in all fields.', 'warning'), 0);
            setLoading(false);
            setIsLoading(false);
            return;
        }
        // Prepare the payload for the API call
        const requestBody = {
            grant_type: "client_credentials",
        };

        // Define headers for the request
        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };

        // Add authentication information to the headers
        const auth = {
            username: ClientID,
            password: ClientSecret,
        };

        try {
            //console.log("Connection Request: ", requestBody)
            const response = await AxiosInstance.post(TokenURL, new URLSearchParams(requestBody), {
                auth,
                headers,
            });
            //console.log("Connection Response: ", response)
            if (response.data.access_token) {
                // setTimeout(() => showAlert(' Connection Successfully Tested.', 'success'), 0);

                // setIsConnectionSuccessful(true); // Enable Save button
                // setLoading(false);
                setIsLoading(false);
                return true;
            } else {
                // setTimeout(() => showAlert('Incorrect Credentials. Please try again.', 'danger'), 0);

                // setIsConnectionSuccessful(false); // Disable Save button
                // setLoading(false);
                return false;
            }
        } catch (error) {
            // setTimeout(() => showAlert('Unable to connect to the API. Please try again.', 'danger'), 0);

            // setIsConnectionSuccessful(false); // Disable Save button
            // setLoading(false);
            //console.log("Connection Catch: ", error)
            setIsLoading(false);
            return false;
        } finally {
            setIsLoading(false);
            setIsTCLoading(false);
            setIsTestingLoading(false);
            setIsLoadingRow(null);
        }
    }

    return (
        <>
            <Header setModalType={setModalType} />
            <SideBar />
            <div className="app-container">
                <main id="main" className="main">
                    <PageTitle title={"Connectivity"} subtitle={"Destination"} />
                    <AllModals modalType={modalType} handleClose={handleClose} setModalType={setModalType} />
                    <div className="d-flex justify-content-end mb-1">
                        <button className="btn btn-primary text-xs" onClick={handleCreateAction} style={{
                            //height: "30px", // Adjust the height as needed
                            fontSize: "14px", // Optional: Adjust font size for better alignment
                            padding: "5px",   // Optional: Adjust padding for a smaller look
                            width: "70px",
                            height: "auto",

                        }}>CREATE</button>
                    </div>

                    {isTableLoading ? (<div className="tab-panel">
                        <div className="w-100 p-4 shadow rounded bg-light destination-tb-custom-border"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <span
                                className="spinner-border spinner-border-lg text-primary"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '10px' }}
                            ></span></div></div>) : (<>
                                <div className="mt-3">{screenMessage}</div>
                                <div className="tab-panel">
                                    <Modal className="custom-modal-end" show={showViewModal} onHide={handleCloseModal} centered backdrop="static" keyboard={false}>
                                        <div style={{ position: 'relative' }}>
                                            <button
                                                className="custom-modal-close-btn m-1"
                                                onClick={handleCloseModal}
                                                aria-label="Close"
                                            >
                                                &times; {/* Close icon */}
                                            </button>
                                        </div>
                                        <Modal.Header>
                                            <Modal.Title>View Data</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="custom-modal-body">
                                            {/* Two-column layout */}
                                            {selectedRowData ? (
                                                <div style={{
                                                    backgroundColor: '#e7f9e7',  // Light green background
                                                    padding: '5px',
                                                    borderRadius: '5px',
                                                    marginLeft: '10px',
                                                    marginRight: '10px'
                                                }}>
                                                    <div className="row">
                                                        <div className="col-4 text-end">
                                                            <strong>Name :</strong>
                                                        </div>
                                                        <div className="col-8 text-start">
                                                            {selectedRowData.SystemName}
                                                        </div>

                                                        <div className="col-4 text-end">
                                                            <strong>System Type :</strong>
                                                        </div>
                                                        <div className="col-8 text-start">
                                                            {selectedRowData.SystemType}
                                                        </div>

                                                        <div className="col-4 text-end">
                                                            <strong>Host URL :</strong>
                                                        </div>
                                                        <div className="col-8 text-start">
                                                            {selectedRowData.HostUrl.substring(0, 35) + '...'}

                                                        </div>
                                                        <div className="col-4 text-end">
                                                            <strong>Client ID :</strong>
                                                        </div>
                                                        <div className="col-8 text-start">

                                                            {selectedRowData.ClientID.substring(0, 30) + '...'}
                                                        </div>
                                                        <div className="col-4 text-end">
                                                            <strong>Token URL :</strong>
                                                        </div>
                                                        <div className="col-8 text-start">
                                                            {selectedRowData.TokenURL.substring(0, 35) + '...'}

                                                        </div>

                                                        {/* Add other fields as needed */}
                                                    </div>
                                                </div>
                                            ) : (
                                                <p>No data available.</p>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseModal}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal show={isDeleteModalOpen} onHide={handleCancelDelete} centered backdrop="static" keyboard={false}>
                                        <div style={{ position: 'relative' }}>
                                            <button
                                                className="custom-modal-close-btn m-1"
                                                onClick={handleCancelDelete}
                                                aria-label="Close"
                                            >
                                                &times; {/* Close icon */}
                                            </button>
                                        </div>
                                        <Modal.Header>
                                            <Modal.Title>Confirm Deletion</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="destination-modal-body" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            padding: '20px', // Optional, for spacing
                                        }}>   {isDeleteloading ? (
                                            <LoadingPopup message={"Loding..."} />
                                        ) : (<>
                                            <Form>
                                                <Form.Group controlId="confirmationText">
                                                    {itemToDelete?.SystemName && (  // Check if SystemName is not null or empty
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            backgroundColor: '#f8d7da',
                                                            padding: '20px',
                                                            borderRadius: '5px',
                                                            textAlign: 'center',
                                                            alignItems: 'center', // Center items horizontally
                                                            justifyContent: 'center', // Center items vertically
                                                            gap: '10px', // Add spacing between elements
                                                        }}>

                                                            <div className="row">
                                                                <h5>Do you want to delete {itemToDelete.SystemName}?</h5>
                                                                <h6>Please type 'Delete' to confirm</h6>
                                                                <Form.Control
                                                                    style={{
                                                                        width: '300px',
                                                                        marginLeft: '70px',
                                                                    }}
                                                                    type="text"
                                                                    placeholder="Enter Delete to confirm"
                                                                    value={confirmationText}
                                                                    onChange={(e) => setConfirmationText(e.target.value)}
                                                                />
                                                            </div>

                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Form>
                                        </>)}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCancelDelete}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="danger"
                                                onClick={handleConfirmDelete}
                                                disabled={confirmationText !== "Delete"} // Disable if the text doesn't match
                                                className={confirmationText !== "Delete" ? "modal-custom-btn" : ''}
                                            >
                                                Submit
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {isCreateVisible === 'create' && (
                                        <div className="w-100 p-2 shadow rounded bg-light destination-custom-border">
                                            <div className="two-column-layout">
                                                {/* Left Column */}
                                                <div className="left-column">
                                                    <div className="inner-table">
                                                        <div className="inner-row">
                                                            <div className="inner-label">Name</div>
                                                            <div className="inner-value">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    name="SystemName"
                                                                    placeholder="Enter Name"
                                                                    value={formData.SystemName}
                                                                    onChange={handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="inner-row">
                                                            <div className="inner-label">Endpoint URL</div>
                                                            <div className="inner-value">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Endpoint URL"
                                                                    name="HostURL"
                                                                    value={formData.HostURL}
                                                                    onChange={handleInputChange} /></div>
                                                        </div>
                                                        <div className="inner-row">
                                                            <div className="inner-label">Client ID</div>
                                                            <div className="inner-value">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter ClientID"
                                                                    name="ClientID"
                                                                    value={formData.ClientID}
                                                                    onChange={handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="inner-row">
                                                            <div className="inner-label">Expiry Date</div>
                                                            <div className="inner-value destination-calendar-container">

                                                                <DatePicker
                                                                    name="Expiry"
                                                                    selected={formData.Expiry}
                                                                    onChange={(date) => handleInputChange(date, "Expiry")}
                                                                    placeholderText="YYYY-MM-DD"
                                                                    dateFormat="yyyy-MM-dd"
                                                                    className="destination-date-picker"
                                                                    popperPlacement="bottom-start" // Ensure calendar opens downward
                                                                    minDate={new Date()} // Restrict to today and future dates
                                                                />
                                                                <span className="destination-custom-calendar-icon me-2"><i className="bi bi-calendar2-range-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Right Column */}
                                                <div className="right-column">
                                                    <div className="inner-table">
                                                        <div className="inner-row">
                                                            <div className="inner-label">System Type</div>
                                                            <div className="inner-value">
                                                                <div className="destination-dropdown-container">
                                                                    <select
                                                                        className="destination-custom-dropdown"
                                                                        name="SystemType"
                                                                        value={formData.SystemType}
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    >
                                                                        <option value="" disabled>Select System Type</option>
                                                                        {systemTypes.map((type) => (
                                                                            <option key={type.SystemID} value={type.SystemName}>
                                                                                {type.SystemName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <span className="dropdown-icon">
                                                                        <i className="fas fa-caret-down"></i> {/* Font Awesome icon */}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="inner-row">
                                                            <div className="inner-label">Token URL</div>
                                                            <div className="inner-value">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="TokenURL"
                                                                    placeholder="Enter Token URL"
                                                                    value={formData.TokenURL}
                                                                    onChange={handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="inner-row">
                                                            <div className="inner-label">Client Secret</div>
                                                            <div className="inner-value">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"

                                                                    placeholder="Enter Client Secret"
                                                                    name="ClientSecret"
                                                                    value={formData.ClientSecret}
                                                                    onChange={handleInputChange} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="button-row">
                                                        <button className="custom-button" onClick={handleTestConnection}>
                                                            {isTCLoading ? (
                                                                <>
                                                                    <span
                                                                        className="spinner-border spinner-border-sm me-2"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    Please wait...
                                                                </>
                                                            ) : (
                                                                "Test Connection"
                                                            )}

                                                        </button>
                                                        <button
                                                            className="custom-button"
                                                            onClick={handleSave}
                                                            disabled={!isConnectionSuccessful || isSaveLoading} // Disable if connection test fails
                                                        >
                                                            {isSaveLoading ? (
                                                                <>
                                                                    <span
                                                                        className="spinner-border spinner-border-sm text-light"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                        style={{ marginRight: "8px" }}
                                                                    ></span>
                                                                    {isEditing ? "Updating..." : "Saving..."}
                                                                </>
                                                            ) : (
                                                                isEditing ? "Update" : "Save"
                                                            )}
                                                        </button>
                                                        <button className="custom-button" onClick={handleCancel}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="w-100 p-4 shadow rounded bg-light destination-tb-custom-border">
                                        <table {...getTableProps()} className="table table-striped table-bordered">
                                            <thead>
                                                {headerGroups.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()} >
                                                        {headerGroup.headers.map((column) => (
                                                            <th {...column.getHeaderProps()} style={{ width: column.width }}>{column.render("Header")}</th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {rows.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                                            No data found.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    rows.map((row, i) => {
                                                        prepareRow(row);
                                                        return (
                                                            <tr {...row.getRowProps()}
                                                                key={row.id}
                                                                className={`custom-row-height ${i % 2 === 0 ? 'row-grey' : 'row-white'}`}>
                                                                {row.cells.map((cell) => (
                                                                    <td {...cell.getCellProps()} key={cell.column.id}>{cell.render("Cell")}</td>
                                                                ))}
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </>)}


                </main>
            </div>
            <Footer />
            <BackToTop />
        </>
    )
}

export default DestinationConnectivity