import React, { useState } from 'react';
import './RegistrationPage.css';
import registrationImage from '../../Resource/registrationImage.png'; // Add your image here
import RegistrationPage1 from './RegistrationPage1';
import RegistrationPage2 from './RegistrationPage2';
import RegistrationPage3 from './RegistrationPage3';
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import { hashPassword, getUTCDateTime, generateCustomerID, generateActivationCode } from '../../ProHelpers/AuthUtils';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import Logo from '../../ProHelpers/Logo'


const RegistrationPage = () => {
    const { showAlert } = useAlert();
    const navigate = useNavigate(); // Initialize the useNavigate hook
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const [page, setPage] = useState(0);
    const [transition, setTransition] = useState('slide-to-center'); // Initial transition state
    const [formData, setFormData] = useState({
        FirstName: "",
        LastName: "",
        EmailID: "",
        CustomerAddress: "",
        Country: "",
        AccountType: "Trial",
        CustomerPassword: "",
        ServerInstanceURL: "",
        coupon: "",
        CustomerName: "",
        RegionName: "US",
        Retry: "false"
    });

    const passwordRequirements = (
        <div className="password-requirements">
            <p>Password must include:</p>
            <ul>
                <li>At least 8 characters</li>
                <li>One uppercase letter</li>
                <li>One lowercase letter</li>
                <li>One number</li>
                <li>One special character</li>
            </ul>
        </div>
    );
    const [isTermsChecked, setIsTermsChecked] = useState(false);

    const FormTitles = ["System Information", "Basic Information", "Account Details"];

    // Handle API Call
    const couponApi = async (couponCode) => {
        try {
            const requestBody = {
                CouponCode: couponCode
            };

            const response = await AxiosInstance.post('/CouponCode', requestBody); // Replace with actual API endpoint
            // Debugging: Log the full response object
            //console.log("Full API response:", response);
            // Check if the data is nested under the 'data' field
            const { message, reason } = response.data || {}; // Ensure 'data' exists
            // Log the destructured values
            // console.log("Message from API:", message);
            // console.log("Reason from API:", reason);
            // Return the message and reason
            return { message, reason };
        } catch (error) {
            //console.log("Error fetching data from API:", error);
            return { message: "error", reason: "API request failed" };
        }
    };

    const PageDisplay = () => {
        if (page === 0) {
            return <RegistrationPage2 formData={formData} setFormData={setFormData} />;
        } else if (page === 1) {
            return <RegistrationPage1 formData={formData} setFormData={setFormData} />;
        } else if (page === 2) {
            return <RegistrationPage3 formData={formData} setFormData={setFormData} />;
        }
    }
    // Function to validate password
    const validatePassword = (password) => {
        // Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        return pattern.test(password);
    };

    // Function to validate email format
    const validateEmail = (email) => {
        // Basic email format validation
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };


    // Validation function to check if all required fields are filled based on the current page
    const validatePage = () => {
        switch (page) {
            case 0:
                const isAccountTypeValid = formData.AccountType === 'Trial' || (formData.AccountType === 'Enterprise' && formData.CustomerName && formData.coupon);
                // // return formData.coupon && formData.regionname && formData.timezone && isAccountTypeValid;
                return formData.RegionName && isAccountTypeValid;
            case 1:
                // if (formData.AccountType === 'Enterprise' && !formData.CustomerAddress) {
                //     setTimeout(() => showAlert('Address is required for Enterprise accounts.', 'failure'), 0);
                //     return false;
                // }
                const isAddress = formData.AccountType === 'Trial' || (formData.AccountType === 'Enterprise' && formData.CustomerAddress);
                return formData.FirstName && formData.LastName && formData.Country && isAddress;
            case 2:
                if (!validateEmail(formData.EmailID)) {
                    setTimeout(() => showAlert('Invalid email format!', 'failure'), 0);
                    return false;
                }
                if (!validatePassword(formData.CustomerPassword)) {
                    setTimeout(() => showAlert(passwordRequirements, 'warning'), 0);
                    return false;
                }
                if (formData.CustomerPassword !== formData.confirmpassword) {
                    setTimeout(() => showAlert('Oops! Looks like the Confirm Passwords You Entered Don\'t Match. Try again?', 'warning'), 0);
                    return false;
                }
                return formData.EmailID && formData.CustomerPassword;

            default:
                return false;
        }
    };


    const isButtonDisabled = () => {
        if (page === FormTitles.length - 1) {
            // On the last page, check both terms acceptance and form validation
            return !isTermsChecked || !validatePage();
        } else {
            // For other pages, just check form validation
            return !validatePage();
        }
    };



    // Check if the button should be disabled based on validation
    //const isButtonDisabled = page === FormTitles.length - 1 ? !isTermsChecked && !validatePage() : !validatePage();

    const handlePageChange = (newPage, direction) => {
        // Set the appropriate slide-out class based on the direction
        setTransition(direction === 'next' ? 'slide-in-left' : 'slide-out-left');

        setTimeout(() => {
            // Update the page number and set the incoming transition class
            setPage(newPage);
            setTransition(direction === 'next' ? 'slide-out-right' : 'slide-in-left');

            // Reset the transition to center after animation completes
            setTimeout(() => {
                setTransition('slide-to-center');
            }, 260); // Match the duration of the CSS transition
        }, 260); // Match the duration of the CSS transition
    };

    return (
        <div>
            {loading && <LoadingPopup message={message} />}
            <div className="registration-page d-flex justify-content-center align-items-center">
                <div className="registration-card shadow-lg">
                    <div className="registration-card-left d-none d-md-flex">
                        <Link to="/">
                            {/* <img src={logo} alt="Logo" className="logo" /> */}
                            <Logo className="logo" />
                        </Link>
                        <img src={registrationImage} alt="Registration" className="registration-image" />
                    </div>

                    <div className="registration-card-right p-4">
                        <h2 className="text-left app-branding-title" style={{ fontSize: "1.7rem" }}>Create Account</h2>
                        <div><h2 className="registration-sub-title">{FormTitles[page]}</h2></div>

                        <div className="registrationbody-container">
                            <div className={`registrationslide-body ${transition}`}>
                                {PageDisplay()}
                            </div>
                        </div>
                        <div className="form-group">
                            {page === 2 ? (
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center">
                                            <input
                                                type="checkbox"
                                                id="terms"
                                                checked={isTermsChecked}
                                                onChange={(event) => setIsTermsChecked(event.target.checked)}
                                            />
                                            <label htmlFor="terms" className="mb-0">I Accept the Terms and Conditions</label>
                                        </div>
                                    </div>
                                </div>
                            ) : ""}
                        </div>
                        <div className="registration-btn-container mt-2">
                            {page > 0 && (
                                <button
                                    className="btn registration-btn me-2 mb-1"
                                    onClick={() => handlePageChange(page - 1, 'previous')}
                                >
                                    Previous
                                </button>
                            )}
                            <button
                                className="btn app-branding-button mb-1"
                                onClick={async () => { // Make this async to use await
                                    if (page === 0 && formData.AccountType === "Enterprise") {
                                        try {
                                            setLoading(true);  // Start loading
                                            const apiResponse = await couponApi(formData.coupon); // Await API call
                                            setLoading(false); // End loading on error
                                            if (apiResponse.message === "false") {
                                                // Coupon is valid, go to the next page
                                                handlePageChange(page + 1, 'next');
                                            } else {
                                                // Invalid coupon case
                                                setTimeout(() => showAlert(apiResponse.reason, 'failure'), 0);
                                                setPage(0); // Stay on page 0
                                            }
                                        } catch (error) {
                                            // Handle error if API call fails
                                            setLoading(false); // End loading on error
                                            setTimeout(() => showAlert('Error validating coupon, Please try again', 'failure'), 0);
                                        }
                                    } else if (page === FormTitles.length - 1) {
                                        //showAlert('Success! Form successfully submitted.', 'success');
                                        try {
                                            setLoading(true);  // Start loading
                                            setMessage("Please Wait, We are in Progress...");
                                            const hashedPassword = await hashPassword(formData.CustomerPassword);
                                            // const { confirmpassword, ...formData } = formData;
                                            const [Country, City, ServerURL] = formData.RegionName.split('|');
                                            const formDataWithHashedPassword = {
                                                ...formData,
                                                ServerInstanceURL: ServerURL,
                                                CreatedOn: getUTCDateTime(),
                                                CustomerID: generateCustomerID(),
                                                ActivationCode: generateActivationCode(50),
                                                CustomerPassword: hashedPassword,  // Replace plain password with hashed password
                                            };
                                            const { RegionName, confirmpassword, ...finalFormData } = formDataWithHashedPassword;

                                            const response = await AxiosInstance.post('/Registration', finalFormData); // Replace with actual API endpoint
                                            setLoading(false);
                                            if (response.status === 200) {
                                                //console.log("Response ", response);
                                                //setTimeout(() => showAlert(response.data.message, 'success'), 0);
                                                setLoading(false);
                                                navigate('/registrationcompleted', { state: response.data });
                                            } else if (response.status === 201) {
                                                //console.log("Response ", response);
                                                setLoading(false);
                                                setTimeout(() => showAlert(response.data.message, 'warning'), 0);
                                            } else {
                                                //console.log("Response ", response);
                                                setLoading(false);
                                                setTimeout(() => showAlert(response.data.message, 'failure'), 0);
                                            }
                                        } catch (error) {
                                            // Handle error case
                                            //console.log("Error Response", error);
                                            setLoading(false); // End loading on error
                                            if (error.code === 'ECONNABORTED') {
                                                showAlert('Oops!!! Request timed out', 'warning');
                                            } else {
                                                showAlert('Error creating account, Please try again.', 'failure');
                                            }

                                        }
                                    } else {
                                        handlePageChange(page + 1, 'next');
                                    }
                                }}
                                disabled={isButtonDisabled()} // Disable button based on validation
                            >
                                {page === FormTitles.length - 1 ? "Create Account" : "Next"}
                            </button>

                        </div>
                        <div className="registerlogin-link text-end mt-3">
                            <p>Already have an account? <a href="/login">Sign In</a>  | <a href="/forgotpassword">Forgot Password</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationPage;
